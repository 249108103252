import { Table, Button, Modal, Form, Toast, Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TodoDataService from "../../services/todos";
import "./ContactsInfo.css";

const ContactsInfo = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [editedLead, setEditedLead] = useState({
    // Campos originales
    description: "",
    email: "",
    name: "",
    number: "",
    priority: "",
    status: "",

    // Campos adicionales, ordenados alfabéticamente
    brand_category: "",
    brand_description: "",
    brand_differentiation: "",
    brand_necessity: "",
    brand_perception_keywords: "",
    brand_personality: "",
    brand_slogan_or_motto: "",
    brand_style_preference: "",
    brand_values: "",
    brand_virtues: "",
    business_experience_duration: "",
    business_type: "",
    colors: "",
    commercial_information_details: "",
    company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
    company_name: "",
    company_website_or_social_media: "",
    contact_person_name: "",
    contact_person_phone: "",
    contact_person_position: "",
    contact_reason: "",
    current_business_goals: "",
    main_competitors: "",
    opening_hours_location_maps: "",
    payment_information: "",
    payment_method: "",
    target_age_range: "",
    target_gender: "",
    target_interests: "",
    target_lifecycle_stage: "",
    target_socioeconomic_level: "",
  });

  const [activeTab, setActiveTab] = useState("client");
  const [tabData, setTabData] = useState(editedLead); // Estado para manejar los datos según la pestaña

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "client":
        setTabData({
          name: editedLead.name,
          email: editedLead.email,
          description: editedLead.description,
          number: editedLead.number,
          company_address: editedLead.company_address,
          brand_category: editedLead.brand_category,
          brand_description: editedLead.brand_description,
          brand_differentiation: editedLead.brand_differentiation,
        });
        break;
      case "vendor":
        setTabData({
          contact_person_name: editedLead.contact_person_name,
          contact_person_phone: editedLead.contact_person_phone,
        });
        break;
      case "graphic_designer":
        setTabData({
          color_palette: editedLead.color_palette,
          logo_use: editedLead.logo_use,
        });
        break;
      case "marketing_designer":
        setTabData({
          current_business_goals: editedLead.current_business_goals,
          competitors: editedLead.competitors,
        });
        break;
      default:
        break;
    }
  };

  const handleTabChangeCreate = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "client":
        setTabData({
          name: newLead.name,
          email: newLead.email,
          description: newLead.description,
          number: newLead.number,
          company_address: newLead.company_address,
          brand_category: newLead.brand_category,
          brand_description: newLead.brand_description,
          brand_differentiation: newLead.brand_differentiation,
        });
        break;
      case "vendor":
        setTabData({
          contact_person_name: newLead.contact_person_name,
          contact_person_phone: newLead.contact_person_phone,
        });
        break;
      case "graphic_designer":
        setTabData({
          color_palette: newLead.color_palette,
          logo_use: newLead.logo_use,
        });
        break;
      case "marketing_designer":
        setTabData({
          current_business_goals: newLead.current_business_goals,
          competitors: newLead.competitors,
        });
        break;
      default:
        break;
    }
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newLead, setNewLead] = useState({
    // Campos requeridos
    name: "",
    email: "",
    description: "",
    number: "",
    priority: "",
    status: "nuevo", // Valor por defecto

    // Campos adicionales
    comments: [],
    comercial_activity: "",
    brand_category: "",
    brand_description: "",
    brand_differentiation: "",
    brand_necessity: "",
    brand_perception_keywords: "",
    brand_personality: "",
    brand_slogan_or_motto: "",
    brand_style_preference: "",
    brand_values: "",
    brand_virtues: "",
    business_experience_duration: "",
    business_type: "",
    colors: "",
    commercial_information_details: "",
    company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
    company_name: "",
    company_website_or_social_media: "",
    contact_person_name: "",
    contact_person_phone: "",
    contact_person_position: "",
    contact_reason: "",
    current_business_goals: "",
    main_competitors: "",
    opening_hours_location_maps: "",
    payment_information: "",
    payment_method: "",
    target_age_range: "",
    target_gender: "",
    target_interests: "",
    target_lifecycle_stage: "",
    target_socioeconomic_level: "",
  });

  const [errorToast, setErrorToast] = useState(null);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedLeadComments, setSelectedLeadComments] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [leadsPerPage, setLeadsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [deleteToastMessage, setDeleteToastMessage] = useState("");
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const currentUserData = localStorage.getItem("currentUser");
    if (currentUserData) {
      try {
        const parsedData = JSON.parse(currentUserData);
        setCurrentUser(parsedData);
      } catch (error) {
        console.error("Error parsing currentUser data:", error);
      }
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("user");
    if (storedUsername) {
      loadLeads();
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [leadsPerPage]);

  const loadLeads = () => {
    TodoDataService.getAllLeads(localStorage.getItem("token"))
      .then((response) => {
        const updatedLeads = response.data.map((lead) => {
          const lastComment =
            lead && lead.comments && lead.comments.length > 0
              ? lead.comments[lead.comments.length - 1]
              : null;

          return {
            ...lead,
            lastComment,
          };
        });

        setLeads(updatedLeads);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    TodoDataService.deleteLead(id, localStorage.getItem("token"))
      .then((response) => {
        loadLeads();
      })
      .catch((error) => {
        console.error(error);
      });
    // Configurar el mensaje de Toast y mostrar el Toast
    setDeleteToastMessage("Lead eliminado exitosamente.");
    setShowDeleteToast(false);

    // Restablecer otros estados según sea necesario
    setSelectedLead(null);
  };

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setShowModal(true);
    setEditedLead({
      name: lead.name || "",
      email: lead.email || "",
      description: lead.description || "",
      number: lead.number || "",
      priority: lead.priority || "",
      status: lead.status || "nuevo", // Valor por defecto

      // Campos adicionales
      brand_category: lead.brand_category || "",
      brand_description: lead.brand_description || "",
      brand_differentiation: lead.brand_differentiation || "",
      brand_necessity: lead.brand_necessity || "",
      brand_perception_keywords: lead.brand_perception_keywords || "",
      brand_personality: lead.brand_personality || "",
      brand_slogan_or_motto: lead.brand_slogan_or_motto || "",
      brand_style_preference: lead.brand_style_preference || "",
      brand_values: lead.brand_values || "",
      brand_virtues: lead.brand_virtues || "",
      business_experience_duration: lead.business_experience_duration || "",
      business_type: lead.business_type || "",
      colors: lead.colors || "",
      commercial_information_details: lead.commercial_information_details || "",
      company_logo: lead.company_logo || null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: lead.company_name || "",
      company_website_or_social_media:
        lead.company_website_or_social_media || "",
      contact_person_name: lead.contact_person_name || "",
      contact_person_phone: lead.contact_person_phone || "",
      contact_person_position: lead.contact_person_position || "",
      contact_reason: lead.contact_reason || "",
      current_business_goals: lead.current_business_goals || "",
      main_competitors: lead.main_competitors || "",
      opening_hours_location_maps: lead.opening_hours_location_maps || "",
      payment_information: lead.payment_information || "",
      payment_method: lead.payment_method || "",
      target_age_range: lead.target_age_range || "",
      target_gender: lead.target_gender || "",
      target_interests: lead.target_interests || "",
      target_lifecycle_stage: lead.target_lifecycle_stage || "",
      target_socioeconomic_level: lead.target_socioeconomic_level || "",
      comment: "", // Puedes inicializarlo con un valor predeterminado si es necesario
    });

    setIsEditMode(true); // Activa el modo de edición
  };

  const handleSave = () => {
    const updatedLead = {
      ...selectedLead,
      name: editedLead.name || "",
      email: editedLead.email || "",
      description: editedLead.description || "",
      number: editedLead.number || "",
      priority: editedLead.priority || "",
      status: editedLead.status || "nuevo", // Valor por defecto

      // Campos adicionales
      brand_category: editedLead.brand_category || "",
      brand_description: editedLead.brand_description || "",
      brand_differentiation: editedLead.brand_differentiation || "",
      brand_necessity: editedLead.brand_necessity || "",
      brand_perception_keywords: editedLead.brand_perception_keywords || "",
      brand_personality: editedLead.brand_personality || "",
      brand_slogan_or_motto: editedLead.brand_slogan_or_motto || "",
      brand_style_preference: editedLead.brand_style_preference || "",
      brand_values: editedLead.brand_values || "",
      brand_virtues: editedLead.brand_virtues || "",
      business_experience_duration:
        editedLead.business_experience_duration || "",
      business_type: editedLead.business_type || "",
      colors: editedLead.colors || "",
      commercial_information_details:
        editedLead.commercial_information_details || "",
      company_logo: editedLead.company_logo || null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: editedLead.company_name || "",
      company_website_or_social_media:
        editedLead.company_website_or_social_media || "",
      contact_person_name: editedLead.contact_person_name || "",
      contact_person_phone: editedLead.contact_person_phone || "",
      contact_person_position: editedLead.contact_person_position || "",
      contact_reason: editedLead.contact_reason || "",
      current_business_goals: editedLead.current_business_goals || "",
      main_competitors: editedLead.main_competitors || "",
      opening_hours_location_maps: editedLead.opening_hours_location_maps || "",
      payment_information: editedLead.payment_information || "",
      payment_method: editedLead.payment_method || "",
      target_age_range: editedLead.target_age_range || "",
      target_gender: editedLead.target_gender || "",
      target_interests: editedLead.target_interests || "",
      target_lifecycle_stage: editedLead.target_lifecycle_stage || "",
      target_socioeconomic_level: editedLead.target_socioeconomic_level || "",
    };

    TodoDataService.updateLead(
      selectedLead.id,
      updatedLead,
      localStorage.getItem("token")
    )
      .then((response) => {
        if (editedLead.comment) {
          const commentData = {
            lead: selectedLead.id,
            comment: editedLead.comment,
          };
          return TodoDataService.createCommentlead(
            commentData,
            localStorage.getItem("token")
          );
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        setShowModal(false);
        loadLeads();
      })
      .catch((error) => {
        console.error(error);
        showErrorToast("Se produjo un error al guardar los cambios.");
      });
  };

  const handleCreateComment = (lead) => {
    // Inicializar el nuevo comentario
    const newComment = {
      lead: lead.id,
      comment: "", // El comentario se dejará vacío al inicio
    };
  
    // Mostrar un formulario modal para que el usuario escriba su comentario
    const comment = prompt("Escribe tu comentario:");
  
    // Si el comentario no está vacío
    if (comment && comment.trim() !== "") {
      newComment.comment = comment;
  
      // Llamada al servicio para crear el comentario
      TodoDataService.createCommentlead(newComment, localStorage.getItem("token"))
        .then(() => {
          // Después de crear el comentario, recargar los leads y comentarios
          loadLeads(); // Asegúrate de que `loadLeads` también recarga los comentarios
          console.log("Comentario creado exitosamente.");
        })
        .catch((error) => {
          console.error(error);
          console.log("Error al crear el comentario.");
        });
    } else {
      showErrorToast("El comentario no puede estar vacío.");
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLead(null);
    setEditedLead({
      name: "",
      email: "",
      description: "",
      priority: "",
      status: "",
      number: "",
      comment: "",
    });
  };

  const handleCreateLead = () => {
    // console.log("se presiono el boton de crear")
    setShowCreateModal(true);
  };

  const handleNewLeadInputChange = (event) => {
    const { name, value } = event.target;
    setNewLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveNewLead = () => {
  console.log(newLead.name, "nombre", newLead.email, "email",newLead.description, "descripcion", newLead.priority, "prioridad", newLead.status, "status", newLead.number, "numero")

    // Validar campos obligatorios
    const requiredFields = [
      newLead.name,
      newLead.email,
      newLead.description,
      newLead.priority,
      newLead.status,
      newLead.number,
    ];
  
    if (requiredFields.some(field => !field)) {
      console.log("faltan datos")
      // showErrorToast("Todos los campos son obligatorios.");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newLead.email)) {
      console.log("Formato de correo inválido.")
      // showErrorToast("Formato de correo inválido.");
      return;
    }
  
    // Asegúrate de que el objeto leadData tiene todos los campos que espera el servidor
    const leadData = {
      name: newLead.name || "",
      email: newLead.email || "",
      description: newLead.description || "",
      number: newLead.number || "",
      priority: newLead.priority || "",
      status: newLead.status || "",
      // Campos adicionales
      brand_category: newLead.brand_category || "",
      brand_description: newLead.brand_description || "",
      brand_differentiation: newLead.brand_differentiation || "",
      brand_necessity: newLead.brand_necessity || "",
      brand_perception_keywords: newLead.brand_perception_keywords || "",
      brand_personality: newLead.brand_personality || "",
      brand_slogan_or_motto: newLead.brand_slogan_or_motto || "",
      brand_style_preference: newLead.brand_style_preference || "",
      brand_values: newLead.brand_values || "",
      brand_virtues: newLead.brand_virtues || "",
      business_experience_duration: newLead.business_experience_duration || "",
      business_type: newLead.business_type || "",
      colors: newLead.colors || "",
      commercial_information_details: newLead.commercial_information_details || "",
      company_logo: newLead.company_logo || null, // Asegúrate de que este campo se maneje correctamente
      company_name: newLead.company_name || "",
      company_website_or_social_media: newLead.company_website_or_social_media || "",
      contact_person_name: newLead.contact_person_name || "",
      contact_person_phone: newLead.contact_person_phone || "",
      contact_person_position: newLead.contact_person_position || "",
      contact_reason: newLead.contact_reason || "",
      current_business_goals: newLead.current_business_goals || "",
      main_competitors: newLead.main_competitors || "",
      opening_hours_location_maps: newLead.opening_hours_location_maps || "",
      payment_information: newLead.payment_information || "",
      payment_method: newLead.payment_method || "",
      target_age_range: newLead.target_age_range || "",
      target_gender: newLead.target_gender || "",
      target_interests: newLead.target_interests || "",
      target_lifecycle_stage: newLead.target_lifecycle_stage || "",
      target_socioeconomic_level: newLead.target_socioeconomic_level || "",
    };
  
    console.log("Datos a enviar:", leadData); // Para depurar
  
    // Enviar datos al servidor
    TodoDataService.createLead(leadData, localStorage.getItem("token"))
      .then((response) => {
        setShowCreateModal(false);
        // Reiniciar el estado de newLead
        setNewLead({
          name: "",
          email: "",
          description: "",
          number: "",
          priority: "",
          status: "nuevo", // Valor por defecto
          brand_category: "",
          brand_description: "",
          brand_differentiation: "",
          brand_necessity: "",
          brand_perception_keywords: "",
          brand_personality: "",
          brand_slogan_or_motto: "",
          brand_style_preference: "",
          brand_values: "",
          brand_virtues: "",
          business_experience_duration: "",
          business_type: "",
          colors: "",
          commercial_information_details: "",
          company_logo: null,
          company_name: "",
          company_website_or_social_media: "",
          contact_person_name: "",
          contact_person_phone: "",
          contact_person_position: "",
          contact_reason: "",
          current_business_goals: "",
          main_competitors: "",
          opening_hours_location_maps: "",
          payment_information: "",
          payment_method: "",
          target_age_range: "",
          target_gender: "",
          target_interests: "",
          target_lifecycle_stage: "",
          target_socioeconomic_level: "",
        });
  
        loadLeads();
      })
      .catch((error) => {
        console.error("Error al crear lead:", error);
        const errorMessage = error.response?.data?.error || "Ocurrió un error al crear el lead.";
        showErrorToast(errorMessage);
      });
  };
  

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewLead({
      // Campos originales
      name: "",
      email: "",
      description: "",
      number: "",
      priority: "",
      status: "", // Valor por defecto

      // Campos adicionales
      brand_category: "",
      brand_description: "",
      brand_differentiation: "",
      brand_necessity: "",
      brand_perception_keywords: "",
      brand_personality: "",
      brand_slogan_or_motto: "",
      brand_style_preference: "",
      brand_values: "",
      brand_virtues: "",
      business_experience_duration: "",
      business_type: "",
      colors: "",
      commercial_information_details: "",
      company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: "",
      company_website_or_social_media: "",
      contact_person_name: "",
      contact_person_phone: "",
      contact_person_position: "",
      contact_reason: "",
      current_business_goals: "",
      main_competitors: "",
      opening_hours_location_maps: "",
      payment_information: "",
      payment_method: "",
      target_age_range: "",
      target_gender: "",
      target_interests: "",
      target_lifecycle_stage: "",
      target_socioeconomic_level: "",
    });
  };

  const showErrorToast = (message) => {
    setErrorToast(message);
    setTimeout(() => {
      setErrorToast(null);
    }, 5000);
  };


  const handleDownloadCSV = async () => { 
  try {
    // Filtra las claves para eliminar "lastComment" y agregar "comments"
    const leadKeys = Object.keys(leads[0]).filter(key => key !== "lastComment").concat("comments");

    const csvData = await convertToCSV(leads, leadKeys);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "clientes.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al descargar el CSV:", error);
  }
};

const convertToCSV = async (data, fields) => {
  const columns = fields;
  const header = columns.join(";");

  const rows = [];

  for (const lead of data) {
    // Obtén los comentarios para cada lead
    const commentsResponse = await TodoDataService.getLeadComments(lead.id, localStorage.getItem("token"));
    
    // Crea una fila para el lead
    const row = columns.map((column) => {
      if (column === "comments") {
        // Guardar el texto de los comentarios en una variable
        const commentsText = commentsResponse.data
          .map(commentObj => commentObj.comment)
          .join(", ");
        console.log("Comentarios:", commentsText); // Para ver los comentarios en la consola
        return commentsText; // Retornar el texto de los comentarios
      } else if (typeof lead[column] === "object" && lead[column] !== null) {
        // Para otros campos que son objetos
        return JSON.stringify(lead[column]);
      } else {
        // Para otros tipos de datos
        return lead[column] || ""; // Manejar valores null o undefined
      }
    });

    rows.push(row.join(";")); // Añadir la fila al array de filas
  }

  return header + "\n" + rows.join("\n");
};





  const handleLastCommentClick = (lead) => {
    TodoDataService.getLeadComments(lead.id, localStorage.getItem("token"))
      .then((response) => {
        setSelectedLeadComments(response.data);
        setSelectedLeadId(lead.id);
        setShowCommentsModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteComment = (commentId) => {
    if (!selectedLeadId) {
      console.error("No lead selected.");
      return;
    }

    TodoDataService.deleteCommentContact(
      selectedLeadId,
      commentId,
      localStorage.getItem("token")
    )
      .then((response) => {
        setSelectedLeadComments((comments) =>
          comments.filter((comment) => comment.id !== commentId)
        );
      })
      .catch((error) => {
        console.error(error);
        showErrorToast("Se produjo un error al eliminar el comentario..");
      });
  };

  const handleDeleteConfirmation = (lead) => {
    setSelectedLead(lead);
    setDeleteToastMessage(
      `¿Estás segura de que quieres eliminar? ${lead.name}?`
    );
    setShowDeleteToast(true);
  };

  const handleDeleteConfirmationClose = () => {
    setShowDeleteToast(false);
  };

  const filteredLeads = leads.filter(
    (lead) =>
      lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLeadsPerPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setLeadsPerPage(value);
  };

  const [columnSort, setColumnSort] = useState({
    column: "",
    direction: "asc", // 'asc' or 'desc'
  });

  const handleSort = (columnName) => {
    setColumnSort((prevSort) => {
      if (prevSort.column === columnName) {
        // Cambiar la dirección si ya está ordenada por esa columna
        return {
          ...prevSort,
          direction: prevSort.direction === "asc" ? "desc" : "asc",
        };
      } else {
        // Ordenar por una nueva columna en orden ascendente
        return {
          column: columnName,
          direction: "asc",
        };
      }
    });
  };

  const sortedLeads = [...filteredLeads].sort((a, b) => {
    const columnA = (a[columnSort.column] || "").toLowerCase();
    const columnB = (b[columnSort.column] || "").toLowerCase();

    if (columnSort.direction === "asc") {
      return columnA.localeCompare(columnB);
    } else {
      return columnB.localeCompare(columnA);
    }
  });

  return (
    <div style={{ width: "100%" }}>
<div style={{ display: 'flex', width: '100%', marginTop: '13%' }}>
      <h1>Clientes</h1>

      <div
        className="header-buttons"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
          marginLeft: "50%",
          marginTop: "-3%",
        }}
      >
        {currentUser && currentUser.staff_status === "administrator" && (
          <>
            <Button
              variant="primary"
              onClick={handleCreateLead}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Nuevo cliente
            </Button>
            <Button
              variant="primary"
              onClick={handleDownloadCSV}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Descargar
            </Button>
          </>
        )}
        {currentUser && currentUser.staff_status === "sales" && (
          <>
            <Button
              variant="primary"
              onClick={handleCreateLead}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Nuevo cliente
            </Button>
          </>
        )}
      </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Form.Group controlId="searchTerm"  style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre o correo electrónico"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              borderRadius: "5px",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              transition: "box-shadow 0.3s",
              outline: "none",
              padding: "6px",
              width: "30%",
            }}
            onFocus={(e) => {
              e.target.style.boxShadow = "0 0 6px rgba(0, 0, 255, 0.5)";
            }}
            onBlur={(e) => {
              e.target.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.5)";
            }}
          />
        </Form.Group>
        <Form.Group controlId="leadsPerPage" style={{ marginBottom: "10px" }}>
          <Form.Label style={{ display: 'flex', alignItems: 'center' }}>Clientes por pagina:</Form.Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Control
              as="input"
              type="number"
              min="1"
              value={leadsPerPage}
              onChange={handleLeadsPerPageChange}
              style={{
                borderRadius: "5px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "box-shadow 0.3s",
                outline: "none",
                padding: "6px",
                marginLeft: "5px",
                marginRight: "5px",
                width: "50px",
                textAlign: "center",
              }}
              onFocus={(e) => {
                e.target.style.boxShadow = "0 0 6px rgba(0, 0, 255, 0.5)";
              }}
              onBlur={(e) => {
                e.target.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.5)";
              }}
            />
          </div>
        </Form.Group>
      </div>

      <Table
        striped
        bordered
        hover
        className="leads-table"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th onClick={() => handleSort("name")}>Nombre del Vendedor</th>
            <th onClick={() => handleSort("name")}>Nombre de la Empresa</th>
            <th onClick={() => handleSort("email")}>Correo electronico</th>
            <th>Numero</th>
            <th>Descripción</th>
            <th onClick={() => handleSort("priority")}>Prioridad</th>
            <th onClick={() => handleSort("status")}>Estado</th>
            <th>Último comentario</th>
            <th>Comportamiento</th>
          </tr>
        </thead>
        <tbody>
          {currentLeads.map((lead) => (
            <React.Fragment key={lead.id}>
              <tr>
                <td>
                  <a
                    href="#"
                    style={{
                      display: "inline-block",
                      padding: "6px 12px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "1.42857143",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      verticalAlign: "middle",
                      msTouchAction: "manipulation",
                      touchAction: "manipulation",
                      cursor: "pointer",
                      userSelect: "none",
                      backgroundImage: "none",
                      // border: '1px solid transparent',
                      borderRadius: "4px",
                      textDecoration: "none",
                      color: "#333",
                      backgroundColor: "#fff",
                      borderColor: "#ccc",
                    }}
                    onClick={() => {
                      handleEdit(lead);
                      setIsEditMode(false); // Desactiva el modo de edición al hacer clic en el nombre
                    }}
                  >
                    {lead.contact_person_name}
                  </a>
                </td>
                <td>{lead.name}</td>

                <td>{lead.email}</td>
                <td>{lead.number}</td>
                <td>{lead.description}</td>
                <td>{lead.priority}</td>
                <td>{lead.status}</td>
                <td>
                {lead.lastComment ? (
                <div>
                  <Button
                    variant="link"
                    onClick={() => handleLastCommentClick(lead)}
                  >
                    Ver
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => handleCreateComment(lead)}
                  >
                    Crear comentario
                  </Button>
                  </div>
                  
                ) : (
                  <Button
                    variant="link"
                    onClick={() => handleCreateComment(lead)}
                  >
                    Crear comentario
                  </Button>
                )}
              </td>
                <td>
                  {currentUser &&
                    currentUser.staff_status === "administrator" && (
                      <>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteConfirmation(lead)}
                          className="action-button"
                        >
                          Eliminar
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handleEdit(lead)}
                          className="action-button"
                        >
                          Editar
                        </Button>
                      </>
                    )}
                  {currentUser && currentUser.staff_status === "sales" && (
                    <>
                      <Button
                        variant="primary"
                        onClick={() => handleEdit(lead)}
                        className="action-button"
                      >
                        Editar
                      </Button>
                    </>
                  )}
                </td>
              </tr>
              {selectedLead === lead && selectedLeadComments.length > 0 && (
                <tr>
                  <td colSpan="8" style={{ padding: "0" }}>
                    <Table bordered hover className="comments-table">
                      <thead>
                        <tr>
                          <th>Comentario</th>
                          <th>Usuario</th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLeadComments.map((comment) => (
                          <tr key={comment.id}>
                            <td>{comment.comment}</td>
                            <td>{comment.user}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => handleDeleteComment(comment.id)}
                                className="action-button"
                              >
                                Eliminar
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div>
        {totalPages > 1 && (
          <div
            className="pagination-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ marginRight: "5px" }}
            >
              Anterior
            </Button>
            <div className="page-info" style={{ margin: "0 5px" }}>
              Pagina {currentPage}
            </div>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ marginLeft: "5px" }}
            >
              Siguiente
            </Button>
          </div>
        )}
      </div>

      {/* Modal de creación (Create New Lead) */}
      <Modal
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        centered
        className="modal-above-table"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear nuevo cliente potencial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "calc(100vh - 410px)", overflowY: "auto" }}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabChangeCreate}
              className="mb-3 custom-tabs"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tab
                eventKey="client"
                title={<span style={{ color: "black" }}>Cliente</span>}
                className="custom-tab"
              >
                {activeTab === "client" && (
                  <>
                    <Form.Group controlId="name">
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={newLead.name || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={newLead.email || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_description">
                      <Form.Label>Descripción Breve de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={newLead.description || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="number">
                      <Form.Label>Número de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        value={newLead.number || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="newPriority">
          <Form.Label>Prioridad</Form.Label>
          <Form.Control
            as="select"
            name="priority"
            value={newLead.priority}
            onChange={handleNewLeadInputChange}
          >
            <option value="">Seleccionar prioridad</option>
            <option value="bajo">Baja</option>
            <option value="medio">Medio</option>
            <option value="alto">Alta</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="newStatus">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            name="status"
            value={newLead.status}
            onChange={handleNewLeadInputChange}
          >
            <option value="">Seleccionar estado</option>
            <option value="nuevo">Nuevo</option>
            <option value="contactado">Contactado</option>
            <option value="ganado">Cliente Ganado</option>
          </Form.Control>
        </Form.Group>
                    <Form.Group controlId="company_address">
                      <Form.Label>Dirección de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="company_address"
                        value={newLead.company_address || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_category">
                      <Form.Label>Categoría de Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_category"
                        value={newLead.brand_category || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_description">
                      <Form.Label>Descripción Detallada de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_description"
                        value={newLead.brand_description || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_differentiation">
                      <Form.Label>Diferenciación de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_differentiation"
                        value={newLead.brand_differentiation || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    {/* Nuevos campos del cliente */}
                    <Form.Group controlId="brand_need">
                      <Form.Label>Necesidad que Satisface la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_need"
                        value={newLead.brand_need || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_keywords">
                      <Form.Label>
                        Palabras Clave de Percepción de la Marca
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_keywords"
                        value={newLead.brand_keywords || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_personality">
                      <Form.Label>Personalidad de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_personality"
                        value={newLead.brand_personality || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_slogan">
                      <Form.Label>Eslogan o Motto de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_slogan"
                        value={newLead.brand_slogan || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_style">
                      <Form.Label>Preferencia de Estilo de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_style"
                        value={newLead.brand_style || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_values">
                      <Form.Label>Valores de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_values"
                        value={newLead.brand_values || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_virtues">
                      <Form.Label>Virtudes de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_virtues"
                        value={newLead.brand_virtues || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_experience">
                      <Form.Label>Duración de Experiencia Comercial</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_experience"
                        value={newLead.business_experience || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_type">
                      <Form.Label>Tipo de Negocio</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_type"
                        value={newLead.business_type || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_colors">
                      <Form.Label>Colores Asociados a la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_colors"
                        value={newLead.brand_colors || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_details">
                      <Form.Label>
                        Detalles de la Información Comercial
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="business_details"
                        value={newLead.business_details || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="website_social">
                      <Form.Label>
                        Sitio Web o Redes Sociales de la Empresa
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="website_social"
                        value={newLead.website_social || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="vendor"
                title={<span style={{ color: "black" }}>Vendedor</span>}
                className="custom-tab"
              >
                {activeTab === "vendor" && (
                  <>
                    <Form.Group controlId="contact_person_name">
                      <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_name"
                        value={newLead.contact_person_name || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_phone">
                      <Form.Label>
                        Teléfono de la Persona de Contacto
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_phone"
                        value={newLead.contact_person_phone || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_email">
                      <Form.Label>Correo Electrónico del Vendedor</Form.Label>
                      <Form.Control
                        type="email"
                        name="contact_person_email"
                        value={newLead.contact_person_email || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_role">
                      <Form.Label>Rol del Vendedor</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_role"
                        value={newLead.contact_person_role || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="graphic_designer"
                title={
                  <span style={{ color: "black" }}>Diseñador Gráfico</span>
                }
                className="custom-tab"
              >
                {activeTab === "graphic_designer" && (
                  <>
                    <Form.Group controlId="color_palette">
                      <Form.Label>Paleta de Colores Preferida</Form.Label>
                      <Form.Control
                        type="text"
                        name="color_palette"
                        value={newLead.color_palette || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="logo_use">
                      <Form.Label>Uso Principal del Logo</Form.Label>
                      <Form.Control
                        type="text"
                        name="logo_use"
                        value={newLead.logo_use || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="design_references">
                      <Form.Label>Referencias de Diseño</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="design_references"
                        value={newLead.design_references || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="design_style">
                      <Form.Label>Estilo de Diseño Preferido</Form.Label>
                      <Form.Control
                        type="text"
                        name="design_style"
                        value={newLead.design_style || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="marketing_designer"
                title={
                  <span style={{ color: "black" }}>Diseñador de Marketing</span>
                }
                className="custom-tab"
              >
                {activeTab === "marketing_designer" && (
                  <>
                    <Form.Group controlId="current_business_goals">
                      <Form.Label>Metas Actuales del Negocio</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="current_business_goals"
                        value={newLead.current_business_goals || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="competitors">
                      <Form.Label>Principales Competidores</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="competitors"
                        value={newLead.competitors || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_audience">
                      <Form.Label>Público Objetivo</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_audience"
                        value={newLead.target_audience || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="marketing_message">
                      <Form.Label>Mensaje Clave de Marketing</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="marketing_message"
                        value={newLead.marketing_message || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="campaign_objectives">
                      <Form.Label>
                        Objetivos Específicos de la Campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="campaign_objectives"
                        value={newLead.campaign_objectives || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>
            </Tabs>
          </div>
          <Button variant="primary" onClick={handleSaveNewLead}>
            Crear Cliente Potencial
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCommentsModal}
        onHide={() => setShowCommentsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLeadComments.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Comentario</th>
                  <th>Usuario</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {selectedLeadComments.map((comment) => (
                  <tr key={comment.id}>
                    <td>{comment.comment}</td>
                    <td>{comment.user}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteComment(comment.id)}
                        className="action-button"
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay comentarios disponibles.</p>
            
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowCommentsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de edición (Edit Lead) */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="modal-above-table"
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "calc(100vh - 410px)", overflowY: "auto" }}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabChange}
              className="mb-3 custom-tabs"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tab
                eventKey="client"
                title={<span style={{ color: "black" }}>Cliente</span>}
                className="custom-tab"
              >
                {activeTab === "client" && (
                  <>
                    <Form.Group controlId="name">
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={editedLead.name || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={editedLead.email || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_description">
                      <Form.Label>Descripción Breve de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={editedLead.description || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="number">
                      <Form.Label>Número de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        value={editedLead.number || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_address">
                      <Form.Label>Dirección de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="company_address"
                        value={editedLead.company_address || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_category">
                      <Form.Label>Categoría de Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_category"
                        value={editedLead.brand_category || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_description">
                      <Form.Label>Descripción Detallada de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_description"
                        value={editedLead.brand_description || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_differentiation">
                      <Form.Label>Diferenciación de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_differentiation"
                        value={editedLead.brand_differentiation || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    {/* Nuevos campos del cliente */}
                    <Form.Group controlId="brand_need">
                      <Form.Label>Necesidad que Satisface la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_need"
                        value={editedLead.brand_need || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_keywords">
                      <Form.Label>
                        Palabras Clave de Percepción de la Marca
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_keywords"
                        value={editedLead.brand_keywords || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_personality">
                      <Form.Label>Personalidad de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_personality"
                        value={editedLead.brand_personality || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_slogan">
                      <Form.Label>Eslogan o Motto de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_slogan"
                        value={editedLead.brand_slogan || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_style">
                      <Form.Label>Preferencia de Estilo de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_style"
                        value={editedLead.brand_style || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_values">
                      <Form.Label>Valores de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_values"
                        value={editedLead.brand_values || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_virtues">
                      <Form.Label>Virtudes de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_virtues"
                        value={editedLead.brand_virtues || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_experience">
                      <Form.Label>Duración de Experiencia Comercial</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_experience"
                        value={editedLead.business_experience || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_type">
                      <Form.Label>Tipo de Negocio</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_type"
                        value={editedLead.business_type || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_colors">
                      <Form.Label>Colores Asociados a la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_colors"
                        value={editedLead.brand_colors || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_details">
                      <Form.Label>
                        Detalles de la Información Comercial
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="business_details"
                        value={editedLead.business_details || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="website_social">
                      <Form.Label>
                        Sitio Web o Redes Sociales de la Empresa
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="website_social"
                        value={editedLead.website_social || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="vendor"
                title={<span style={{ color: "black" }}>Vendedor</span>}
                className="custom-tab"
              >
                {activeTab === "vendor" && (
                  <>
                    <Form.Group controlId="contact_person_name">
                      <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_name"
                        value={editedLead.contact_person_name || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_phone">
                      <Form.Label>
                        Teléfono de la Persona de Contacto
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_phone"
                        value={editedLead.contact_person_phone || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_email">
                      <Form.Label>Correo Electrónico del Vendedor</Form.Label>
                      <Form.Control
                        type="email"
                        name="contact_person_email"
                        value={editedLead.contact_person_email || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_role">
                      <Form.Label>Rol del Vendedor</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_role"
                        value={editedLead.contact_person_role || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="graphic_designer"
                title={
                  <span style={{ color: "black" }}>Diseñador Gráfico</span>
                }
                className="custom-tab"
              >
                {activeTab === "graphic_designer" && (
                  <>
                    <Form.Group controlId="color_palette">
                      <Form.Label>Paleta de Colores Preferida</Form.Label>
                      <Form.Control
                        type="text"
                        name="color_palette"
                        value={editedLead.color_palette || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="logo_use">
                      <Form.Label>Uso Principal del Logo</Form.Label>
                      <Form.Control
                        type="text"
                        name="logo_use"
                        value={editedLead.logo_use || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="design_references">
                      <Form.Label>Referencias de Diseño</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="design_references"
                        value={editedLead.design_references || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="design_style">
                      <Form.Label>Estilo de Diseño Preferido</Form.Label>
                      <Form.Control
                        type="text"
                        name="design_style"
                        value={editedLead.design_style || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="marketing_designer"
                title={
                  <span style={{ color: "black" }}>Diseñador de Marketing</span>
                }
                className="custom-tab"
              >
                {activeTab === "marketing_designer" && (
                  <>
                    <Form.Group controlId="current_business_goals">
                      <Form.Label>Metas Actuales del Negocio</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="current_business_goals"
                        value={editedLead.current_business_goals || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="competitors">
                      <Form.Label>Principales Competidores</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="competitors"
                        value={editedLead.competitors || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_audience">
                      <Form.Label>Público Objetivo</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_audience"
                        value={editedLead.target_audience || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="marketing_message">
                      <Form.Label>Mensaje Clave de Marketing</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="marketing_message"
                        value={editedLead.marketing_message || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="campaign_objectives">
                      <Form.Label>
                        Objetivos Específicos de la Campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="campaign_objectives"
                        value={editedLead.campaign_objectives || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          {isEditMode && selectedLead ? (
            <Button variant="primary" onClick={handleSave}>
              Guardar cambios
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>

      {/* Toast de error */}
      {errorToast && (
        <Toast
          show={errorToast}
          onClose={() => setErrorToast(null)}
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            minWidth: "200px",
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{errorToast}</Toast.Body>
        </Toast>
      )}

      {/* Toast de confirmación de eliminación */}
      <Toast
        show={showDeleteToast}
        onClose={handleDeleteConfirmationClose}
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
          background: "white",
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">Confirmar eliminación</strong>
        </Toast.Header>
        <Toast.Body>
          {deleteToastMessage}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="secondary"
              onClick={handleDeleteConfirmationClose}
              style={{ marginRight: "5px" }}
            >
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedLead.id)}
            >
              Eliminar
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ContactsInfo;
