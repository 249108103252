import axios from 'axios';

class JobDataService {
  // Métodos para el modelo Empresa (Company)
  getAllCompanies(token) {
    return axios.get('https://abcupon-backend.com/employee/companies/');
  }

  createCompany(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/companies/', data);
  }

  updateCompany(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/companies/${id}/`, data);
  }

  deleteCompany(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/companies/${id}/`);
  }

  // Métodos para el modelo Categoría de trabajo (JobCategory)
  getAllJobCategories(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/job-categories/');
  }

  createJobCategory(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/job-categories/', data);
  }

  updateJobCategory(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/job-categories/${id}/`, data);
  }

  deleteJobCategory(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/job-categories/${id}/`);
  }

  // Métodos para el modelo Publicación de trabajo (JobPosting)
  getAllJobPostings(token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/jobs/');
  }

  createJobPosting(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/jobs/', data);
  }

  updateJobPosting(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/jobs/${id}/`, data);
  }

  deleteJobPosting(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/jobs/${id}/`);
  }

  // Métodos para el modelo Aplicación de trabajo (JobApplication)
  createJobApplication(data, token, jobId) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post(`https://abcupon-backend.com/employee/jobs/${jobId}/apply/`, data);
  }

  getAllJobApplications(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/applications/');
  }
  deleteJobApplication(jobId, token, applicationId) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/jobs/${jobId}/apply/${applicationId}/`);
  }

  // Métodos para el modelo Pregunta de trabajo (JobQuestion)
  createJobQuestion(data, token, jobId) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post(`https://abcupon-backend.com/employee/jobs/${jobId}/questions/`, data);
  }

  getAllJobQuestions(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/questions/');
  }

  // Métodos para el modelo Alerta de trabajo (JobAlert)
  getAllJobAlerts(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/job-alerts/');
  }

  createJobAlert(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/job-alerts/create/', data);
  }
  deleteJobAlert(jobId, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/job-alerts/${jobId}/`);
  }

  // Métodos para el modelo Nivel de experiencia (ExperienceLevel)
  getAllExperienceLevels(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/experience-levels/');
  }

  createExperienceLevel(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/experience-levels/', data);
  }

  updateExperienceLevel(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/experience-levels/${id}/`, data);
  }

  deleteExperienceLevel(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/experience-levels/${id}/`);
  }

  // Métodos para Habilidades (Skill)
  getAllSkills(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/skills/');
  }

  createSkill(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/skills/', data);
  }

  updateSkill(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/skills/${id}/`, data);
  }

  deleteSkill(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/skills/${id}/`);
  }

  // Métodos para Beneficios (Benefit)
  getAllBenefits(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/benefits/');
  }

  createBenefit(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/benefits/', data);
  }

  updateBenefit(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/benefits/${id}/`, data);
  }

  deleteBenefit(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/benefits/${id}/`);
  }

  // Métodos para Etiquetas de trabajo (JobTag)
  getAllJobTags(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/job-tags/');
  }

  createJobTag(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post('https://abcupon-backend.com/employee/job-tags/', data);
  }

  updateJobTag(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/job-tags/${id}/`, data);
  }

  deleteJobTag(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/job-tags/${id}/`);
  }
}

export default new JobDataService();
