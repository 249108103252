import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { useMediaQuery } from "react-responsive";
import slider1 from "../../../assets/slider1.jpg";
import slider2 from "../../../assets/slider2.jpg";
import slider3 from "../../../assets/slider3.jpg";
import slider4 from "../../../assets/slider4.jpg";
import slider5 from "../../../assets/slider5.jpg";

const Carousel1 = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <div className="imageContainerStyle" >
          {isMobile ? (
            <div style={{marginTop:"8%"}}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              interval={4000}
              stopOnHover={false}
              emulateTouch={true}
              infiniteLoop={true}
              selectedItem={0} // Asegura que el primer elemento seleccionado sea el original
            >
              {[...Array(10)].map((_, repeatIndex) =>
                [slider1, slider2, slider3, slider4, slider5].map(
                  (image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                  )
                )
              )}
            </Carousel>
            </div>
          ) : (
            
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              interval={4000}
              stopOnHover={false}
              emulateTouch={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              infiniteLoop={true}
              selectedItem={0} // Asegura que el primer elemento seleccionado sea el original
            >
              {[...Array(10)].map((_, repeatIndex) =>
                [slider1, slider2, slider3, slider4, slider5].map(
                  (image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                  )
                )
              )}
            </Carousel>
            
          )}
        </div>
  )
}

export default Carousel1