import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom"; // Importa Link
import "./Redireccion.css"; // Asegúrate de que la ruta del archivo CSS sea correcta

const Redireccion = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [vibrateButton, setVibrateButton] = useState(false);
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVibrateButton(null);
        }, 200);
        return () => clearTimeout(timeoutId);
    }, [vibrateButton]);

    return (
        <div>
           <div className="buttonContainerStyle" style={{ width: "75%", marginLeft: "15%", marginTop: "2%" }}>

                        <div className="buttonGroup">
                    <Link to="/avisos_economicos">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "allProducts" ? 'vibrating' : ''}`} >
                            16-AVISOS ECONÓMICOS
                        </button>
                    </Link>

                    <Link to="/directorio_comercial">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "clasificados" ? 'vibrating' : ''}`}>
                            24-DIRECTORIO_COMERCIAL
                        </button>
                    </Link>
                </div>

                <div className="buttonGroup">
                    <Link to="/tienda">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "products" ? 'vibrating' : ''}`}>
                            11-TIENDA EN LÍNEA
                        </button>
                    </Link>

                    <Link to="/bolsadeempleo">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "bolsaEmpleo" ? 'vibrating' : ''}`}>
                            02-BOLSA DE EMPLEO
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Redireccion;
