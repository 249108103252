import React, { useState, useEffect } from 'react';
import { FaCar, FaBriefcase, FaHome, FaHospital, FaWifi, FaCut, FaGlobe, FaBalanceScale, FaUtensils } from 'react-icons/fa';
import { IoMdThermometer } from 'react-icons/io';
import { BsThermometerHalf } from 'react-icons/bs';
import { BiRuler } from 'react-icons/bi';
import { HiOutlineCamera, HiOutlineScale } from 'react-icons/hi';
import { RiScissorsCutFill } from 'react-icons/ri';
import { GiWireframeGlobe, GiProcessor } from 'react-icons/gi';
import FileDataService from '../../../services/files';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import img1 from '../../../assets/categorias/1.jpg'
import img2 from '../../../assets/categorias/2.jpg'
import img3 from '../../../assets/categorias/3.jpg'
import img4 from '../../../assets/categorias/4.jpg'
import img5 from '../../../assets/categorias/5.jpg'
import img6 from '../../../assets/categorias/6.jpg'
import img7 from '../../../assets/categorias/7.jpg'
import img8 from '../../../assets/categorias/8.jpg'
import img9 from '../../../assets/categorias/9.jpg'
import img10 from '../../../assets/categorias/10.jpg'
import img11 from '../../../assets/categorias/11.jpg'
import img12 from '../../../assets/categorias/12.jpg'
import img13 from '../../../assets/categorias/13.jpg'
import img14 from '../../../assets/categorias/14.jpg'
import img15 from '../../../assets/categorias/15.jpg'
import img16 from '../../../assets/categorias/16.jpg'
import img17 from '../../../assets/categorias/17.jpg'
import img18 from '../../../assets/categorias/18.jpg'
import img19 from '../../../assets/categorias/19.jpg'
import img20 from '../../../assets/categorias/20.jpg'
import img21 from '../../../assets/categorias/21.jpg'
import img22 from '../../../assets/categorias/22.jpg'
import img23 from '../../../assets/categorias/23.jpg'
import img24 from '../../../assets/categorias/24.jpg'
import ABCupon from '../../../assets/categorias/ABCupon.jpg'

const values = [
    {
      id: 1,
      icon: <img src={img1} alt="Categoría 1.Vehiculos, Repuestos y Talleres" />,
      title: "1.Vehiculos, Repuestos y Talleres",
      component: "Vehiculosrepuestos",
    },
    {
      id: 2,
      icon: <img src={img2} alt="Categoría 2.Bolsa de Empleo y Servicios Profesionales" />,
      title: "2.Bolsa de Empleo y Servicios Profesionales",
      component: "BolsaDeEmpleo",
    },
    {
      id: 3,
      icon: <img src={img3} alt="Categoría 3. Casas Y Lotes" />,
      title: "3. Casas Y Lotes",
      component: "Casasylotes",
    },
    {
      id: 4,
      icon: <img src={img4} alt="Categoría 4. Clinicas, Salud Y Estética" />,
      title: "4. Clinicas, Salud Y Estética",
      component: "Clinicasalud",
    },
    {
      id: 5,
      icon: <img src={img5} alt="Categoría 5. Comunicación, Tecnología Y Energía" />,
      title: "5. Comunicación, Tecnología Y Energía",
      component: "Comunicaciontecnologia",
    },
    {
    id: 6,
    icon: <img src={img6} alt="Categoría 6. Construcción, Diseño Y Supervisión" />,
    title: "6. Construcción, Diseño Y Supervisión",
    component: "Construcciondiseño",
  },
    {
      id: 7,
      icon: <img src={img7} alt="Categoría 7. Cupones de Descuento de Inversión e intercambio" />,
      title: "7. Cupones de Descuento de Inversión e intercambio",
      component: "Cuponesdedescuento",
    },
    {
      id: 8,
      icon: <img src={img8} alt="Categoría 8. Centros Educativos" />,
      title: "8. Centros Educativos",
      component: "Centroseducativos",
    },
    {
      id: 9,
      icon: <img src={img9} alt="Categoría 9. Entretenimiento Restaurantes Y Turismo" />,
      title: "9. Entretenimiento Restaurantes Y Turismo",
      component: "Entretenimientorestaurantes",
    },
    {
        id: 10,
        icon: <img src={img10} alt="Categoría 10. Ferretería Y Depósito" />,
        title: "10. Ferretería Y Depósito",
        component: "Ferreteriaydeposito",
      },
      {
        id: 11,
        icon: <img src={img11} alt="Categoría 11. Hogar Tienda Y Electrónica" />,
        title: "11. Hogar Tienda Y Electrónica",
        component: "Hogartiendayelectronica",
      },
      {
        id: 12,
        icon: <img src={img12} alt="Categoría 12. Plataforma de Inversiones" />,
        title: "12. Plataforma de Inversiones",
        component: "Plataformadeinversiones",
      },
      {
        id: 13,
        icon: <img src={img13} alt="Categoría 13. Legal Y Notariado" />,
        title: "13. Legal Y Notariado",
        component: "Legalynotariado",
      },
      {
        id: 14,
        icon: <img src={img14} alt="Categoría 14. Librería Y Editoriales" />,
        title: "14. Librería Y Editoriales",
        component: "Libreriayeditoriales",
      },
      {
        id: 15,
        icon: <img src={img15} alt="Categoría 15. Ofertas Y Subastas" />,
        title: "15. Ofertas Y Subastas",
        component: "Ofertasysubastas",
      },
      {
        id: 16,
        icon: <img src={img16} alt="Categoría 16. Noticias Y Avisos Clasificados" />,
        title: "16. Noticias Y Avisos Clasificados",
        component: "Noticiasyavisosclasificados",
      },
      {
        id: 17,
        icon: <img src={img17} alt="Categoría 17. Póliza Y Seguros" />,
        title: "17. Póliza Y Seguros",
        component: "Polizayseguros",
      },
      {
        id: 18,
        icon: <img src={img18} alt="Categoría 18. Préstamos Y Rescate sobre propiedades" />,
        title: "18. Préstamos Y Rescate sobre propiedades",
        component: "Prestamosyrescatesobrepropiedades",
      },
      {
        id: 19,
        icon: <img src={img19} alt="Categoría 19. Productos Y Servicios Cooperativos" />,
        title: "19. Productos Y Servicios Cooperativos",
        component: "Productosyservicioscooperativos",
      },
      {
        id: 20,
        icon: <img src={img20} alt="Categoría 20. Publicidad Y Páginas Web" />,
        title: "20. Publicidad Y Páginas Web",
        component: "Publicidadypaginasweb",
      },
      {
        id: 21,
        icon: <img src={img21} alt="Categoría 21. Fundación Eslabones" />,
        title: "21. Fundación Eslabones",
        component: "Fundacioneslabones",
      },
      {
        id: 22,
        icon: <img src={img22} alt="Categoría 23. Hoteles y Turismo" />,
        title: "23. Hoteles y Turismo",
        component: "Hoteles_turismo",
      },
      {
        id: 23,
        icon: <img src={img23} alt="Categoría 24. Transporte Y Mensajería" />,
        title: "24. Transporte Y Mensajería",
        component: "Transporteymensajeria",
      },
      {
        id: 24,
        icon: <img src={img24} alt="Categoría 25. Transporte Y Mensajería" />,
        title: "25. Transporte Y Mensajería",
        component: "Transporteymensajeria",
      },
  ];

  const Botonera = ({ setActiveComponent }) => {
  const [imageUrl, setImageUrl] = useState('');
  const token = useSelector(state => state.authentication.token);

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = () => {
    FileDataService.getAll(token)
      .then(response => {
        const chipsImage = response.data.find(file => file.name === 'Chips');
        if (chipsImage) {
          setImageUrl(chipsImage.file);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleMouseEnter = (event) => {
    const element = event.currentTarget;
    element.style.position = 'relative'; // Asegúrate de que el elemento tenga 'position: relative'
  
    // Verificar si ya existe un overlay para evitar duplicados
    let overlay = element.querySelector('.green-overlay');
    if (!overlay) {
      overlay = document.createElement('div');
      overlay.className = 'green-overlay'; // Agregar clase para el estilo
      overlay.style.position = 'absolute';
      overlay.style.top = 0;
      overlay.style.left = 0;
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.backgroundColor = 'rgba(13, 255, 0, 0.5)'; // Fondo verde semitransparente
      overlay.style.pointerEvents = 'none'; // Permitir que los clics pasen a través del overlay
      element.appendChild(overlay);
    }
  };
  
  const handleMouseLeave = (event) => {
    const element = event.currentTarget;
    const overlay = element.querySelector('.green-overlay');
    if (overlay) {
      element.removeChild(overlay); // Eliminar la capa verde cuando el ratón se salga
    }
  };

  return (
    <section >
      <div
        
      />

      <div >
        <div className="values__right text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <br/>
          <h2 style={{ color: 'black', textShadow: '2px 2px 4px black' }}>Botonera de categorias, presionar para ir a la categoria</h2>
          <br/>
          <div className="values__wrapper">
    {values.slice(0, 12).map(({ id, icon, title, component }) => (
      <a
        key={id} // Agregar clave única para cada elemento
        onClick={() => {
          setActiveComponent(component);
        }}
      >
        <span
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="values__span"
          style={{ display: 'inline-block', position: 'relative' }} // Asegúrate de que el span tenga 'position: relative'
        >
          {React.cloneElement(icon, {
            style: { width: '100%', height: 'auto', objectFit: 'cover' },
          })}
        </span>
      </a>
    ))}
  </div>

          <div style={{ width: '100%', textAlign: 'center', margin: '10px 0' }}>
            <img src={ABCupon} alt="ABCupon" style={{ width: '40%', height: 'auto', marginLeft:"30%" }} />
          </div>
          <div className="values__wrapper">
            {values.slice(12, 24).map(({ id, icon, title, component}) => (
              <a onClick={() => setActiveComponent(component)}><span
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="values__span"
          style={{ display: 'inline-block', position: 'relative' }} // Asegúrate de que el span tenga 'position: relative'
        >
              {React.cloneElement(icon, {
                style: { width: '100%', height: 'auto', objectFit: 'cover' }, // Ajuste de estilos
              })}
            </span></a>

            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Botonera;