import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/imagenes/logo_abcupon.jpg";
import {
  FiHome,
  FiX,
  FiMenu,
  FiPackage,
  FiMonitor,
  FiSettings,
  FiBell,
  FiBookOpen,
  FiPhone,
  FiServer,
  FiLayers,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { FaUser, FaSignOutAlt, FaFolder } from "react-icons/fa";
import { Popover, Transition } from "@headlessui/react";
import DotLoader from "react-spinners/DotLoader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { setAuthentication } from "../../redux/actions/authActions";
import { AuthContext } from "../login/AuthContext";
import ReactFlagsSelect from "react-flags-select";
import "./Navbar.css";
import { Toggle } from "../darkmode/Toggle";
import { useMediaQuery } from "react-responsive";

const Navbar = ({ logout, setIsSidebar }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showProductsMenu, setShowProductsMenu] = useState(false);
  const [showSubOptions, setShowSubOptions] = useState(false);
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const [selected, setSelected] = useState("CR");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [pressedButton, setPressedButton] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [showCurrentUserModal, setShowCurrentUserModal] = useState(false);
  const isMobile = useMediaQuery({ query: '(min-width: 1025px) and (max-width: 1440px)' });
  const isMobile1 = useMediaQuery({ query: "(max-width: 1220px)" });

  const fetchCurrentUserData = () => {
    const currentUser = localStorage.getItem("currentUser");
    setCurrentUser(JSON.parse(currentUser));
  };
  useEffect(() => {
    fetchCurrentUserData();
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    if (storedToken && storedUser) {
      dispatch(setAuthentication(storedToken, storedUser));
    }
    setLoading(false);
  }, []);

  function scrollFunction() {
    if (document.getElementById("navbar")) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("navbar").classList.add("shadow-navbar");
        document.getElementById("navbar").classList.add("bg-white");
      } else {
        document.getElementById("navbar").classList.remove("shadow-navbar");
        document.getElementById("navbar").classList.remove("bg-white");
      }
    }
  }

  const handleCurrentUser = () => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
      setShowCurrentUserModal(true);
    }
  };

  const solutions = [
    {
      name: "11-Tienda_Virtual",
      description:
        " Trabajamos de la mano con empresas y organizaciones de diversos sectores para lograr un impacto significativo en nuestras comunidades.",
      href: "/tienda",
      icon: FiMonitor,
    },
    // {
    //   name: 'Asociados',
    //   description: ' Trabajamos de la mano con empresas y organizaciones de diversos sectores para lograr un impacto significativo en nuestras comunidades.',
    //   href: '/technology',
    //   icon: FiMonitor,
    // },
    {
      name: "24-Directorio_Comercial",
      description: "Visualiza el Directorio Central de 24 categorias",
      href: "/directorio_comercial",
      icon: FiPackage,
    },
    {
      name: "16-Avisos_Economicos",
      description: "Mantente actualizado con las últimas noticias",
      href: "/avisos_economicos",
      icon: FiBell,
    },
    {
      name: "02-Bolsa_de_Empleo",
      description: "Mantente actualizado con las últimas noticias",
      href: "/bolsadeempleo",
      icon: FiBell,
    },
    {
      name: "Cotizador",
      description: "Crea tu propio paquete de publicidad",
      href: "/cotizador",
      icon: FiPackage,
    },
    {
      name: "16-Noticias",
      description:
        "Somos una plataforma virtual que reúne a numerosos proveedores cuidadosamente seleccionados, quienes ofrecen sus servicios con altos estándares de excelencia.",
      href: "/noticias",
      icon: FiSettings,
    },
    // {
    //   name: 'Avisos Clasificados',
    //   description: 'Mantente actualizado con las últimas noticias',
    //   href: '/news',
    //   icon: FiBell,
    // },
    {
      name: "Blog",
      description: "Lee nuestras últimas publicaciones en el blog",
      href: "/blog",
      icon: FiBookOpen,
    },
    {
      name: "Contacto",
      description: "Ponte en contacto con nosotros",
      href: "/contacto",
      icon: FiPhone,
    },
  ];

  const handleMobileMenuClick = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("currentUser");
    localStorage.clear();
    navigate("/login"); // Redirigir al usuario a la página de inicio de sesión
    window.location.reload();
  };

  useEffect(() => {
    let timer;
    if (showProductsMenu) {
      timer = setTimeout(() => {
        setShowProductsMenu(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showProductsMenu]);

  useEffect(() => {
    const logoImage = document.getElementById("logo-image");

    const startVibration = () => {
      logoImage.classList.add("logo-vibrating");
    };

    const stopVibration = () => {
      logoImage.classList.remove("logo-vibrating");
    };

    // Iniciar vibración cada 5 segundos
    const vibrationInterval = setInterval(() => {
      startVibration();

      // Detener la vibración después de 0.5 segundos (ajusta según sea necesario)
      setTimeout(() => {
        stopVibration();
      }, 500);
    }, 5000);

    // Limpiar el intervalo al desmontar el componente
    return () => {
      clearInterval(vibrationInterval);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleButtonClick = (item) => {
    if (item.href === "/servicios") {
      navigate("/servicios");
    }
    setPressedButton(item.href); // Actualizar el botón presionado
  };

  const handleLogoClick = () => {
    setPressedButton(null); // Desactivar cualquier botón presionado
  };

  return (
    <nav
      id="navbar"
      className=" transition duraction-300 easy-in-out fixed inset-x-0 top-0 w-full h-15vh z-50"
    >
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between h-full">
        {/* Logo */}
        <div className="flex-shrink-0">
          <Link
            to="/"
            className="text-xl font-bold text-red-500"
            onClick={() => {
              handleLogoClick();
              scrollToTop();
            }}
          >
            <img
              id="logo-image"
              src={logo}
              alt="ABCupon logo"
              width={200}
              height={200}
              className=""
            />
            <p
              className="text-sm"
              style={{
                color: "lightgreen",
                WebkitTextStroke: "1px green", // WebkitTextStroke para navegadores basados en Webkit
                textStroke: "1px black", // Propiedad estándar
                display: "inline-block", // Para que el borde no afecte el diseño
              }}
            >
              Ir a página principal
            </p>
          </Link>
        </div>

        {/* Menu en pantallas medianas y grandes */}
        <div className="hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          {solutions.map((item) => (
        <div
          key={item.name}
          className="relative"
          onMouseEnter={() => {
            if (item.name === 'Products') {
              setShowProductsMenu(true);
            }
          }}
          onMouseLeave={() => {
            if (item.name === 'Products') {
              setShowProductsMenu(false);
              setShowSubOptions(false);
            }
          }}
          style={{ zIndex: showProductsMenu ? 999 : 'auto' }}
        >
          <NavLink
            to={item.href}
            className={`buttonStyle2 ${pressedButton === item.href ? 'pressed' : ''}`}
            // Cambiar el tamaño de la fuente según si es móvil o no
            style={{ fontSize: isMobile1 ? '0.5em' : (isMobile ? '0.8em' : '1.2em') }} // Ajusta los valores según sea necesario
            onClick={() => setPressedButton(item.href)}
          >
            {item.name}
          </NavLink>

          {item.name === 'Products' && showProductsMenu && item.subOptions && (
            <div
              className="absolute left-0 mt-2 py-2 bg-white border border-gray-200 rounded-md shadow-lg"
              onMouseEnter={() => {
                setShowProductsMenu(true);
                setShowSubOptions(true);
              }}
              onMouseLeave={() => {
                setShowSubOptions(false);
              }}
              style={{ zIndex: showSubOptions ? 999 : 'auto', margin: 0 }}
            >
              {item.subOptions.map((subOption) => (
                <NavLink
                  key={subOption.name}
                  to={subOption.href}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  activeClassName="bg-red-500 text-white"
                  onMouseEnter={() => setShowSubOptions(true)}
                  onMouseLeave={() => setShowSubOptions(false)}
                  style={{ padding: '0.5rem 1rem', margin: 0 }}
                >
                  <subOption.icon className="mr-2 h-5 w-5 text-red-500" />
                  {subOption.name}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      ))}

          {user !== null ? (
            <>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                        ${open ? "text-red-500" : "text-black"}
                        nav-link flex items-center focus:outline-none px-3 py-2 text-sm font-small leading-6
                        border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4
                      `}
                    >
                      {user}
                      {open ? (
                        <FiChevronUp className="ml-1 w-5 h-5" />
                      ) : (
                        <FiChevronDown className="ml-1 w-5 h-5" />
                      )}
                    </Popover.Button>

                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Popover.Panel
                        static
                        className="absolute z-50 w-48 bg-white border border-gray-200 rounded-md shadow-lg"
                      >
                        <div className="py-1">
                          <button
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={handleCurrentUser}
                          >
                            <FaUser className="mr-2 h-5 w-5 text-red-500" />
                            Perfil
                          </button>
                          {currentUser &&
                            currentUser.staff_status === "user" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/files"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Archivos
                              </Link>
                            )}
                          {currentUser &&
                            currentUser.staff_status === "design" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/files"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Archivos
                              </Link>
                            )}
                          {currentUser &&
                            currentUser.staff_status === "sales" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/customer"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Clientes
                              </Link>
                            )}
                          {currentUser &&
                            currentUser.staff_status === "administrator" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/files"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Archivos
                              </Link>
                            )}
                          {currentUser &&
                            currentUser.staff_status === "administrator" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/customer"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Clientes
                              </Link>
                            )}

                          {/* <Link className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" to={"/files"}>
                            <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                            Files
                          </Link> */}
                          {currentUser &&
                            currentUser.staff_status === "administrator" && (
                              <Link
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                to={"/user"}
                              >
                                <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                                Usuarios
                              </Link>
                            )}
                          {/* <Link className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" to={"/user"}>
                            <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                            Usuario
                          </Link> */}
                          <Link
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            to={"/calendar"}
                          >
                            <FaFolder className="mr-2 h-5 w-5 text-red-500" />
                            Calendario
                          </Link>
                          <button
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={handleLogout}
                          >
                            <FaSignOutAlt className="mr-2 h-5 w-5 text-red-500" />
                            Cerrar Sesion
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </>
          ) : (
            <>
              {user === null && (
                <Link
                  to="/login"
                  className="inline-flex ml-12 items-center rounded-md border border-transparent bg-red-button px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900 hover:text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Inicio de sesion
                  <DotLoader
                    className="ml-3 -mr-1 h-5 w-5"
                    loading={loading}
                    size={20}
                    color="#fff"
                  />
                </Link>
              )}
            </>
          )}
        </div>

        {/* Icono del menú en dispositivos móviles */}
        <div className="lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2 max-w-screen-sm mx-auto">
          {/* <ReactFlagsSelect
  selected={selected}
  onSelect={(code) => {
    setSelected(code);
    localStorage.setItem('selectedCountry', countryCodeToFullName[code]);
    console.log('Country selected:', countryCodeToFullName[code]);
  }}
  countries={countriesToShow}
  customLabels={countryCodeToFullName}
  showSelectedLabel={false} // No mostrará el texto del país seleccionado
  className="halfWidth" // Puedes mantener la clase halfWidth si también quieres el ancho reducido
/> */}

          <button
            onClick={handleMobileMenuClick}
            className="p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:ring"
          >
            {isMobileMenuOpen ? (
              <FiX className="h-6 w-6" aria-hidden="true" />
            ) : (
              <FiMenu className="h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>

      {/* Menú desplegable en dispositivos móviles */}
      <Transition
        show={isMobileMenuOpen}
        as={React.Fragment}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute top-16 inset-x-0 transform origin-top-right transition duration-300 ease-in-out lg:hidden z-40">
          <div className="overflow-y-auto max-h-screen rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
              {solutions.map((item, index) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="block rounded-md py-2 px-3 text-base font-medium text-blue-700 hover:text-blue-900 hover:bg-blue-100 transition duration-150 ease-in-out"
                  activeClassName="text-blue-500" // Aplica la clase cuando esté activo
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <item.icon
                        className="h-6 w-6 text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {item.name}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </NavLink>
              ))}

              {user !== null ? (
                <div className="py-1">
                  <button
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleCurrentUser}
                  >
                    <FaUser className="mr-2 h-5 w-5 text-blue-500" />
                    Perfil
                  </button>
                  {currentUser && currentUser.staff_status === "user" && (
                    <Link
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      to={"/files"}
                    >
                      <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                      Archivos
                    </Link>
                  )}
                  {currentUser &&
                    currentUser.staff_status === "administrator" && (
                      <Link
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        to={"/files"}
                      >
                        <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                        Archivos
                      </Link>
                    )}
                  {/* <Link className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" to={"/files"}>
                  <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                  Files
                </Link> */}
                  {currentUser &&
                    currentUser.staff_status === "administrator" && (
                      <Link
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        to={"/user"}
                      >
                        <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                        Usuario
                      </Link>
                    )}
                  {/* <Link className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" to={"/user"}>
                  <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                  Usuario
                </Link> */}
                  <Link
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    to={"/calendar"}
                  >
                    <FaFolder className="mr-2 h-5 w-5 text-blue-500" />
                    Calendario
                  </Link>
                  <button
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    <FaSignOutAlt className="mr-2 h-5 w-5 text-blue-500" />
                    Cerrar Sesion
                  </button>
                  <button
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    <FaSignOutAlt className="mr-2 h-5 w-5 text-blue-500" />
                    Cerrar Sesion
                  </button>
                </div>
              ) : (
                <>
                  {user === null && (
                    <Link
                      to="/login"
                      className="inline-flex ml-12 items-center rounded-md border border-transparent bg-red-button px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900 hover:text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Inicio de Sesion
                      <DotLoader
                        className="ml-3 -mr-1 h-5 w-5"
                        loading={loading}
                        size={20}
                        color="#fff"
                      />
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Transition>

      {/* Modal de perfil del usuario */}
      {showCurrentUserModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-md shadow-md">
            <div className="flex justify-end">
              <button
                onClick={() => setShowCurrentUserModal(false)}
                className="text-blue-500 hover:text-blue-700"
              >
                <FiX className="h-5 w-5" />
              </button>
            </div>
            <div>
              {currentUser && (
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td
                        className="font-semibold"
                        style={{ paddingRight: "20px" }}
                      >
                        Nombre:
                      </td>
                      <td>{currentUser.first_name}</td>
                    </tr>
                    <tr>
                      <td
                        className="font-semibold"
                        style={{ paddingRight: "20px" }}
                      >
                        Apellido:
                      </td>
                      <td>{currentUser.last_name}</td>
                    </tr>
                    <tr>
                      <td
                        className="font-semibold"
                        style={{ paddingRight: "20px" }}
                      >
                        Correo electronico:
                      </td>
                      <td>{currentUser.email}</td>
                    </tr>
                    <tr>
                      <td
                        className="font-semibold"
                        style={{ paddingRight: "20px" }}
                      >
                        Tipo de Usuario:
                      </td>
                      <td>{currentUser.staff_status}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
