import React, { useState, useEffect, useRef } from "react";
import SearchTable from "./abcupon/SearchTable";
import Carousel1 from "./abcupon/Carousel";
import { Link } from "react-router-dom";
import Faq from "./abcupon/Faq";
import Redireccion from "./abcupon/Redireccion";
import Botonera from "./abcupon/Botonera";
import Vehiculosrepuestos from "../services/1vehiculosrepuestosytalleres";
import BolsaDeEmpleo from "../services/2bolsadeempleo";
import Casasylotes from "../services/3casasylotes";
import Clinicasalud from "../services/4clinicassaludyestetica";
import Comunicaciontecnologia from "../services/5comunicaciontecnologiayenergia";
import Construcciondiseño from "../services/6construcciondisenoysupervicion";
import Cuponesdedescuento from "../services/7cuponesdedescuento";
import Centroseducativos from "../services/8centroseducativos";
import Entretenimientorestaurantes from "../services/9entretenimientorestaurantesyturismo";
import Ferreteriaydeposito from "../services/10ferreteriaydeposito";
import Hogartiendayelectronica from "../services/11hogartiendayelectronica";
import Plataformadeinversiones from "../services/12plataformadeinversiones";
import Legalynotariado from "../services/13legalynotariado";
import Libreriayeditoriales from "../services/14libreriayeditoriales";
import Ofertasysubastas from "../services/15ofertasysubastas";
import Noticiasyavisosclasificados from "../services/16noticiasyavisosclasificados";
import Polizayseguros from "../services/17polizayseguros";
import Prestamosyrescatesobrepropiedades from "../services/18prestamosyrescatesobrepropiedades";
import Productosyservicioscooperativos from "../services/19productosyservicioscooperativos";
import Publicidadypaginasweb from "../services/20publicidadypaginasweb";
import Fundacioneslabones from "../services/21fundacioneslabones";
import Hoteles_turismo from "../services/22hoteles_turismo";
import Transporteymensajeria from "../services/23transporteymensajeria";
import ProductDataService from "../../services/products";
import FileDataService from "../../services/files";
import DistributorDataService from "../../services/products";
import { useDispatch, useSelector } from "react-redux";
import "./Abcupon.css";

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={scrollToTop}
      style={{
        position: "fixed", // Cambiado de absolute a fixed
        bottom: "20px",
        right: "20px",
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        cursor: "pointer",
        boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000, // Añadido z-index alto
      }}
    >
      <span style={{ fontSize: "40px", lineHeight: "0" }}>↑</span>
    </button>
  );
};

const Abcupon = () => {
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const componentRefs = useRef({});
  const token = useSelector((state) => state.authentication.token);
  const [allServices, setAllServices] = useState([]);

  const normalizeString = (str) => {
    return str
      ? str
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : "";
  };

  const countryCodeToFullName = {
    MX: "México",
    GT: "Guatemala",
    BZ: "Belice",
    HN: "Honduras",
    SV: "El Salvador",
    NI: "Nicaragua",
    CR: "Costa Rica",
    PA: "Panamá",
    CO: "Colombia",
    VE: "Venezuela",
    // Agrega más países según sea necesario
  };
  const [selected, setSelected] = useState("CR");
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [subproducts, setSubproducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPageProducts, setCurrentPageProducts] = useState(1);
  const [currentPageSubproducts, setCurrentPageSubproducts] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const allSubproducts = [];
  const [subproductsWithSameCountry, setSubproductsWithSameCountry] = useState(
    []
  );
  const [searchValue, setSearchValue] = useState("");
  const uniqueNames = [];
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newsPosts, setNewsPosts] = useState([]);
  const [newsPosts1, setNewsPosts1] = useState([]);
  const normalizeProductName = (name) => {
    return name.replace(/^\d+\.\s*/, "").trim();
  };

  const fetchProducts = () => {
    ProductDataService.getAll(token)
      .then((response) => {
        const products = response.data; // Productos obtenidos del servidor
        // console.log("productos totales sin tanto pero", products)
        localStorage.setItem("products", JSON.stringify(products));
        setProducts2(products);
        // Ejemplo de búsqueda de subproducto
      })
      .catch((e) => {
        console.error(e);
      });
  };
  useEffect(() => {
    fetchProducts();
    fetchAllSubProducts();
    // fetchNewsPosts();
  }, [token]);

  const fetchNewsPosts = (searchTerm = "") => {
    FileDataService.getAllPost()
      .then((response) => {
        const sortedPosts = response.data.sort((a, b) => {
          const numA = parseInt(a.category.split(".")[0]);
          const numB = parseInt(b.category.split(".")[0]);

          if (numA === numB) {
            return a.category.localeCompare(b.category);
          }

          return numA - numB;
        });
        console.log("clasificados", sortedPosts);

        // Filtrar clasificados por país
        const filteredPosts = sortedPosts.filter((post) => {
          const normalizedName = normalizeString(post.title); // Cambié post.name a post.title
          const normalizedDescription = normalizeString(post.description);
          const normalizedCategory = normalizeString(post.category); // Normaliza la categoría
          return (
            normalizedName.includes(searchTerm) ||
            normalizedDescription.includes(searchTerm) ||
            normalizedCategory.includes(searchTerm)
          ); // Filtrar por categoría
        });

        console.log("Posts filtrados", filteredPosts);
        setNewsPosts(filteredPosts);

        // Obtener el post buscado
        const searchedPosts = filteredPosts.filter(
          (post) =>
            normalizeString(post.title).includes(searchTerm) || // Busca en el título
            normalizeString(post.category).includes(searchTerm) // Busca en la categoría
        );

        if (searchedPosts.length > 0) {
          console.log("Posts buscados:", searchedPosts);
          setNewsPosts1(searchedPosts); // Almacena todos los posts coincidentes en newsPosts1
        } else {
          setNewsPosts1([]); // Si no se encuentra, establecer en vacío
        }

        // Obtener categorías únicas y ordenarlas
        const uniqueCategories = [
          ...new Set(filteredPosts.map((post) => post.category)),
        ];
        const sortedCategories = uniqueCategories.sort((a, b) => {
          const numA = parseInt(a.split(".")[0]);
          const numB = parseInt(b.split(".")[0]);
          return numA - numB;
        });
        console.log("clasificados ordenados", sortedCategories);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearchTableChange = (newValue) => {
    console.log("newValue", newValue);

    // Limpia el valor de búsqueda si está vacío o solo tiene espacios
    if (!newValue || newValue.trim() === "") {
      console.log("Input vacío o inválido, eliminando datos de newsPosts1");
      setNewsPosts1([]); // Limpia los resultados
      setSearchValue(""); // Resetea el valor de búsqueda
      return; // Sal de la función, no se ejecuta más código
    }

    // Verifica si newValue empieza con 'ae_'
    if (newValue.startsWith("ae_")) {
      const cleanValue = newValue.replace("ae_", "").trim(); // Elimina 'ae_' y limpia el valor
      console.log("Buscando posts con:", cleanValue);

      if (cleanValue !== "") {
        fetchNewsPosts(cleanValue); // Solo realiza la búsqueda si cleanValue no está vacío
      } else {
        console.log("Valor limpio está vacío después de quitar 'ae_'");
        setNewsPosts1([]); // Limpia los resultados
      }
    } else {
      console.log("El valor no comienza con 'ae_', no se realiza búsqueda");
      setNewsPosts1([]); // Limpia los resultados si no es un valor válido
    }

    console.log("post buscado", newsPosts1);
    setSearchValue(newValue);
    handleSearchChange({ target: { value: newValue } });
  };

  // Asegúrate de que handleSearchChange maneje correctamente el término de búsqueda

  const handleSearchChange = async (e) => {
    const searchTerm = normalizeString(e.target.value); // Normaliza el término de búsqueda
    console.log("aqui estan todos los productos", products2);

    const selectedCountryFullName = countryCodeToFullName[selected] || selected;

    // Validar que searchTerm y selectedCountryFullName sean válidos
    if (!searchTerm || !selectedCountryFullName) {
      console.warn("Search term o nombre del país no son válidos");
      setSearchResults([]);
      return;
    }

    // Filtrar productos basado en el término de búsqueda
    const filteredProducts = products2.filter((product) => {
      const normalizedProductName = normalizeString(product.name); // Normaliza el nombre del producto
      const normalizedProductDescription = normalizeString(product.description); // Normaliza la descripción del producto

      const productMatch =
        normalizedProductName.includes(searchTerm) || // Busca sin tildes
        normalizedProductDescription.includes(searchTerm) ||
        product.characteristics.some(
          (characteristic) =>
            normalizeString(characteristic.name).includes(searchTerm) // Normaliza el nombre de características
        );

      const subproductMatch = product.subproducts.some((subproduct) => {
        const normalizedSubproductName = normalizeString(subproduct.name); // Normaliza el nombre del subproducto
        return (
          normalizedSubproductName.includes(searchTerm) || // Busca sin tildes
          normalizeString(subproduct.email).includes(searchTerm) ||
          normalizeString(subproduct.phone).includes(searchTerm)
        );
      });

      return productMatch || subproductMatch;
    });

    console.log("datos filtrados", filteredProducts);
    setSearchResults(filteredProducts);
    setCurrentPageSubproducts(1);
  };

  const fetchAllSubProducts = async () => {
    try {
      const allSubProductsResponse =
        await DistributorDataService.getAllSubProduct();
      const allSubProducts = allSubProductsResponse.data;
      console.log("allsubproducts", allSubProducts);

      // Verifica si ya existe "subproducts" en localStorage
      if (localStorage.getItem("subproducts")) {
        localStorage.removeItem("subproducts"); // Elimina el item existente
      }

      // Guarda los nuevos subproductos en localStorage
      localStorage.setItem("subproducts", JSON.stringify(allSubProducts));

      const servicesMap = {};
      const updatedAllServices = [];
      const allServices = []; // Asegúrate de inicializar allServices

      for (const subProduct of allSubProducts) {
        const servicesResponse =
          await DistributorDataService.getAllServicesForSubProduct(
            subProduct.id
          );
        const services = servicesResponse.data;
        servicesMap[subProduct.id] = services;
        allServices.push(...services);

        const modifiedServices = services.map((service) => ({
          ...service,
          subproductId: subProduct.id,
        }));

        updatedAllServices.push(...modifiedServices); // Cambiado a modifiedServices para usar el array correcto
      }

      // Actualiza el estado una vez que se hayan recopilado todos los servicios
      setAllServices(allServices);

      // Verifica si ya existe "services" en localStorage
      if (localStorage.getItem("services")) {
        localStorage.removeItem("services"); // Elimina el item existente
      }

      // Guarda los nuevos servicios en localStorage
      localStorage.setItem("services", JSON.stringify(allServices));
      console.log("allservices", allServices);
    } catch (error) {
      console.error("Error al obtener los subproductos y servicios:", error);
    }
  };

  const savedSubproducts =
    JSON.parse(localStorage.getItem("subproducts")) || [];
  // console.log("datos guardados", savedSubproducts)

  // Verificar si los subproductos en el localStorage son diferentes a los existentes en el estado
  if (
    JSON.stringify(savedSubproducts) !==
    JSON.stringify(subproductsWithSameCountry)
  ) {
    setSubproductsWithSameCountry(savedSubproducts);
  }

  const toggleFaqModal = () => {
    setIsFaqOpen(!isFaqOpen);
  };

  const handleScrollToComponent = (componentName) => {
    if (componentRefs.current[componentName]) {
      componentRefs.current[componentName].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (activeComponent) {
      handleScrollToComponent(activeComponent);
    }
  }, [activeComponent]);

  const indexOfLastSubproduct = currentPageSubproducts * itemsPerPage;
  const indexOfFirstSubproduct = indexOfLastSubproduct - itemsPerPage;
  const currentSubproducts = searchResults
    .map((result) => result.subproducts)
    .flat()
    .slice(indexOfFirstSubproduct, indexOfLastSubproduct);

  const handlePageChangeSubproducts = (newPage) => {
    setCurrentPageSubproducts(newPage);
  };
  console.log("vamos a probar siempre newsPosts1", newsPosts1);
  return (
    <div style={{ marginTop: "7%" }}>
      <Carousel1 />
      <SearchTable onSearchChange={handleSearchTableChange} />
{/* Condicional para mostrar la tabla de clasificados */}
{newsPosts1.length > 0 ? (
  <div style={{ overflowX: "auto" }}>
    <table>
      <thead>
        <tr>
          <th style={{color:"black"}}>Título</th>
          <th style={{color:"black"}}>Descripción</th>
          <th style={{color:"black"}}>Categoría</th>
          <th style={{color:"black"}}>Ubicación</th>
          <th style={{color:"black"}}>Teléfono</th>
        </tr>
      </thead>
      <tbody>
        {newsPosts1
          // Filtramos para eliminar duplicados
          .filter((post, index, self) =>
            index === self.findIndex((p) => p.title === post.title)
          )
          .map((post) => (
            <tr key={post.id}>
             <td >
    <Link to={`/avisos_economicos/${post.id}`} style={{color:"black"}}>{post.title}</Link>
  </td>
              <td> <Link to={`/avisos_economicos/${post.id}`} style={{color:"black"}}>{post.description}</Link></td>
              <td><Link to={`/avisos_economicos/${post.id}`} style={{color:"black"}}>{post.category}</Link></td>
              <td><Link to={`/avisos_economicos/${post.id}`} style={{color:"black"}}>{post.province}</Link></td>
              <td><Link to={`/avisos_economicos/${post.id}`} style={{color:"black"}}>{post.phone_number}</Link></td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
) : (
  <p>No hay busquedas recientes.</p>
)}

{searchValue && (
  <div>
    <div style={{ overflowX: "auto" }}>
      <h2>Resultados de búsqueda:</h2>
      {searchResults.map((product) => (
        <div key={product.id}>
          <h3
            style={{
              color: "black",
              fontWeight: "bold",
              textShadow: "1px 1px red",
            }}
          >
            {product.name}
          </h3>
          {product.subproducts.length > 0 && (
            <div style={{ overflowX: "auto" }}>
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>Nombre del Comercio</th>
                    <th>País</th>
                    <th>Teléfono</th>
                    <th>Email</th>
                    <th>Dirección</th>
                    <th>Página Web</th>
                    <th>Maps</th>
                  </tr>
                </thead>
                <tbody>
                  {product.subproducts
                    // Filtramos para eliminar duplicados por nombre y categoría
                    .filter((subproduct, index, self) =>
                      index === self.findIndex((s) => 
                        s.name === subproduct.name && s.category === subproduct.category
                      )
                    )
                    .map((subproduct) => (
                      <tr key={subproduct.id}>
                        <td
                          className="fixed-column"
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textShadow: "1px 1px red",
                          }}
                        >
                          <Link
                            to={`/servicios/${subproduct.email}`}
                            state={{
                              subproductName: subproduct.name,
                              subproductEmail: subproduct.email,
                            }}
                            style={{ color: "black" }}
                          >
                            {subproduct.name}
                          </Link>
                        </td>
                        <td className="fixed-column" data-label="País">
                          {subproduct.country}
                        </td>
                        <td className="fixed-column" data-label="Teléfono">
                          {subproduct.phone}
                        </td>
                        <td className="fixed-column1" data-label="Email">
                          {subproduct.email}
                        </td>
                        <td data-label="Dirección">{subproduct.address}</td>
                        <td data-label="Página Web">{subproduct.url}</td>
                        <td className="map-column" data-label="Maps">
                          {subproduct.addressmap && (
                            <iframe
                              title="Google Maps"
                              src={subproduct.addressmap}
                              width="100%"
                              height="300px"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              aria-hidden="false"
                              tabIndex="0"
                            ></iframe>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>

    <div className="pagination">
      {currentPageSubproducts > 1 && (
        <button
          onClick={() =>
            handlePageChangeSubproducts(currentPageSubproducts - 1)
          }
        >
          Página anterior
        </button>
      )}
      {indexOfLastSubproduct <
        searchResults.map((result) => result.subproducts).flat().length && (
        <button
          onClick={() =>
            handlePageChangeSubproducts(currentPageSubproducts + 1)
          }
        >
          Página siguiente
        </button>
      )}
    </div>
  </div>
)}
{/* Separador personalizado */}
<div
        onClick={toggleFaqModal}
        style={{
          borderTop: "2px solid red",
          borderBottom: "2px solid red",
          padding: "10px",
          textAlign: "center",
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        p5-Ayuda: preguntas frecuentes
      </div>
{/* Modal de FAQ */}
{isFaqOpen && (
        <div className="faq-modal">
          <div className="faq-content">
            <button
              onClick={toggleFaqModal}
              style={{ float: "right", cursor: "pointer", fontSize: "2.5em" }}
            >
              X
            </button>
            <Faq />
          </div>
        </div>
      )}
        <Redireccion />
        <div
        style={{
          marginTop: "2%",
          borderTop: "2px solid red",
          borderBottom: "2px solid red",
          padding: "10px",
          textAlign: "center",
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        ENCUENTRELO DESDE LA BOTONERA
      </div>
      <div style={{ marginBottom: "4%" }}>
        <Botonera setActiveComponent={setActiveComponent} />
      </div>
      <div>
        <div
          style={{ backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            1)- Autos y Accesorios 
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Vehiculosrepuestos"] = el)}>
          <Vehiculosrepuestos />
        </div>
        <div
          style={{marginTop:"4%",backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            2)- Bolsa de empleo 
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["BolsaDeEmpleo"] = el)}>
          <BolsaDeEmpleo />
        </div>
        <div
          style={{ marginTop:"4%",backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            3)- Casas, Lotes y Boncre 
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Casasylotes"] = el)}>
          <Casasylotes />
        </div>
        <div
          style={{ marginTop:"4%",backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            4)- Clinica, Salud y Estética 
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Clinicasalud"] = el)}>
          <Clinicasalud />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            5)- Comunicación, Tecnología y Energía 
          </h2>
        </div>
        <div
          ref={(el) => (componentRefs.current["Comunicaciontecnologia"] = el)}
        >
          <Comunicaciontecnologia />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            6)- Construcción, Diseño y Supervisión
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Construcciondiseño"] = el)}>
          <Construcciondiseño />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            7)- Cupones de Descuento de Inversión e Intercambio
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Cuponesdedescuento"] = el)}>
          <Cuponesdedescuento />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            8)- Centros de Educación y Universidades
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Centroseducativos"] = el)}>
          <Centroseducativos />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            9)- Entretenimiento, Diversión y Restaurantes
          </h2>
        </div>
        <div
          ref={(el) =>
            (componentRefs.current["Entretenimientorestaurantes"] = el)
          }
        >
          <Entretenimientorestaurantes />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            10)- Ferretería y Depósito
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Ferreteriaydeposito"] = el)}>
          <Ferreteriaydeposito />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            11)- Hogar, Tienda, Electrónica y Supermercados
          </h2>
        </div>
        <div
          ref={(el) => (componentRefs.current["Hogartiendayelectronica"] = el)}
        >
          <Hogartiendayelectronica />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            12)- Planes de Inversión Portafolio Inmobiliario
          </h2>
        </div>
        <div
          ref={(el) => (componentRefs.current["Plataformadeinversiones"] = el)}
        >
          <Plataformadeinversiones />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            13)- Legal y Notariado
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Legalynotariado"] = el)}>
          <Legalynotariado />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
           14)- Librería
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Libreriayeditoriales"] = el)}>
          <Libreriayeditoriales />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            15)- Catálogo, Ofertas y Subastas
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Ofertasysubastas"] = el)}>
          <Ofertasysubastas />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            16)- Noticias
          </h2>
        </div>
        <div
          ref={(el) =>
            (componentRefs.current["Noticiasyavisosclasificados"] = el)
          }
        >
          <Noticiasyavisosclasificados />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            17)- Póliza y seguros AB
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Polizayseguros"] = el)}>
          <Polizayseguros />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            18)- Préstamos privados sobre propiedades
          </h2>
        </div>
        <div
          ref={(el) =>
            (componentRefs.current["Prestamosyrescatesobrepropiedades"] = el)
          }
        >
          <Prestamosyrescatesobrepropiedades />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
            19)- Productos y Servicios Cooperativos
          </h2>
        </div>
        <div
          ref={(el) =>
            (componentRefs.current["Productosyservicioscooperativos"] = el)
          }
        >
          <Productosyservicioscooperativos />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
           20)- Combos de Publicidad y Páginas web
          </h2>
        </div>
        <div
          ref={(el) => (componentRefs.current["Publicidadypaginasweb"] = el)}
        >
          <Publicidadypaginasweb />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
           21)- Fundación Eslabonescr.com
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Fundacioneslabones"] = el)}>
          <Fundacioneslabones />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
           22)- Esencial Costa Rica Hoteles y Turismo
          </h2>
        </div>
        <div ref={(el) => (componentRefs.current["Hoteles_turismo"] = el)}>
          <Hoteles_turismo />
        </div>
        <div
          style={{ marginTop:"4%", backgroundColor: "red",color: "white",padding: "10px",textAlign: "center", maxWidth: "100%",marginBottom:"-5%", border: "1px solid black",}}>
          <h2
            style={{backgroundColor: "white",color: "black", padding: "5px 10px", textotextAlign: "center",display: "inline-block", textomaxWidth: "100%", border: "2px solid black",borderRadius: "8px"}}
          >
           23)- Transporte y Mensajería
          </h2>
          </div>
        <div
          ref={(el) => (componentRefs.current["Transporteymensajeria"] = el)}
        >
          <Transporteymensajeria />
        </div>
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default Abcupon;
