import React, { useState, useEffect } from 'react';
import './SearchTable.css'; // Para agregar estilos personalizados
import DistributorDataService from "../../../services/products";
import { useNavigate } from 'react-router-dom';

const SearchTable = ({ onSearchChange }) => {
  // Estados para cada input
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [directory, setDirectory] = useState('');
  const [ads, setAds] = useState('');
  const [catalog, setCatalog] = useState('');
  const [contact, setContact] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate(); 

  // Manejar cambios de los inputs
  const handleInputChange = (setter, fieldName, value) => {
    setter(value);
    // console.log(`${fieldName}: ${value}`);
    
    // Enviar el valor a onSearchChange con el prefijo solo si es 'avisos'
    if (fieldName === 'avisos') {
      onSearchChange(`ae_${value}`); // Agregar prefijo solo para avisos
    } else {
      onSearchChange(value); // Dejar el valor igual para otros campos
    }
  };

  const fetchCategories = () => {
    DistributorDataService.getAllSubProduct()
      .then((response) => {
        const uniqueCategories = [...new Set(response.data.map(distributor => distributor.comercial_activity))];
        // console.log("categorias unicas",uniqueCategories)
        setCategories(uniqueCategories);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // Efecto para cargar categorías al montar el componente
  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="table-container">
      <table className="search-table">
        <thead>
          <tr>
            {/* Primera fila con las 3 columnas unidas */}
            <th colSpan="3" className="search-header">
              <i className="fas fa-search search-icon"></i>
              <span className="header-text">
                Escriba el producto o servicio que solicita aqui: 
              </span>
              
              <i className="fas fa-search search-mirror"></i>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            {/* Primera fila con las 3 columnas unidas */}
            <th colSpan="3" className="search-header">
              <i className="fas fa-search search-icon"></i>
              <span className="header-text">
                 <strong>00-Buscador</strong>
              </span>
              
              <i className="fas fa-search search-mirror"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Segunda fila con títulos de columnas */}
          <tr>
            <td>0.1-Buscar por nombre</td>
            <td>0.2-Buscar por categoría</td>
            {/* <td>3-Directorio Páginas Amarillas</td> */}
          </tr>

          {/* Tercera fila con inputs para los filtros */}
          <tr>
            <td>
              <input
                type="text"
                value={name}
                onChange={(e) => handleInputChange(setName, 'nombre', e.target.value)}
                placeholder="Buscar nombre"
              />
            </td>
            <td>
            <select
                value={category}
                onChange={(e) => handleInputChange(setCategory, 'categoría', e.target.value)}
              >
                <option value="">Selecciona una categoría</option>
                {categories.map((cat, index) => (
                  <option key={index} value={cat}>{cat}</option>
                ))}
              </select>
            </td>
            {/* <td>
              <input
                type="text"
                value={directory}
                onChange={(e) => handleInputChange(setDirectory, 'directorio', e.target.value)}
                placeholder="Buscar directorio"
              />
            </td> */}
          </tr>

          {/* Cuarta fila con títulos adicionales */}
          <tr>
            <td>0.3-Buscar en Avisos Económicos</td>
            {/* <td>5-Catálogo Físico para Vendedores</td> */}
            <td>0.4-Contáctenos ABc</td>
          </tr>

          {/* Quinta fila con inputs adicionales */}
          <tr>
            <td>
              <input
                type="text"
                value={ads}
                onChange={(e) => handleInputChange(setAds, 'avisos', e.target.value)}
                placeholder="Buscar avisos"
              />
            </td>
            <td>
              {/* Botón para redirigir a la página de contacto */}
              <button 
  onClick={() => navigate('/contacto')} 
  style={{
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s'
  }} 
  onMouseEnter={(e) => {
    e.target.style.backgroundColor = 'green';
    e.target.style.color = 'white';
  }} 
  onMouseLeave={(e) => {
    e.target.style.backgroundColor = 'transparent';
    e.target.style.color = 'black';
  }}
>
Servicio al Cliente 
</button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SearchTable;
