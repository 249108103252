import React from "react";
import { useMediaQuery } from 'react-responsive';
import Accordion from "./faq/Accordion";
import illustration__box from "./images/illustration-box-desktop.svg";
import illustration__woman_desktop from "./images/illustration-woman-online-desktop.svg";
import illustration__woman_mobile from "./images/illustration-woman-online-mobile.svg";
import "./faq/faq.css";

const questionsAnswers = [
  {
    question: "¿Cuáles son las respuestas disponibles por teléfono, Messenger, WhatsApp, grupos y fan page?",
    answer: "Red de Hipotecas, Credired, Arrendamientos inmobiliarios, Red de Arrendadores, Vinsa, Coopered, Coope coin, (CeC) COOP e COIN Credi red, ventanales e instalaciones Nacionales, Movimiento eslabones cr.com",
  },
  {
    question: "¿Cómo debo saludar al cliente?",
    answer: "Buenos días, tardes, noches. Red de Hipotecas, tal le atiendo. Con quien tengo el gusto (Anotar de inmediato el nombre y continuar expresando el nombre al interlocutor).",
  },
  {
    "question": "¿Cómo debo proceder con el cliente?",
    "answer": "¿Coménteme su caso y le comunico con el profesional que le atiende mejor?"
  },
  {
    "question": "¿Qué necesito para hacer un préstamo hipotecario si pongo mi propiedad a responder por el dinero que me van a dar?",
    "answer": "Ocupa enviar: Fotos de la propiedad y vídeo desde afuera, inicie filmando su propiedad y gire sin caminar hasta terminar en su propiedad mostrando el entorno. Mande foto del plano. Dirección exacta de la propiedad. Monto y plan de inversión, del uso del dinero, si es para refundir deudas, una lista con los montos. Nombre del propietario registral y número de teléfono. Fecha para cuando ocupa el dinero. Una vez preaprobado, llena aplicación y se tramita estudios de abogados y fecha para la formalización. Los abogados podrían solicitar otros documentos de ser necesario."
  },
  {
    "question": "¿Cuánto es de intereses mensual y la cuota?",
    "answer": "Tengo diferentes inversionistas que prestan a intereses según zona y riesgo."
  },
  {
    "question": "¿Cuáles son los intereses para diferentes tipos de propiedades?",
    "answer": [
      "1% para edificios y estaciones de combustible.",
      "1.5% para casas y locales en GAM.",
      "2% para casas y locales en GAM.",
      "2.25%, 2.5%, 2.75% y 3% para garantías en otros sectores; yo le consigo la mejor opción de acuerdo con la zona.",
      "Cada 1% corresponde a una cuota mensual de 10 mil por cada millón de deuda. Por ejemplo, por 1 millón en $ en un edificio, paga una cuota mensual por concepto de intereses de ₡15 mil."
    ]
  },
  {
    "question": "¿Cuánto es el plazo de crédito hipotecario privado?",
    "answer": "El plazo es mínimo de 1 año y se prorroga hasta por dos períodos igual al primero. Requisito que el cliente se mantenga al día en los pagos. En caso de amortizables, son a 84 cuotas, 7 años."
  },
  {
    "question": "¿Me prestan en segundo grado?",
    "answer": "Se pasa a estudio, debe enviar estado de cuenta del primer grado para verificar saldo."
  },
  {
    "question": "¿Puedo realizar abonos mínimos?",
    "answer": "En casos pre acordados en la escritura, sí puede realizar abonos mínimos de ₡500 mil o de $1 mil. Recuerde escribirme al 8787-6767, también puede llamarme al 2224-8686 o hacer click en este enlace y su mensaje me llega directo a mi WhatsApp."
  },
  {
    "question": "¿Existe penalidad por cancelar antes?",
    "answer": "Cancela cuando lo desee; después de los 3 primeros meses no hay penalidad. Recuerde escribirme al 8787-6767, también puede llamarme al 2224-8686 o hacer click en este enlace y su mensaje me llega directo a mi WhatsApp."
  },
  {
    "question": "¿Prestan por propiedades en verde?",
    "answer": [
      "Se hacen excepciones dependiendo de cada caso y del uso del dinero.",
      "En caso de construcciones menores sobre la misma propiedad, se realizan desembolsos según avance de obra.",
      "Es muy importante que tramite la disponibilidad de los servicios públicos: agua y electricidad.",
      "Se sugiere tramitar mínimo la carta de disponibilidad de estos servicios.",
      "Recuerde escribirme al 8787-6767, también puede llamarme al 2224-8686 o hacer click en este enlace y su mensaje me llega directo a mi WhatsApp."
    ]
  },
  {
    "question": "¿Me prestan si estoy manchado en SUGEF?",
    "answer": "Sí, le prestan aun cuando tenga manchas. Recuerde escribirme al 8787-6767, también puede llamarme al 2224-8686 o hacer click en este enlace y su mensaje me llega directo a mi WhatsApp."
  },
  {
    "question": "¿Me prestan para realizar un sucesorio?",
    "answer": "Se estudia el caso y si no muestra contención entre los beneficiados, se le puede ayudar. Recuerde escribirme al 8787-6767, también puede llamarme al 2224-8686 o hacer click en este enlace y su mensaje me llega directo a mi WhatsApp."
  },
  {
    "question": "¿Me prestan si la propiedad no es mía?",
    "answer": "Sí, le prestan, solo debe firmar la solicitud de aplicación y la formalización el dueño registral; no se aceptan poderes."
  },
  {
    "question": "¿Me prestan por derechos?",
    "answer": "Presente su caso y se estudia de forma independiente."
  },
  {
    "question": "Si tengo fecha de remate del banco, ¿me ayudan a rescatarla?",
    "answer": [
      "Sí, le buscamos solución mediante un plan de rescate que minimice los riesgos del inversionista y le ayude a usted a ganar tiempo, con las siguientes proyecciones:",
      "Que usted pueda refinanciar por otro lado la deuda.",
      "Que pueda vender la propiedad a otra persona."
    ]
  },
  {
    "question": "¿Hay probabilidad de tramitar crédito tipo empresaria al 1% en colones?",
    "answer": [
      "No, con hipoteca privada (ese trámite se logra cumpliendo los requisitos bancarios).",
      "Si usted los cumple, pero está cansado de chocar en los bancos, tenemos alguien que le puede ayudar.",
      "¿Es rígida la tabla de intereses? No es rígida, se le podría mejorar las condiciones de la tabla relativo a la garantía."
    ]
  },
  {
    "question": "¿Hasta dónde se puede flexibilizar el interés?",
    "answer": "En el mismo grado en que la garantía y fuente de ingresos del deudor minimicen el riesgo."
  },
  {
    "question": "¿Quisiera crédito hipotecario para refundir deudas?",
    "answer": "Con gusto le ofrezco sobre propiedades hasta un máximo del 40% + gastos, que es como el 50% del valor estimado de venta de la propiedad a 90 días."
  },
  {
    "question": "¿Quiero información de crédito hipotecario?",
    "answer": "Con gusto, le ofrezco sobre propiedades..."
  },
  {
    "question": "¿Cómo logro o cómo se obtiene un préstamo sobre mi propiedad?",
    "answer": "Para Precalificar, envíeme: 1-Monto que requiere, 2-Ubicación de la propiedad. Si está en zona y monto posibles, envíeme: 3-En qué va a invertir este préstamo, 4-Su nombre completo. PENDIENTE: Requisitos de Calificación y en caso de solicitarlo, el Dpto. Legal algún requisito aclaratorio."
  },
  {
    "question": "¿Prestan gota a gota?",
    "answer": "NO PRÉSTAMOS PERSONALES NI GOTA GOTA."
  },
  {
    "question": "¿Forma de contacto?",
    "answer": "Su contacto directo a mi WhatsApp haciendo click en el siguiente enlace: 'wa.link/qvhox1'. Soy Rodney Zuñiga, encargado de ayudarle en su trámite. ☎️Whatsapp: 8384-0545. ☎️Oficina: 2224-8686. 📍Zapote, iglesia católica 75 este. BIENVENIDOS Gerentes, agentes contadores y abogados. Si desea invertir sus ahorros, le capacitamos como inversionista."
  },
  {
    "question": "¿Qué tipo de interés manejan?",
    "answer": "Tengo diferentes inversionistas que prestan sobre edificios, centros comerciales, locales, bodegas, estaciones de servicio, empresas, apartamentos residencias y propiedades tipo económicas. Los intereses, plazos y condiciones son de acuerdo a cada garantía."
  },
  {
    "question": "¿Cuántos años, plazo mínimo y máximo?",
    "answer": "Mínimo 1 año, máximo 7 años… depende del inversionista que le consiga."
  },
  {
    "question": "¿Qué debo hacer para tener éxito en el trámite de un crédito privado?",
    "answer": "De primero debemos asesorarnos si nuestro perfil encaja para obtener un crédito privado y el perfil de la propiedad que ofrecemos en garantía, con relación a la ubicación, descripción de la infraestructura en el área, disponibilidad de servicios, el uso y el valor de la propiedad."
  },
  {
    "question": "¿Cuáles son los requisitos para el crédito hipotecario privado?",
    "answer": [
      "Estar al día en sus obligaciones financieras.",
      "La propiedad en garantía debe ser del cliente o avalada por el propietario.",
      "En caso de ser aval, es necesario su firma en la solicitud.",
      "Ser propietarios de la propiedad que quieren hipotecar."
    ]
  },
  {
    "question": "¿Con qué documentos inicio el trámite?",
    "answer": [
      "Comprobante de ingreso.",
      "Identificación (cédula o pasaporte).",
      "Escritura de la propiedad que se va a hipotecar."
    ]
  },
  {
    "question": "¿Qué gastos debo tomar en cuenta para solicitar un crédito privado?",
    "answer": [
      "Porcentaje sobre el monto del préstamo que se acordó.",
      "Los gastos de la formalización.",
      "Tasa del abogado (de 1-2% del monto del préstamo)."
    ]
  },
  {
    "question": "¿Cuánto tiempo tarda la respuesta a la solicitud?",
    "answer": "La respuesta puede tardar de 1 a 3 días hábiles."
  },
  {
    "question": "¿Qué se necesita para formalizar el crédito?",
    "answer": "La firma del cliente, el abogado y los inversores que estén involucrados en el préstamo."
  },
  {
    "question": "¿Qué pasa si no puedo pagar el crédito?",
    "answer": "El contrato especifica las consecuencias por incumplimiento. La propiedad se puede poner a la venta para cubrir la deuda."
  },
  {
    "question": "¿Qué tipo de propiedades aceptan como garantía?",
    "answer": "Aceptamos propiedades urbanas y rurales, terrenos, edificios, locales comerciales y viviendas."
  },
  {
    "question": "¿Es posible obtener un crédito si no tengo ingresos formales?",
    "answer": "Sí, se pueden considerar otros factores como ingresos informales, ahorros, o el valor de la propiedad como garantía."
  },
  {
    "question": "¿Los intereses son fijos o variables?",
    "answer": "Los intereses pueden ser negociables, dependiendo del acuerdo con el inversionista."
  },
  {
    "question": "¿Ofrecen algún tipo de asesoría gratuita?",
    "answer": "Sí, ofrecemos asesoría gratuita para ayudarle a entender el proceso y sus opciones."
  },
  {
    "question": "PREGUNTAS FRECUENTES EMPRESAS AFILIADAS A RED DE HIPOTECAS: PRESTAMOS PERSONALES.",
    "answer": ""
  },
  {
    "question": "¿TIENEN PRESTAMOS PERSONALES?",
    "answer": "No le ofrecemos inversionistas con préstamos personales sin garantía real, LE OFRECEMOS POSIBILIDADES con el banco a partir de 5 millones de colones para asalariados."
  },
  {
    "question": "OPORTUNIDADES DE NEGOCIOS DE INVERSION INMOBILIARIA PARA SOCIOS COMERCIALES.",
    "answer": "CHARLA2 *PROGRAMA DE FORMACIÓN PARA QUE INVIERTA SUS AHORROS DE FORMA SEGURA Y GARANTIZADO *SOBRE HIPOTECAS EN 1ER GRADO LUEGO LE PRESENTO OPORTUNIDADES DE INVERSION EXPOSITOR Rodney & Zúñiga. Gestor de Inversiones."
  },
  {
    "question": "ASEGUREMOS LA COMPRENSIÓN CON UN CHECK LIST.",
    "answer": ""
  },
  {
    "question": "¿ENCONTRE ESTA OPORTUNIDAD DE NEGOCIO Y QUIERO COMPARTIRLA CON USTED?",
    "answer": "Su dinero trabajando por usted, sin horarios, empleados, locales, servicios públicos ni patentes."
  },
  {
    "question": "¿MONTO DE INVERSION?",
    "answer": "Desde hipotecas ₡5 millones o $10 mil USD. Fondeo $100K, desarrollos inmobiliarios $500K y +."
  },
  {
    "question": "¿GARANTÍAS?",
    "answer": "Al suscribirse le presentamos oportunidades para invertir y usted accede al servicio de: Propiedades filtradas, (Descarte de escritorio, Revisión: Registral, TSE, Municipal y Topográfico), Filtro de deudores, huella digital y acreditaciones, Presentación de garantías, fotos, video y Waze, Confección de expedientes, apertura de auxiliar CxC, Inspección de avalúo, Comprobación en sitio del bien, Visto bueno y solicitud de servicio notarial, Formalización Notarial, Administración de desembolso de acuerdo con convenio suscrito con el deudor, los pagos se le retienen y los hace la administración directamente para evitar que el cliente le de otro rumbo al pago proyectado para la operación, garantizando la capacidad de pago del deudor, Otros servicios: Pólizas de seguro, cobranza y reportes mensuales de crecimiento patrimonial, Compra Venta y administración de propiedades, Servicio legal inmobiliario."
  },
  {
    "question": "¿ESTUDIOS DE RIESGO?",
    "answer": "Únicamente tramitamos alquiler de dinero en contratos protocolizados de hipotecas sobre garantías inmobiliarias en primer grado inscritas en el registro público de la propiedad sección inmuebles, a su nombre."
  },
  {
    "question": "¿OPCIONES DE INVERSIÓN?",
    "answer": "PLAN ABIERTO: Se presta sobre oportunidades de negocio con clientes económicamente sensibles que requieren una mano para extender el plazo. PLAN CERRADO (Blindado): Solo se presta a perfiles que demuestren alta capacidad de pago. PLAN MIXTO: La combinación de las dos anteriores."
  },
  {
    "question": "¿RENDIMIENTOS PARA INVERSIONISTA POR AÑO?",
    "answer": "Basados en la ley 9859 contra la usura crediticia. EN RANGOS METRICA BAJA DE 1 A 20 MILLONES: Abierto dólares 15%+F. Abierto colones 21%. Blindado dólares 12%+F. Blindado colones 18%. EN RANGOS METRICA MEDIA DE 21 A 50 MILLONES: Abiertos dólares 16%. Abierto colones 20%+. Blindado dólares 12%+F. Blindado colones 17%+F. EN RANGOS METRICA ALTA DE 51 MILLONES EN ADELANTE: Abiertos dólares 9%. Abiertos colones 18%. Blindado dólares 8%+F. Blindado Colones 15%+F."
  },
  {
    "question": "POLÍTICAS INTERNAS.",
    "answer": "PLAZOS: Para ubicarnos correctamente, este caso lo vamos a revisar con el siguiente ejemplo. ¿Si usted tiene 100 casas de alquiler “¿BIEN ALQUILADAS”, cuando desearía que se vayan esos clientes? Nunca, ¡claro que nunca! son inversiones pensadas en que trabajen por usted, el alquiler de dinero es parecido, pero con menor desgaste administrativo y de mantenimiento."
  },
  {
    "question": "¿PLAZO SUGERIDO?",
    "answer": "Plan pagando solo intereses, a un año renovable de forma automática hasta por dos periodos igual al primero. Plan amortizable a 7 años, (Beneficio de este plan mitiga la migración de garantías)."
  },
  {
    "question": "¿MANEJO DE CUENTA SENSIBLES?",
    "answer": "Gestionamos el cobro corral, evitamos rematar, en caso extremo aplicamos recuperación *Mediante la dación en pago. Se ofrece vuelto sumando, Monto del proceso legal + Monto de un periodo de intereses + Monto de paz mental a cambio de recibir las llaves en mano."
  },
  {
    "question": "¿EXTRA-NEGOCIACIONES?",
    "answer": "Se puede ofrecer: A)- Discreción absoluta en la dación, B)- Alquiler al cedente y C)-Posibilidad de recompra en un precio especial que incluye: suma de principal + gastos del proceso e inscripción + comisión de éxito para el inversionista."
  },
  {
    "question": "¿SITIOS SENSIBLES?",
    "answer": "En algunos sitios identificados como de alta vulnerabilidad definitivamente no trabajamos."
  },
  {
    "question": "¿NUESTRO NEGOCIO?",
    "answer": "A-Alquiler de dinero sobre propiedades, no propiciamos cobros legales, B-Fondeo para capital de trabajo, C-Inversiones en desarrollo de proyectos inmobiliarios."
  },
  {
    "question": "¿DISTRIBUCIÓN DE RENDIMIENTOS?",
    "answer": "Todo rendimiento generado por resultado de nuestro ejercicio comercial será retribuido EN ASOCIO COMERCIAL por una tercera parte de monto neto generado del inversionista al administrador."
  },
  {
    "question": "¿RELACIÓN CONTRACTUAL?",
    "answer": "Suscribe acuerdo Sugef e inicia en la colocación de negocios, cubriendo el 100% de la discreción y confidencialidad con el suscriptor y el beneficiario nombrado."
  },
  {
    "question": "¿CONVERSION?",
    "answer": "(Punto = Millón), (+F=fluctuación $). Pida su inscripción de CHARLA por llamada o mensaje aquí."
  },
  {
    "question": "¿SI TENGO DINERO PUEDO PONERLO A TRABAJAR CON USTEDES?",
    "answer": "Efectivamente aquí le capacitamos y luego le presentamos Garantías, las hipotecas siempre quedan a su nombre. “Porque no somos captadores dinero.”"
  },
  {
    "question": "¿USTEDES SON INSTACREDIT, BETO COLOMBIANOS O ALGUNO DE ELLOS?",
    "answer": "No somos ni pertenecemos a esos grupos. Somos una empresa gestionadora independiente que reúne inversionistas con la finalidad de atender la necesidad de créditos urgentes, propiciamos en todo inversionista nuevo prestar con amortización en la cuota mensual, para ayudar al cliente a salir más fácil de las deudas."
  },
  {
    "question": "¿DE LAS OPCIONES DE MERCADO CUAL ME RECOMIENDAN?",
    "answer": "Siempre recuerde que un interés muy bajo representa un riesgo alto para el deudor."
  },
  {
    "question": "¿QUE SERVICIOS OFRECEN?",
    "answer": "Tengo inversionistas con dinero que prestan con garantía sobre propiedades, yo le acompaño a pre-calificarlo y presentarlo con ellos, para formalizar y aprobar el desembolso que usted requiere."
  },
  {
    "question": "¿CUAL ES LA CUOTA MENSUAL DE CRÉDITO HIPOTECARIO?",
    "answer": "En este caso le comento que tengo varios inversionistas de cuotas bajas, está sujeto a estudio de la garantía que usted ofrece."
  },
  {
    "question": "Ejemplos de intereses:",
    "answer": "18.1 Cuota mensual en dólares: $1.16% -₡11.600 por cada millón. Anual 14%. 18.2 Cuota mensual en dólares: $1.5% -₡15mil por cada millón. Anual 18%. 18.3 Cuota mensual en colones: ₡ 2% -₡20mil por cada millón. Anual 24%. 18.4 Cuota amortizando a 7 años, mensual en colones ₡32.733 por cada millón. Anual 36%. Edificios y Estaciones de servicio, la cuota se revisa hacia la baja. Bienestar social y sitios marginales, tengo otros inversionistas de cuotas más altas."
  },
  {
    "question": "¿TIENE UN CLIENTE CON UN PAGO AL DIA?",
    "answer": "Generalmente hay un monto de anticipos que reciben el 90% hasta del capital y reanudar los montos hasta dos meses con 2 mil a 5 mil dólares según la documentación completa que traiga."
  },
  {
    "question": "¿DURANTE CUANTO TIEMPO SE MANEJARA ESTA RELACIÓN?",
    "answer": "Siempre que ambos estén conformes con el negocio, yo lo acompaño a que en el futuro sus hipotecas las reúnan bajo un fondo que administre su capital con el 20% de gastos al año por ser su contraparte en el proceso."
  },
  {
    "question": "¿EN QUÉ ZONAS GEOGRÁFICAS PUEDEN TRABAJAR?",
    "answer": "En Costa Rica, trabajamos sobre garantías, yo le ayudo a encontrar propiedad, le comparto varias ofertas que captamos por portafolios con empresas afiliadas en el tema de financiamiento."
  },
  {
    "question": "¿SE PUEDEN HACER CAMBIOS EN LAS GARANTÍAS?",
    "answer": "Siempre que usted presente el valor de avalúo y firma donde expresa su autorización para trabajar con el inmueble que representa."
  },
  {
    "question": "¿EN EL VALOR DE AVALÚO QUE HACEN USTEDES?",
    "answer": "Proporcionamos el costo de la revisión y llevamos al administrador que lo presentará, entre los $120 y $150 + el servicio notarial si va por hipotecas. Se debe presentar la autorización para hacer la evaluación."
  },
  {
    "question": "¿CUANTO TIEMPO SE TARDARÁN EN MONTAR EL EXPEDIENTE?",
    "answer": "Puede ser entre 4 a 7 días, según la agenda de servicio que se tenga en ese momento, podemos comenzar a trabajar en 48 horas si hay urgencia."
  },
  {
    "question": "¿CUANDO SE TIENE UNA GARANTÍA YA, HAY UN COSTO DE MANTENIMIENTO?",
    "answer": "Sí, es mínimo y depende de la forma en que trabajemos el expediente. Por ejemplo, si ya se trabaja un acuerdo y es un contrato de arrendamiento, le ayudamos a hacer seguimiento durante el periodo del contrato a fin de proteger su inversión."
  },
  {
    "question": "¿HAY UNA CUOTA POR HACER LA GESTIÓN?",
    "answer": "Se requiere firmar una carta de intención. Esta permite al asesor acceder a su información y tener más datos a fin de tener la mejor garantía para presentarle al inversionista. Costo aproximado $50."
  },
  {
    "question": "¿LOS ASESORAMIENTOS EN CRÉDITOS SON GRATIS?",
    "answer": "Todos los servicios son costos por única vez, para el formalismo de la firma del contrato, la revisión y planificación de las cuotas."
  },
  {
    "question": "¿QUÉ OTRAS COSAS LES INTERESAN?",
    "answer": "Quiero saber si las propiedades tienen pago al día, para eso se puede revisar con el sistema Banca en línea, siempre que tenga acceso a una propiedad o cuenta."
  },
  {
    "question": "¿TIENEN UNA OPORTUNIDAD PARA HACER DINERO?",
    "answer": "Siempre que el cliente pase la revisión, buscaremos la alternativa con más seguridad que cumpla con sus expectativas."
  },
  {
    "question": "¿CUAL ES EL MONTOS MÁXIMO QUE PUEDO PEDIR?",
    "answer": "El monto máximo depende del estudio de la garantía que presente y si tiene comprobación de ingresos que respalde el financiamiento."
  },
  {
    "question": "¿QUIEN REVISARA MIS GARANTÍAS?",
    "answer": "El asesor que lo acompaña en el proceso hasta finalizar con éxito su financiamiento."
  },
  {
    "question": "¿QUÉ SUCEDE SI NO PAGO?",
    "answer": "Se corre el riesgo de perder la propiedad, si no se presenta en un plazo razonable la solución por medio de pagos se procederá a un embargo de acuerdo a la ley vigente."
  },
  {
    "question": "¿CUAL ES SU CAPACIDAD DE PAGO?",
    "answer": "Este proceso lo revisamos y apoyamos en la precalificación para no generar expectativas sin garantías."
  },
  {
    "question": "¿QUÉ TIPO DE PROPIEDADES PUEDEN FINANCIAR?",
    "answer": "Cualquier propiedad que sea susceptible de tener hipoteca, ya sea comercial, residencial, o agrícola."
  },
  {
    "question": "¿CUALES SON LOS TIEMPOS DE RESPUESTA?",
    "answer": "Una vez que se presenta el expediente puede tardar hasta 72 horas en respuesta si está completo."
  },
  {
    "question": "¿TIENEN GARANTÍAS SOBRE PROPIEDADES QUE SE ENCUENTRAN EMBARGADAS?",
    "answer": "Podemos trabajar con propiedades que están en procesos de embargo siempre que se presente la documentación necesaria que respalde la oferta y el pago en el expediente."
  },
  {
    "question": "¿DE QUE MANERA DEBO LLEVAR MIS GARANTÍAS?",
    "answer": "Primero presentando su información y luego la garantía en propiedad o bienes que respalden su petición."
  },
  {
    "question": "¿QUIEREN QUE ESTEMOS EN CONTACTO?",
    "answer": "Siempre que lo desee puede acercarse y hacer su consulta para darle seguimiento, siempre apoyamos en el proceso de pre-calificación para que pueda elegir la opción que más le convenga."
  },
  {
    "question": "¿CUALES SON LOS TIPOS DE CRÉDITO QUE PUEDO OBTENER?",
    "answer": "Puede optar por créditos a plazos cortos o largos, dependiendo de sus necesidades y capacidades de pago."
  },
  {
    "question": "¿QUÉ HACER EN CASO DE TENER DUDAS O INCERTIDUMBRE?",
    "answer": "Siempre puede consultar con su asesor de crédito, estamos aquí para ayudarle."
  }
];

const Faq = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

  return (
    <div className="container">
      <div className="component">
        <div className="illustration">
          {!isMobile ? (
            <>
              <img
                src={illustration__box}
                alt="illustration with box"
                className="illustration__box"
              />
              <img
                className="illustration__woman-desktop"
                src={illustration__woman_desktop}
                alt="illustration with woman"
              />
            </>
          ) : (
            <img
              className="illustration__woman-mobile"
              src={illustration__woman_mobile}
              alt="illustration with woman"
            />
          )}
        </div>
        <Accordion questionsAnswers={questionsAnswers} />
      </div>
    </div>
  );
};

export default Faq;
