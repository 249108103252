import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Avisos.css";
import FileDataService from "../../../services/files";
import ClasificadoDetail from "./ClasificadoDetail";
import Textura from "../../../assets/textura.jpg";
import logo from "../../services/imagenes/logo.jpg";
import image1 from "../../services/imagenes/1.jpg";
import image2 from "../../services/imagenes/2.jpg";
import image3 from "../../services/imagenes/3.jpg";
import image4 from "../../services/imagenes/4.jpg";
import image5 from "../../services/imagenes/5.jpg";
import image6 from "../../services/imagenes/6.jpg";
import image7 from "../../services/imagenes/7.jpg";
import image8 from "../../services/imagenes/8.jpg";
import image9 from "../../services/imagenes/9.jpg";
import image10 from "../../services/imagenes/10.jpg";
import image11 from "../../services/imagenes/11.jpg";
import image12 from "../../services/imagenes/12.jpg";
import image13 from "../../services/imagenes/13.jpg";
import image14 from "../../services/imagenes/14.jpg";
import image15 from "../../services/imagenes/15.jpg";
import image16 from "../../services/imagenes/16.jpg";
import image17 from "../../services/imagenes/17.jpg";
import image18 from "../../services/imagenes/18.jpg";
import image19 from "../../services/imagenes/19.jpg";
import image20 from "../../services/imagenes/20.jpg";
import image21 from "../../services/imagenes/21.jpg";
import image22 from "../../services/imagenes/22.jpg";
import image23 from "../../services/imagenes/23.jpg";
import image24 from "../../services/imagenes/24.jpg";
import { useMediaQuery } from "react-responsive"
import {FaArrowUp,} from "react-icons/fa";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useInterval };

const ClasificadosComponent = ({ email, name }) => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [clasificadosPorPagina, setClasificadosPorPagina] = useState();
  const [totalClasificados, setTotalClasificados] = useState(0);
  const [clasificados, setClasificados] = useState([]);
  const [selectedClasificado, setSelectedClasificado] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [subsubcategorias, setSubsubcategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [selectedSubcategoria, setSelectedSubcategoria] = useState(null);
  const [selectedSubsubcategoria, setSelectedSubsubcategoria] = useState(null);
  const [mostrarSubcategorias, setMostrarSubcategorias] = useState(true);
  const [mostrarSubsubcategorias, setMostrarSubsubcategorias] = useState(true);
  const [clasificadosConImagen, setClasificadosConImagen] = useState([]);
  const [clasificadosSinImagen, setClasificadosSinImagen] = useState([]);
  const [clasificadosSinContenido, setClasificadosSinContenido] = useState([]);
  const [filteredClasificados, setFilteredClasificados] = useState([]);
  
  const [selectedCountry, setSelectedCountry] = useState("");
  const { id } = useParams();
  const [categoriaImages, setCategoriaImages] = useState({});
  const [imagenesPorCategoria, setImagenesPorCategoria] = useState({});
  const [mostrarTodasSubcategorias, setMostrarTodasSubcategorias] =
    useState(true);
  const [mostrarTodasSubsubcategorias, setMostrarTodasSubsubcategorias] =
    useState(true);
  const [sortedSubsubcategorias, setSortedSubsubcategorias] = useState([]);
  const [clasificadosFiltrados, setClasificadosFiltrados] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  
  const images = [
    { src: image1, category: "1. Autos y Accesorios AB" },
    { src: image2, category: "2. Bolsa de Empleo AB" },
    { src: image3, category: "3. Casas, Lotes y Boncre" },
    { src: image4, category: "4. Clinica Salud y Estetica" },
    { src: image5, category: "5. Comunicación, Tecnología y Energía" },
    { src: image6, category: "6. Construcción, Diseño y Supervisión" },
    {
      src: image7,
      category: "7. Cupones de Descuento de Inversión e Intercambio",
    },
    { src: image8, category: "8. Centros de Educación y Universidades" },
    { src: image9, category: "9. Entretenimiento, Diversión y Restaurantes" },
    { src: image10, category: "10. Ferretería y Depósito" },
    {
      src: image11,
      category: "11. Hogar, Tienda, Electrónica y Supermercados",
    },
    {
      src: image12,
      category: "12. Planes de Inversión, Portafolio Inmobiliario",
    },
    { src: image13, category: "13. Legal y Notariado" },
    { src: image14, category: "14. Librería AB" },
    { src: image15, category: "15. Catálogo, Ofertas y Subastas" },
    { src: image16, category: "16. Noticias AB" },
    { src: image17, category: "17. Póliza y Seguros AB" },
    { src: image18, category: "18. Préstamos Privados Sobre Propiedades" },
    { src: image19, category: "19. Productos y Servicios Cooperativos" },
    { src: image20, category: "20. Combos de Publicidad y Páginas Web" },
    { src: image21, category: "21. Fundación Eslabonescr.com" },
    { src: image22, category: "22. Esencial Costa Rica: Hoteles y Turismo" },
    { src: image23, category: "23. Transporte y Mensajería" },
    { src: image24, category: "24. Directorio Comercial C.R" },
  ];

  useEffect(() => {
    fetchNewsPosts();
  }, []);

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    console.log("pais del navbar", storedCountry);
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);

  const updateSelectedCountryFromLocalStorage = () => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  };
  // Actualiza el estado inicial desde el localStorage
  useEffect(() => {
    updateSelectedCountryFromLocalStorage();
  }, []);

  const fetchNewsPosts = () => {
    FileDataService.getAllPost()
      .then((response) => {
        console.log("datos servidor", response.data)
        const postsConEmail = response.data.filter(post => post.url.includes(email));
        console.log("postsConso", postsConEmail)
        // Ordenar los posts filtrados por la categoría
        const sortedPosts = postsConEmail.sort((a, b) => {
          const numA = parseInt(a.category.split(".")[0]);
          const numB = parseInt(b.category.split(".")[0]);

          if (numA === numB) {
            // Si los números son iguales, comparar por subcategoría
            return a.category.localeCompare(b.category);
          }

          return numA - numB;
        });
        console.log("clasificados", sortedPosts);

        // Filtrar clasificados por país
        const filteredPosts = sortedPosts;

        setNewsPosts(filteredPosts);
        setPaginaActual(1);
        setClasificados(filteredPosts.slice(0, clasificadosPorPagina));
        const clasificadosConImagen = filteredPosts.filter(
          (post) => post.content && post.content_type === "clasificado"
        );
        const clasificadosSinImagen = filteredPosts.filter(
          (post) => !post.content || post.content_type !== "clasificado"
        );
        const clasificadosSinContenido = filteredPosts.filter(
          (post) => !post.content
        );

        setClasificadosConImagen(clasificadosConImagen);
        setClasificadosSinImagen(clasificadosSinImagen);
        setClasificadosSinContenido(clasificadosSinContenido);

        // Obtener categorías únicas y ordenarlas
        const uniqueCategories = [
          ...new Set(filteredPosts.map((post) => post.category)),
        ];
        const sortedCategories = uniqueCategories.sort((a, b) => {
          const numA = parseInt(a.split(".")[0]);
          const numB = parseInt(b.split(".")[0]);
          return numA - numB;
        });
        const categoriaImagesObj = {};
        sortedCategories.forEach((categoria, index) => {
          categoriaImagesObj[categoria] = images[index].src;
        });

        // Actualizar el estado de las imágenes por categoría
        setCategoriaImages(categoriaImagesObj);
        setCategorias(sortedCategories);
        setTotalClasificados(sortedPosts.length);
        // Actualizar el estado de las imágenes por categoría
        setImagenesPorCategoria(
          sortedCategories.reduce((acc, categoria, index) => {
            acc[categoria] = images[index].src;
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (id) {
      const selected = newsPosts.find((post) => post.id.toString() === id);
      if (selected) {
        setSelectedClasificado(selected);
        setModalVisible(true);
      }
    }
  }, [id, newsPosts]);

  const handleClasificadoClick = (clasificado) => {
    setSelectedClasificado(clasificado);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const openWebPage = (url) => {
    window.open(url, "_blank");
  };

  const handleCategoriaClick = (categoria) => {
    setSelectedCategoria(categoria);
    setSelectedSubcategoria(null);
    setMostrarSubcategorias(true);
    setMostrarSubsubcategorias(true);

    const filteredClasificados = newsPosts.filter(
      (post) => post.category === categoria
    );
    setPaginaActual(1);
    setClasificados(filteredClasificados.slice(0, clasificadosPorPagina));

    // Obtener subcategorías únicas y ordenarlas
    const uniqueSubcategorias = [
      ...new Set(filteredClasificados.map((post) => post.subcategory)),
    ];
    const sortedSubcategorias = uniqueSubcategorias.sort();
    setSubcategorias(sortedSubcategorias);

    console.log("Subcategorías:", sortedSubcategorias); // Verifica las subcategorías en la consola
  };

  const handleSubcategoriaClick = (subcategoria) => {
    setFilteredClasificados([]);
    console.log("Subcategoría seleccionada:", subcategoria);
    setSelectedSubcategoria(subcategoria);
    setSelectedSubsubcategoria(null);
    setMostrarSubsubcategorias(true);
  
    const filteredClasificados = newsPosts.filter((post) => {
      return (
        post.category === selectedCategoria &&
        (subcategoria ? post.subcategory === subcategoria : true)
      );
    });
    console.log("filteredClasificadossubcategorias:", filteredClasificados);
    setPaginaActual(1);
    setFilteredClasificados(filteredClasificados);
    console.log("clasificados filtrados", clasificados)
    // Obtener subsubcategorías únicas y ordenarlas solo para la subcategoría seleccionada
    const uniqueSubsubcategorias = [
      ...new Set(
        filteredClasificados
          .filter((post) => post.subcategory === subcategoria)
          .map((post) => post.subsubcategory)
      ),
    ];
    const sortedSubsubcategorias = uniqueSubsubcategorias.sort();
  
    // Setear las subsubcategorías y seleccionar la primera (o null si no hay)
    setSubsubcategorias(sortedSubsubcategorias);
    setSelectedSubsubcategoria(sortedSubsubcategorias[0] || null);
    setMostrarTodasSubsubcategorias(true);
    setSortedSubsubcategorias(sortedSubsubcategorias);
    console.log("Subsubcategorías:", sortedSubsubcategorias);
  };
  
  console.log("subcategorias guardadas", sortedSubsubcategorias);

  const handleMostrarTodasClick = () => {
    setPaginaActual(1);
    setClasificados(newsPosts.slice(0, clasificadosPorPagina));

    // Borrar subcategorías y subsubcategorías seleccionadas
    setSubcategorias([]);
    setSubsubcategorias([]);
    setSelectedCategoria(null);
    setSelectedSubcategoria(null);
    setSelectedSubsubcategoria(null);
    setFilteredClasificados([]);
    // Resetear el estado de subsubcategorías
    setSortedSubsubcategorias([]);
    setMostrarTodasSubcategorias(false);
    setMostrarTodasSubsubcategorias(false);
};
  const categoriasUsadas = {};
  const clasificadosToUse = filteredClasificados.length > 0 ? filteredClasificados : clasificados;
    const handleScrollToTop = () => {
      setFilteredClasificados([]);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Puedes ajustar el comportamiento del scroll
      });
    };
  
  return (
    <div>
      <div
        className="background-container"
        style={{
          // background: "#fff4db",
          opacity: 0.6,
          width: "100%",
          height: "100%",
          // position: "fixed",
          top: 0,
          left: 0,
        }}
      ></div>
<div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "20%",
          // zIndex: 9999, 
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={handleScrollToTop}
        >
          <FaArrowUp size={30} />
        </div>

      </div>
      <div >
        <br />
        <br />
        <br />
        <br />
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "2%",
            justifyContent: "space-between",
            background: "#fff4db",
            border: "2px solid black",
            boxShadow: "5px 5px 5px red",
            borderRadius: "15px",
            padding: "10px",
            width: "90vw", // Cambiado a unidades relativas
            margin: "auto",
          }}
        >
          <img
            src={logo}
            alt="Logo Izquierdo"
            style={{ maxWidth: "25%", maxHeight: "100%", objectFit: "cover" }}
          />
          <h2
            style={{
              color: "red",
              fontWeight: "bold",
              textShadow: "10px 10px 10px #000",
              textTransform: "none",
              fontSize: "5.2vw", // Cambiado a unidades relativas
              marginLeft: "2%",
              flex: 1,
              whiteSpace: "nowrap", // Evita el salto de línea
            }}
          >
            <span style={{ fontSize: "8.3vw" }}>Avisos</span>
            <span style={{ marginLeft: "1.6vw" }}>Económico</span>
            <span style={{ fontSize: "4.8vw", verticalAlign: "super" }}>
              's
            </span>
            
          </h2>
          
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "2%",
            justifyContent: "space-between",
            background: "#fff4db",
            border: "2px solid black",
            boxShadow: "5px 5px 5px red",
            borderRadius: "15px",
            padding: "10px",
            width: "90vw", // Cambiado a unidades relativas
            margin: "auto",
          }}
        > <h3 style={{
          color: "red",
          fontWeight: "bold",
          textShadow: "3px 3px 3px #000",
          textTransform: "none",
          fontSize: "3.2vw", // Cambiado a unidades relativas
          marginLeft: "2%",
          flex: 1,
          whiteSpace: "nowrap", // Evita el salto de línea
        }}> De {name}</h3></div>
        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 3 ? 1 : columnIndex === 1 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {columnIndex === 0 && (
                  <div className="navbar1" style={{ marginLeft: "4%", textAlign: "left" }}>
                    <ul>
                      <li onClick={handleMostrarTodasClick}>
                        INDICE POR CATEGORÍAS & ENLACES
                      </li>
                      <br />
                      {categorias.map((categoria, index) => (
                        <li
                          key={index}
                          onClick={() => handleCategoriaClick(categoria)}
                          className={
                            selectedCategoria === categoria ? "selected" : ""
                          }
                        >
                          {categoria.toUpperCase()}
                          {selectedCategoria === categoria &&
                            mostrarSubcategorias && (
                              <div className="navbar2">
                                <ul>
                                  <li
                                    onClick={() =>
                                      handleSubcategoriaClick(null)
                                    }
                                  >
                                    TODAS LAS SUBCATEGORÍAS
                                  </li>
                                  {subcategorias.map((subcategoria, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleSubcategoriaClick(subcategoria)
                                      }
                                      className={
                                        selectedSubcategoria === subcategoria
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {subcategoria}

                                      {sortedSubsubcategorias.some(
                                        (subsubcategoria) =>
                                          newsPosts.some(
                                            (post) =>
                                              post.subcategory ===
                                                subcategoria &&
                                              post.subsubcategory ===
                                                subsubcategoria
                                          )
                                      ) && (
                                        <div className="navbar3">
                                          <ul>
                                            <li>Todas las Subsubcategorías</li>
                                            {sortedSubsubcategorias.map(
                                              (subsubcategoria, index) => (
                                                <li key={index}>
                                                  {subsubcategoria.toUpperCase()}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {clasificadosToUse
                  .filter((_, index) => index >= 0 && index <= 16)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                          {imagenCategoria && escala !== 1 && (
                           <div
                           style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                           }}
                         >
                           <img
                             src={imagenCategoria}
                             alt={clasificado.category}
                             style={{
                               width: isMobile ? "80px": "140px",
                               height: isMobile ? "25px":"50px",
                               marginTop: index === 0 ? "30px" : "20px",
                               marginBottom: index === 0 ? "30px" : "30px",
                               transform: `scale(${escala})`, // Aplicar la escala adecuada
                             }}
                           />
                         </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
        </div>


        
        <hr
          style={{
            width: "100%",
            borderTop: "1px solid #000",
            margin: "10px 0",
          }}
        />
        <p style={{ textAlign: "center" }}>Página 1</p>
        {clasificadosToUse.filter((_, index) => index >= 16 && index <= 32).length > 0 && (
           <div>
        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 0 ? 3 : columnIndex === 3 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {clasificadosToUse
                  .filter((_, index) => index >= 16 && index <= 32)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                          {imagenCategoria && escala !== 1 && (
                            <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={imagenCategoria}
                              alt={clasificado.category}
                              style={{
                                width: isMobile ? "80px": "140px",
                                height: isMobile ? "25px":"50px",
                                marginTop: index === 0 ? "30px" : "20px",
                                marginBottom: index === 0 ? "30px" : "30px",
                                transform: `scale(${escala})`, // Aplicar la escala adecuada
                              }}
                            />
                          </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
        </div>

       
           <hr
           style={{
             width: "100%",
             borderTop: "1px solid #000",
             margin: "10px 0",
           }}
         />
         <p style={{ textAlign: "center" }}>Página 2</p>
         </div>
        )}
        {clasificadosToUse.filter((_, index) => index >= 32 && index <= 48).length > 0 && (
           <div>
        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 0 ? 3 : columnIndex === 3 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {clasificadosToUse
                  .filter((_, index) => index >= 32 && index <= 48)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                         {imagenCategoria && escala !== 1 && (
                            <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={imagenCategoria}
                              alt={clasificado.category}
                              style={{
                                width: isMobile ? "80px": "140px",
                                height: isMobile ? "25px":"50px",
                                marginTop: index === 0 ? "30px" : "20px",
                                marginBottom: index === 0 ? "30px" : "30px",
                                transform: `scale(${escala})`, // Aplicar la escala adecuada
                              }}
                            />
                          </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
          
        </div>
        
           <hr
           style={{
             width: "100%",
             borderTop: "1px solid #000",
             margin: "10px 0",
           }}
         />
         <p style={{ textAlign: "center" }}>Página 3</p>
         </div>
        )}
{clasificadosToUse.filter((_, index) => index >= 48 && index <= 64).length > 0 && (
           <div>
        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 0 ? 3 : columnIndex === 3 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {clasificadosToUse
                  .filter((_, index) => index >= 48 && index <= 64)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                          <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={imagenCategoria}
                                alt={clasificado.category}
                                style={{
                                  width: isMobile ? "80px": "140px",
                                  height: isMobile ? "25px":"50px",
                                  marginTop: index === 0 ? "30px" : "20px",
                                  marginBottom: index === 0 ? "30px" : "30px",
                                  transform: `scale(${escala})`, // Aplicar la escala adecuada
                                }}
                              />
                            </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
        </div>
        
           <hr
           style={{
             width: "100%",
             borderTop: "1px solid #000",
             margin: "10px 0",
           }}
         />
         <p style={{ textAlign: "center" }}>Página 4</p>
         </div>
        )}
        {clasificadosToUse.filter((_, index) => index >= 64 && index <= 80).length > 0 && (
           <div>
        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 0 ? 3 : columnIndex === 3 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {clasificadosToUse
                  .filter((_, index) => index >= 64 && index <= 80)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                          {imagenCategoria && escala !== 1 && (
                            <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={imagenCategoria}
                              alt={clasificado.category}
                              style={{
                                width: isMobile ? "80px": "140px",
                                height: isMobile ? "25px":"50px",
                                marginTop: index === 0 ? "30px" : "20px",
                                marginBottom: index === 0 ? "30px" : "30px",
                                transform: `scale(${escala})`, // Aplicar la escala adecuada
                              }}
                            />
                          </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
        </div>
        
           <hr
           style={{
             width: "100%",
             borderTop: "1px solid #000",
             margin: "10px 0",
           }}
         />
         <p style={{ textAlign: "center" }}>Página 5</p>
         </div>
        )}

        <div className="clasificados-container">
          {[...Array(4)].map((_, columnIndex) => {
            let clasificadosEnColumna = 0;
            const clasificadosPorColumna =
              columnIndex === 0 ? 3 : columnIndex === 3 ? 3 : 3;
            const maxClasificadosConImagen = 2;
            const maxClasificadosSinImagen = 3;

            return (
              <div key={columnIndex} className="columna">
                {/* Mostrar botones de filtrar solo en la primera columna */}

                {clasificadosToUse
                  .filter((_, index) => index >= 80 && index <= 96)
                  .slice(
                    columnIndex * clasificadosPorColumna,
                    columnIndex * clasificadosPorColumna +
                      clasificadosPorColumna
                  )
                  .map((clasificado, index) => {
                    const tieneImagen =
                      clasificado.content &&
                      clasificado.content_type === "imagen";
                    const clasificadoConImagen = tieneImagen ? 1 : 0;

                    const isSinContenido = !clasificado.content;

                    if (
                      clasificadosEnColumna <
                        maxClasificadosConImagen + maxClasificadosSinImagen &&
                      (clasificadoConImagen === 0 ||
                        clasificadosEnColumna < maxClasificadosConImagen)
                    ) {
                      clasificadosEnColumna += clasificadoConImagen || 1;

                      const imagenCategoria = images.find(
                        (img) => img.category === clasificado.category
                      )?.src;

                      // Aplicar tamaño triple solo al primer clasificado de cada categoría
                      const escala =
                        categoriasUsadas[clasificado.category] === undefined
                          ? 2
                          : 1;
                      categoriasUsadas[clasificado.category] = 1;

                      return (
                        <div
                          key={index}
                          className={`clasificado ${
                            isSinContenido ? "sin-contenido" : ""
                          }`}
                          onClick={() => handleClasificadoClick(clasificado)}
                        >
                          {imagenCategoria && escala !== 1 && (
                            <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={imagenCategoria}
                              alt={clasificado.category}
                              style={{
                                width: isMobile ? "80px": "140px",
                                height: isMobile ? "25px":"50px",
                                marginTop: index === 0 ? "30px" : "20px",
                                marginBottom: index === 0 ? "30px" : "30px",
                                transform: `scale(${escala})`, // Aplicar la escala adecuada
                              }}
                            />
                          </div>
                          )}
                          <p
                            className="categoria-identificativo"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.category}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subcategory}
                          </p>
                          <p
                            className="categoria-identificativo1"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.subsubcategory}
                          </p>
                          <h2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textShadow: "2px 2px 4px #828282",
                            }}
                          >
                            {clasificado.title}
                          </h2>
                          <span style={{ textAlign: "justify" }}>
                            id:{clasificado.id}
                          </span>
                          <p
                            className="descripcion"
                            style={{ textAlign: "justify" }}
                          >
                            {clasificado.description.length > 100
                              ? `${clasificado.description.substring(
                                  0,
                                  100
                                )}...`
                              : clasificado.description}
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Tipo de contenido: {clasificado.content_type}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Teléfono: {clasificado.phone_number}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Whatsapp: {clasificado.whatsapp}
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Página Web:
                            <small>
                              {clasificado.url && (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openWebPage(clasificado.url);
                                  }}
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    textShadow: "2px 2px 4px #ff4444",
                                  }}
                                >
                                  {clasificado.url}
                                </a>
                              )}
                            </small>
                          </p>
                          {clasificado.content && (
                            <img
                              src={clasificado.content}
                              alt={clasificado.title}
                              style={{
                                maxWidth: "auto",
                                height: "35vh",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          )}
                        </div>
                      );
                    }

                    return null; // No renderizar más clasificados en esta columna
                  })}
              </div>
            );
          })}
        </div>
        {/* <div className="paginacion">
          <button onClick={handlePaginaAnterior} disabled={paginaActual === 1}>
            Anterior
          </button>
          <button
            onClick={handlePaginaSiguiente}
            disabled={endIndex >= newsPosts.length}
          >
            Siguiente
          </button>
        </div> */}
        {modalVisible && selectedClasificado && (
          <ClasificadoDetail
            clasificado={selectedClasificado}
            onCloseModal={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export default ClasificadosComponent;
