import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProductDataService from "../../../services/products";
import Publicidad from "../../../assets/visibilidad.png";
import { useMediaQuery } from "react-responsive";
import Modal from "react-modal";
import "./SubproductDetails.css";
import Avisoseconomicos from "./Avisos_clasificados_individual"
import HomeScreen from "../../ecommerce/Homescreenbyuser";

Modal.setAppElement("#root");

const SubproductDetails = () => {
  const location = useLocation();
  const { subproductId } = useParams();
  const [subproductData, setSubproductData] = useState(null);
  const [subProducts, setSubProducts] = useState([]);
  const [subCombos, setCombos] = useState([]);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [consulta, setConsulta] = useState("");
  const [services, setServices] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [comboQuantities, setComboQuantities] = useState({});
  const [comboSubtotals, setComboSubtotals] = useState({});
  const [comboServicesTotals, setComboServicesTotals] = useState({});
  const [comboServices, setComboServices] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [allowedProduct, setAllowedProduct] = useState([]);
  useEffect(() => {
    const fetchSubProducts = async () => {
      try {
        const response = await ProductDataService.getAllSubProduct();
        setSubProducts(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching subproducts:", error);
      }
    };
    const fetchCombos = async () => {
      try {
        const response = await ProductDataService.getAllCombos();
        setCombos(response.data);
        console.log("Combos", response.data);
        const comboServicesData = await Promise.all(
          response.data.map(async (combo) => {
            const comboServicesResponse =
              await ProductDataService.getAllServicesForCombo(combo.id);
            return {
              comboId: combo.id,
              services: comboServicesResponse.data,
            };
          })
        );

        // Almacenar los servicios asociados a cada combo en el estado
        const comboServicesMap = comboServicesData.reduce((acc, comboData) => {
          acc[comboData.comboId] = comboData.services;
          return acc;
        }, {});
        setComboServices(comboServicesMap);
      } catch (error) {
        console.error("Error fetching subproducts:", error);
      }
    };
    fetchCombos();
    fetchSubProducts();
  }, []);

  useEffect(() => {
    const fetchCombosForSubproduct = async () => {
      if (!subproductId) return; // Asegúrate de que haya un subproducto seleccionado

      try {
        const response = await ProductDataService.getAllCombos();
        const allCombos = response.data;

        // Filtrar combos relacionados con el subproducto
        const filteredCombos = allCombos.filter((combo) =>
          combo.services.some((service) =>
            services.map((s) => s.id).includes(service.id)
          )
        );

        setCombos(filteredCombos); // Establecer solo los combos filtrados
        console.log("Combos filtrados:", filteredCombos);
      } catch (error) {
        console.error("Error fetching combos:", error);
      }
    };

    fetchCombosForSubproduct();
  }, [subproductId, services]); // Añadir 'services' como dependencia

  useEffect(() => {
    const selectedSubproduct = subProducts.find(
      (subProduct) => subProduct.email.toString() === subproductId.toString()
    );

    if (selectedSubproduct) {
      setSubproductData(selectedSubproduct);

      // Guardar el subproduct_id en una variable
      const subproduct_id = selectedSubproduct.id;

      // Utilizar subproduct_id en la solicitud de servicios
      const fetchServicesForSubProduct = async () => {
        try {
          const response = await ProductDataService.getAllServicesForSubProduct(
            subproduct_id
          );
          setServices(response.data);
          console.log("Services:", services);
        } catch (error) {
          console.error("Error fetching services:", error);
        }
      };

      fetchServicesForSubProduct();
    }
  }, [subProducts, subproductId]);

  const openWhatsAppModal = () => {
    setShowWhatsAppModal(true);
  };

  const closeWhatsAppModal = () => {
    setShowWhatsAppModal(false);
  };

  const sendWhatsAppMessage = () => {
    if (subproductData && subproductData.phone) {
      const selectedServices = services.filter(
        (service) => quantities[service.id] && quantities[service.id] > 0
      );

      const selectedCombos = subCombos.filter(
        (combo) => comboQuantities[combo.id] && comboQuantities[combo.id] > 0
      );

      if (selectedServices.length > 0 || selectedCombos.length > 0) {
        const message = `Nombre: ${name}\nApellido: ${lastName}\nCorreo: ${email}\nConsulta: ${consulta}\n\n`;

        const servicesMessage = selectedServices.map((service) => {
          const quantity = quantities[service.id];
          const subtotal = calculateSubtotal(service.price, service.id);
          return `${quantity}x ${service.name} - ₡${service.price} c/u = ₡${subtotal}`;
        });

        const combosMessage = selectedCombos.map((combo) => {
          const quantity = comboQuantities[combo.id];
          const subtotal = calculateComboSubtotal(combo);
          return `${quantity}x ${combo.name} - ₡${combo.price} c/u = ₡${subtotal}`;
        });

        const totalPrice = calculateTotal() + calculateTotalCombos();
        const totalMessage = `Total: ₡${totalPrice.toLocaleString("es-CR")}`;

        const finalMessage = `${message}${servicesMessage.join(
          "\n"
        )}\n${combosMessage.join("\n")}\n${totalMessage}`;

        const phoneNumber = subproductData.phone_number;
        const whatsappLink = `https://api.whatsapp.com/send?phone=506${phoneNumber}&text=${encodeURIComponent(
          finalMessage
        )}`;
        window.open(whatsappLink, "_blank");
        closeWhatsAppModal();
      } else {
        console.error(
          "No hay servicios o combos seleccionados para enviar el mensaje."
        );
        alert("No hay servicios o combos seleccionados para enviar el mensaje.");
      }
    } else {
      console.error("Número de teléfono no disponible.");
    }
  };

  const openURL = () => {
    if (subproductData && subproductData.url) {
      window.open(subproductData.url, "_blank");
    } else {
      console.error("URL no disponible.");
    }
  };
  const calculateSubtotal = (price, serviceId) => {
    const quantity = quantities[serviceId] || 0;
    return quantity * price;
  };

  const calculateTotal = () => {
    return services.reduce(
      (total, service) => total + calculateSubtotal(service.price, service.id),
      0
    );
  };
  const calculateComboTotal = (services) => {
    return services.reduce(
      (total, service) => total + parseFloat(service.price),
      0
    );
  };
  const calculateComboSubtotal = (combo) => {
    const quantity = comboQuantities[combo.id] || 0;
    return quantity * combo.price;
  };

  const calculateTotalCombos = () => {
    return subCombos.reduce(
      (total, combo) => total + calculateComboSubtotal(combo),
      0
    );
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      {subproductData && (
        <div>
          <div
            style={{
              border: "2px solid #ccc",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "5px 5px 10px #888888",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              marginBottom: "20px",
            }}
          >
            {subproductData.logo ? (
              <img
                src={subproductData.logo}
                alt="Logo del subproducto"
                style={{
                  maxWidth: "50%", // Máximo 50% del ancho de la pantalla
                  maxHeight: "80vh", // Máximo 80% de la altura de la pantalla
                  border: "2px solid red", // Borde rojo
                  boxShadow: "0 0 10px black", // Sombreado negro
                  borderRadius: "10px", // Esquinas redondeadas
                }}
              />
            ) : (
              <img
                src={Publicidad}
                alt="Imagen de Publicidad"
                style={{
                  maxWidth: "50%", // Máximo 50% del ancho de la pantalla
                  maxHeight: "80vh", // Máximo 80% de la altura de la pantalla
                  border: "2px solid red", // Borde rojo
                  boxShadow: "0 0 10px black", // Sombreado negro
                  borderRadius: "10px", // Esquinas redondeadas
                }}
              />
            )}
            <h1
              style={{
                fontSize: "3em",
                color: "red",
                textShadow: "2px 2px 4px #000",
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              {subproductData.name}
            </h1>
            <h3
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "0px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              {subproductData.comercial_activity}
            </h3>
            <div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "2rem",
    backgroundColor: "#f4f4f4", // Fondo suave para destacar el contenido
    borderRadius: "12px", // Bordes redondeados para un look moderno
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave para elevar el contenedor
    maxWidth: isMobile ? "100%" : "80%", // Ancho máximo del contenedor
    margin: "0 auto", // Centra el contenido horizontalmente en la página
  }}
>
  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Correo:</strong>{" "}
    {isMobile && subproductData.email.length > 50
      ? subproductData.email.match(/.{1,50}/g).map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))
      : subproductData.email}
  </p>

  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Teléfono:</strong> {subproductData.phone}
  </p>

  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333",  textAlign: "justify", }}>
    <strong>Descripción:</strong> {subproductData.description}
  </p>

  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Contacto:</strong> {subproductData.contact_name}
  </p>

  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>País:</strong> {subproductData.country}
  </p>

  <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Método de Pago:</strong> {subproductData.pay_method}
  </p>

  <p
    style={{
      fontSize: "1.8em",
      cursor: "pointer",
      color: "#007bff", // Color del enlace
      textDecoration: "underline", // Subrayado para el enlace
    }}
    onClick={openURL}
  >
    <strong>URL:</strong> {subproductData.url}
  </p>

  {/* Si deseas agregar productos, puedes habilitar esta sección */}
  {/* <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Productos:</strong> {subproductData.product_names}
  </p> */}
</div>

            <h2
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Servicios Ofrecidos
            </h2>

            <div
              style={{
                width: "80%",
                margin: "0 auto",
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "10px",
                boxShadow: "5px 5px 10px #888888",
                padding: "20px",
                overflow: "auto",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead
                  style={{
                    background: "#ddd",
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid black",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Cantidad
                    </th>
                    <th style={{ padding: "10px" }}>Nombre</th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Descripción
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Precio Unitario
                    </th>
                    <th style={{ padding: "10px" }}>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {services.length > 0 ? (
                    services.map((service) => (
                      <tr
                        key={service.id}
                        style={{ borderBottom: "1px solid green" }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          <div style={{ position: "relative", zIndex: 1 }}>
                          <input
                            type="number"
                            value={quantities[service.id] || 0}
                            onChange={(e) => {
                              const newQuantity = parseInt(e.target.value, 10);
                              if (newQuantity >= 0) {
                                const newQuantities = {
                                  ...quantities,
                                  [service.id]: newQuantity,
                                };
                                setQuantities(newQuantities);
                              }
                            }}
                            style={{ width: "50px" }}
                          />
                          </div>
                        </td>
                        <td style={{ padding: "10px", fontWeight: "bold" }}>
                          {service.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          {service.description}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          ₡{service.price}
                        </td>
                        <td style={{ padding: "10px" }}>
                          ₡{calculateSubtotal(service.price, service.id)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        <h3>No hay servicios disponibles.</h3>
                        <p>Mostrando datos predeterminados.</p>
                      </td>
                    </tr>
                  )}

                  {/* Aquí se muestra la sección de combos si no hay servicios */}
                  {services.length === 0 && subCombos.length > 0 && (
                    <tr>
                      <td colSpan="5">
                        <div
                          style={{
                            margin: "20px 0",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <h4>Combos Disponibles:</h4>
                          <table style={{ width: "100%" }}>
                            <thead
                              style={{
                                background: "#000000",
                                color: "white",
                                fontWeight: "bold",
                                borderBottom: "2px solid black",
                              }}
                            >
                              <tr>
                                <th style={{ padding: "10px" }}>Cantidad</th>
                                <th
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Nombre
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Descripción
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Servicios
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Precio
                                </th>
                                <th style={{ padding: "10px" }}>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subCombos.map((combo, index) => (
                                <tr
                                  key={index}
                                  style={{ borderBottom: "1px solid green" }}
                                >
                                  <td style={{ padding: "10px" }}>
                                    <input
                                      type="number"
                                      value={comboQuantities[combo.id] || 0}
                                      onChange={(e) => {
                                        const newQuantity = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        if (
                                          !isNaN(newQuantity) &&
                                          newQuantity >= 0
                                        ) {
                                          const newQuantities = {
                                            ...comboQuantities,
                                            [combo.id]: newQuantity,
                                          };
                                          setComboQuantities(newQuantities);
                                        }
                                      }}
                                      style={{ width: "50px" }}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    {combo.name}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    {combo.description}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    <ul>
                                      {combo.services.map(
                                        (service, serviceIndex) => (
                                          <li key={serviceIndex}>
                                            <strong
                                              style={{
                                                color: "green",
                                                fontSize: "1em",
                                              }}
                                            >
                                              *
                                            </strong>{" "}
                                            {service.name} = ₡{service.price}
                                            <br />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <p style={{ marginTop: "10px" }}>
                                      <strong>Total Servicios:</strong> ₡
                                      {combo.services
                                        .reduce(
                                          (total, service) =>
                                            total + parseFloat(service.price),
                                          0
                                        )
                                        .toLocaleString("es-CR")}
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    ₡{combo.price}
                                  </td>
                                  <td style={{ padding: "10px" }}>
                                    {calculateComboSubtotal(combo)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="5"
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  ₡{calculateTotalCombos()}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ padding: "10px", borderTop: "2px solid black" }}
                    >
                      ₡{calculateTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h2
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Combos Disponibles
            </h2>

            <div
              style={
                {
                  /* estilos de la tabla */
                }
              }
            >
              <table style={{ width: "100%" }}>
                <thead
                  style={{
                    background: "#ddd",
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid black",
                  }}
                >
                  <tr>
                    <th style={{ padding: "10px" }}>Cantidad</th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Nombre
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Descripción
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Servicios
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Precio
                    </th>
                    <th style={{ padding: "10px" }}>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {subCombos.map((combo) => (
                    <tr
                      key={combo.id}
                      style={{ borderBottom: "1px solid green" }}
                    >
                      <td style={{ padding: "10px" }}>
                        <input
                          type="number"
                          value={comboQuantities[combo.id] || 0}
                          onChange={(e) => {
                            const newQuantity = parseInt(e.target.value, 10);
                            if (!isNaN(newQuantity) && newQuantity >= 0) {
                              const newQuantities = {
                                ...comboQuantities,
                                [combo.id]: newQuantity,
                              };
                              setComboQuantities(newQuantities);
                            }
                          }}
                          style={{ width: "50px" }}
                        />
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        {combo.name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        {combo.description}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        <ul>
                          {combo.services ? (
                            combo.services.map((service, serviceIndex) => (
                              <li key={serviceIndex}>
                                <strong
                                  style={{ color: "green", fontSize: "1em" }}
                                >
                                  *
                                </strong>{" "}
                                {service.name} = ₡{service.price}
                              </li>
                            ))
                          ) : (
                            <li>No hay servicios disponibles</li>
                          )}
                        </ul>
                        <p style={{ marginTop: "10px" }}>
                          <strong>Total Servicios:</strong> ₡
                          {combo.services
                            ? combo.services
                                .reduce(
                                  (total, service) =>
                                    total + parseFloat(service.price),
                                  0
                                )
                                .toLocaleString("es-CR")
                            : "0"}
                        </p>
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        ₡{combo.price}
                      </td>
                      <td style={{ padding: "10px" }}>
                        ₡{calculateComboSubtotal(combo)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ padding: "10px", borderTop: "2px solid black" }}
                    >
                      ₡{calculateTotalCombos()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {subproductData.file && (
            <div
              style={{
                border: "2px solid #ccc",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "5px 5px 10px #888888",
                marginBottom: "20px",
                display: "flex",
                flexDirection: isMobile ? "column" : "row", // Cambio de dirección en dispositivos móviles
              }}
            >
              <div
                style={{
                  flex: isMobile ? "none" : "50%",
                  marginBottom: isMobile ? "20px" : "0",
                }}
              >
                {" "}
                {/* Estilo para dispositivos móviles */}
                {subproductData.file.toLowerCase().endsWith(".pdf") ? (
                  <iframe
                    src={`https://docs.google.com/viewer?url=${subproductData.file}&embedded=true`}
                    width="100%"
                    height="400"
                    title="Archivo PDF"
                  ></iframe>
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(jpeg|jpg|gif|png)$/) ? (
                  <img
                    src={subproductData.file}
                    alt="Imagen del archivo"
                    style={{ width: "100%" }}
                  />
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(mp4|avi|mkv)$/) ? (
                  <video width="100%" height="400" controls>
                    <source src={subproductData.file} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(doc|docx|xls|xlsx)$/) ? (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${subproductData.file}`}
                    width="100%"
                    height="400"
                  ></iframe>
                ) : (
                  <p>No se puede mostrar el archivo</p>
                )}
              </div>
              <div style={{ flex: "50%" }}>
                {subproductData.addressmap && (
                  <div>
                    <iframe
                      src={subproductData.addressmap}
                      width="100%"
                      height="400"
                      title="Ubicación en Mapa"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                fontSize: "3.5em",
                padding: "10px 20px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={openWhatsAppModal}
            >
              <i className="fab fa-whatsapp" style={{ marginRight: "5px" }}></i>{" "}
              Cotizar por WhatsApp
            </button>
          </div>
          <br />
          <br />
          <div>
          <Avisoseconomicos email={subproductData.email} name={subproductData.name}/>
          </div>
          <div><HomeScreen allowedProduct={subproductData.email} name={subproductData.name}/></div>
        </div>
      )}

      <Modal
        isOpen={showWhatsAppModal}
        onRequestClose={closeWhatsAppModal}
        contentLabel="Enviar mensaje por WhatsApp"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "5px 5px 10px #888888",
            width: "80%", // Ancho del modal
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <span
          className="whatsapp-modal-close"
          onClick={closeWhatsAppModal}
          style={{
            color: "red", // Color de la X
            fontSize: "3em", // Tamaño de la X
            position: "absolute",
            top: "10px",
            right: "10px", // Alineación a la derecha
            cursor: "pointer",
          }}
        >
          &times;
        </span>
        <h2>Enviar mensaje por WhatsApp</h2>
        <div style={{ marginBottom: "10px" }}>
          <label>Nombre:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%", // Ancho del input
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Apellido:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Correo:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Consulta:</label>
          <textarea
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
              minHeight: "100px", // Altura mínima del textarea
            }}
          />
        </div>
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            fontSize: "1.5em",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
          onClick={sendWhatsAppMessage}
        >
          Enviar
        </button>
      </Modal>
     
    </div>
  );
};

export default SubproductDetails;
