import { Table, Button, Modal, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JobDataService from "../../services/employee"; 
import BenefitModal from "./job_modal/benefit_modal";
import CompanyModal from "./job_modal/company_modal";
import ExperienceLevelModal from "./job_modal/experiencelevel_modal";
import JobAlertModal from "./job_modal/jobalert_modal";
import JobApplicationModal from "./job_modal/jobapplication_modal";
import JobCategoryModal from "./job_modal/jobcategory_modal";
import JobPostingModal from "./job_modal/jobposting_modal";
import JobTagModal from "./job_modal/jobtag_modal";
import SkillModal from "./job_modal/skill_model";

const ManageJobs = () => {
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  // Estados para cada modelo
  const [companies, setCompanies] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [jobApplications, setJobApplications] = useState([]);
  const [jobQuestions, setJobQuestions] = useState([]);
  const [jobAlerts, setJobAlerts] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [jobTags, setJobTags] = useState([]);
  const [experienceLevelName, setExperienceLevelName] = useState("");
  const [skillName, setSkillName] = useState("");
  const [benefitName, setBenefitName] = useState("");
  const [tagName, setTagName] = useState("");
  const [jobQuestionText, setJobQuestionText] = useState("");
  const [alertCriteria, setAlertCriteria] = useState("");
  
  // Modales
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showExperienceLevelModal, setShowExperienceLevelModal] = useState(false);
  const [newExperienceLevel, setNewExperienceLevel] = useState({ name: "" });
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [newSkill, setNewSkill] = useState({ name: "" });
  const [showBenefitModal, setShowBenefitModal] = useState(false);
  const [newBenefit, setNewBenefit] = useState({ name: "" });
  const [showJobTagModal, setShowJobTagModal] = useState(false);
  const [newJobTag, setNewJobTag] = useState({ name: "" });
  const [showJobPostingModal, setShowJobPostingModal] = useState(false);
  const [showJobApplicationModal, setShowJobApplicationModal] = useState(false);
  const [showJobQuestionModal, setShowJobQuestionModal] = useState(false);
  const [newJobQuestion, setNewJobQuestion] = useState({ text: "" });
  const [showJobAlertModal, setShowJobAlertModal] = useState(false);
  const [newJobAlert, setNewJobAlert] = useState({ criteria: "" });
const [currentBenefit, setCurrentBenefit] = useState(null);
const [currentJobTag, setCurrentJobTagt] = useState(null);
const [currentCompany, setCurrentCompany] = useState(null);
const [currentExperienceLevel, setCurrentExperienceLevel] = useState(null);
const [currentJobAlert, setCurrentJobAlert] = useState(null);
const [currentJobApplication, setCurrentJobApplication] = useState(null);
const [currentJobCategory, setCurrentJobCategory] = useState(null);
const [currentJobPosting, setCurrentJobPosting] = useState(null);
const [showJobCategoryModal, setShowJobCategoryModal] = useState(false);
const [currentSkill, setCurrentSkill] = useState(null);
  
  const [newCompany, setNewCompany] = useState({
    name: "",
    logo: null,
    website: "",
    description: "",
    contact_email: "",
    phone_number: "",
    address: "",
    industry: "",
    established_date: null,
  });

  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
  });

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [newLocation, setNewLocation] = useState({
    city: "",
    country: "",
    remote: false,
    region: "",
  });

  const [showPostingModal, setShowPostingModal] = useState(false);
  const [newJobPosting, setNewJobPosting] = useState({
    title: "",
    description: "",
    category: "",
    location: "",
    employment_type: "",
    experience_level: "",
    salary_range: "",
    how_to_apply: "",
    application_deadline: "",
  });

  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [newJobApplication, setNewJobApplication] = useState({
    jobId: "",
    applicantId: "",
    status: "",
  });

  // Cargar datos al inicio
  useEffect(() => {
    loadCompanies();
    loadJobCategories();
    loadJobPostings();
    loadJobApplications();
    loadJobQuestions();
    loadJobAlerts();
    loadExperienceLevels();
    loadSkills();
    loadBenefits();
    loadJobTags();
  }, []);

  // Funciones de carga para cada modelo
  const loadCompanies = () => {
    JobDataService.getAllCompanies(token)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobCategories = () => {
    JobDataService.getAllJobCategories(token)
      .then((response) => {
        setJobCategories(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobPostings = () => {
    JobDataService.getAllJobPostings(token)
      .then((response) => {
        setJobPostings(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobApplications = () => {
    JobDataService.getAllJobApplications(token)
      .then((response) => {
        // console.log("jobaplicaciones", response.data);
        setJobApplications(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobQuestions = () => {
    JobDataService.getAllJobQuestions(token)
      .then((response) => {
        setJobQuestions(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobAlerts = () => {
    JobDataService.getAllJobAlerts(token)
      .then((response) => {
        console.log("jobalerts",response.data)
        setJobAlerts(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadExperienceLevels = () => {
    JobDataService.getAllExperienceLevels(token)
      .then((response) => {
        setExperienceLevels(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadSkills = () => {
    JobDataService.getAllSkills(token)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadBenefits = () => {
    JobDataService.getAllBenefits(token)
      .then((response) => {
        setBenefits(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobTags = () => {
    JobDataService.getAllJobTags(token)
      .then((response) => {
        setJobTags(response.data);
      })
      .catch((error) => console.error(error));
  };

  // Funciones de edición para cada modelo
  const editCompany = (companyId) => {
    JobDataService.updateCompany(companyId, newCompany, token)
      .then((response) => {
        loadCompanies(); // Recargar datos de compañías después de la edición
        setShowCompanyModal(false); // Cerrar modal
        // Limpiar el estado de newCompany si es necesario
        setNewCompany({
          name: "",
          logo: null,
          website: "",
          description: "",
          contact_email: "",
          phone_number: "",
          address: "",
          industry: "",
          established_date: null,
        });
      })
      .catch((error) => console.error(error));
  };

  const editJobCategory = (categoryId) => {
    JobDataService.updateJobCategory(categoryId, newCategory, token)
      .then((response) => {
        loadJobCategories(); // Recargar datos de categorías después de la edición
        setShowCategoryModal(false); // Cerrar modal
        // Limpiar el estado de newCategory si es necesario
        setNewCategory({
          name: "",
          description: "",
        });
      })
      .catch((error) => console.error(error));
  };

  const editJobPosting = (postingId) => {
    JobDataService.updateJobPosting(postingId, newJobPosting, token)
      .then((response) => {
        loadJobPostings(); // Recargar datos de publicaciones de trabajo después de la edición
        setShowPostingModal(false); // Cerrar modal
        // Limpiar el estado de newJobPosting si es necesario
        setNewJobPosting({
          title: "",
          description: "",
          category: "",
          location: "",
          employment_type: "",
          experience_level: "",
          salary_range: "",
          how_to_apply: "",
          application_deadline: "",
        });
      })
      .catch((error) => console.error(error));
  };

  const editJobApplication = (applicationId) => {
    JobDataService.updateJobApplication(applicationId, newJobApplication, token)
      .then((response) => {
        loadJobApplications(); // Recargar datos de aplicaciones después de la edición
        setShowApplicationModal(false); // Cerrar modal
        // Limpiar el estado de newJobApplication si es necesario
        setNewJobApplication({ jobId: "", applicantId: "", status: "" });
      })
      .catch((error) => console.error(error));
  };

  // Suponiendo que hay funciones de actualización en JobDataService para los demás modelos
  const editExperienceLevel = (levelId) => {
    JobDataService.updateExperienceLevel(
      levelId,
      { name: experienceLevelName },
      token
    )
      .then((response) => {
        loadExperienceLevels(); // Recargar niveles de experiencia después de la edición
      })
      .catch((error) => console.error(error));
  };

  const editSkill = (skillId) => {
    JobDataService.updateSkill(skillId, { name: skillName }, token)
      .then((response) => {
        loadSkills(); // Recargar habilidades después de la edición
      })
      .catch((error) => console.error(error));
  };

  const editBenefit = (benefitId) => {
    JobDataService.updateBenefit(benefitId, { name: benefitName }, token)
      .then((response) => {
        loadBenefits(); // Recargar beneficios después de la edición
      })
      .catch((error) => console.error(error));
  };

  const editJobTag = (tagId) => {
    JobDataService.updateJobTag(tagId, { name: tagName }, token)
      .then((response) => {
        loadJobTags(); // Recargar etiquetas después de la edición
      })
      .catch((error) => console.error(error));
  };

  const editJobQuestion = (questionId) => {
    JobDataService.updateJobQuestion(
      questionId,
      { question: jobQuestionText },
      token
    )
      .then((response) => {
        loadJobQuestions(); // Recargar preguntas de trabajo después de la edición
      })
      .catch((error) => console.error(error));
  };

  const editJobAlert = (alertId) => {
    JobDataService.updateJobAlert(alertId, { criteria: alertCriteria }, token)
      .then((response) => {
        loadJobAlerts(); // Recargar alertas de trabajo después de la edición
      })
      .catch((error) => console.error(error));
  };
  // Funciones de eliminación para cada modelo
  const deleteCompany = (companyId) => {
    JobDataService.deleteCompany(companyId, token)
      .then((response) => {
        loadCompanies(); // Recargar datos de compañías después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobCategory = (categoryId) => {
    JobDataService.deleteJobCategory(categoryId, token)
      .then((response) => {
        loadJobCategories(); // Recargar datos de categorías después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobPosting = (postingId) => {
    JobDataService.deleteJobPosting(postingId, token)
      .then((response) => {
        loadJobPostings(); // Recargar datos de publicaciones de trabajo después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobApplication = (applicationjob, applicationid) => {
    console.log("id de eliminar", applicationid)
    JobDataService.deleteJobApplication(applicationjob, token, applicationid)
      .then((response) => {
        loadJobApplications(); // Recargar datos de aplicaciones después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteExperienceLevel = (levelId) => {
    JobDataService.deleteExperienceLevel(levelId, token)
      .then((response) => {
        loadExperienceLevels(); // Recargar niveles de experiencia después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteSkill = (skillId) => {
    JobDataService.deleteSkill(skillId, token)
      .then((response) => {
        loadSkills(); // Recargar habilidades después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteBenefit = (benefitId) => {
    JobDataService.deleteBenefit(benefitId, token)
      .then((response) => {
        loadBenefits(); // Recargar beneficios después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobTag = (tagId) => {
    JobDataService.deleteJobTag(tagId, token)
      .then((response) => {
        loadJobTags(); // Recargar etiquetas después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobQuestion = (questionId) => {
    JobDataService.deleteJobQuestion(questionId, token)
      .then((response) => {
        loadJobQuestions(); // Recargar preguntas de trabajo después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const deleteJobAlert = (alertId) => {
    console.log("Eliminar",alertId)
    JobDataService.deleteJobAlert(alertId, token)
      .then((response) => {
        loadJobAlerts(); // Recargar alertas de trabajo después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  // Manejo de creación de empresa
  const handleCreateCompany = () => {
    const formData = new FormData();
    formData.append("name", newCompany.name);
    formData.append("logo", newCompany.logo); // Asegúrate de que esto sea un archivo
    formData.append("website", newCompany.website);
    formData.append("description", newCompany.description);
    formData.append("contact_email", newCompany.contact_email);
    formData.append("phone_number", newCompany.phone_number);
    formData.append("address", newCompany.address);
    formData.append("industry", newCompany.industry);

    // Formatear established_date antes de agregarlo a formData
    if (newCompany.established_date) {
      const formattedDate = moment(newCompany.established_date).format(
        "YYYY-MM-DD"
      );
      formData.append("established_date", formattedDate);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    JobDataService.createCompany(formData, token)
      .then(() => {
        setShowCompanyModal(false);
        loadCompanies();
        // Resetea el estado del nuevo objeto
        setNewCompany({
          name: "",
          logo: null,
          website: "",
          description: "",
          contact_email: "",
          phone_number: "",
          address: "",
          industry: "",
          established_date: null,
        });
      })
      .catch((error) => console.error("Error al crear empresa", error));
  };

  const handleDeleteCompany = (id) => {
    JobDataService.deleteCompany(id, token)
      .then(() => loadCompanies())
      .catch((error) => console.error(error));
  };

  const handleShowCompanyModal = () => setShowCompanyModal(true);
  const handleCloseCompanyModal = () => setShowCompanyModal(false);

  // Manejo de creación de categoría de trabajo
  const handleCreateJobCategory = () => {
    console.log("newcategory", newCategory);
    JobDataService.createJobCategory(newCategory, token)
      .then(() => {
        setShowCategoryModal(false);
        loadJobCategories();
        setNewCategory({ name: "", description: "" });
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteJobCategory = (id) => {
    JobDataService.deleteJobCategory(id, token)
      .then(() => loadJobCategories())
      .catch((error) => console.error(error));
  };

  const handleShowCategoryModal = () => setShowCategoryModal(true);
  const handleCloseCategoryModal = () => setShowCategoryModal(false);
  const createExperienceLevel = () => {
    JobDataService.createExperienceLevel(newExperienceLevel, token)
      .then(() => {
        setShowExperienceLevelModal(false);
        loadExperienceLevels(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createSkill = () => {
    JobDataService.createSkill(newSkill, token)
      .then(() => {
        setShowSkillModal(false);
        loadSkills(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createBenefit = () => {
    JobDataService.createBenefit(newBenefit, token)
      .then(() => {
        setShowBenefitModal(false);
        loadBenefits(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createJobTag = () => {
    JobDataService.createJobTag(newJobTag, token)
      .then(() => {
        setShowJobTagModal(false);
        loadJobTags(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createJobPosting = () => {
    JobDataService.createJobPosting(newJobPosting, token)
      .then(() => {
        setShowJobPostingModal(false);
        loadJobPostings(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createJobApplication = () => {
    JobDataService.createJobApplication(newJobApplication, token, newJobApplication.jobId)
      .then(() => {
        setShowJobApplicationModal(false);
        loadJobApplications(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createJobQuestion = () => {
    JobDataService.createJobQuestion(newJobQuestion, token, newJobQuestion.jobId)
      .then(() => {
        setShowJobQuestionModal(false);
        loadJobQuestions(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  const createJobAlert = () => {
    JobDataService.createJobAlert(newJobAlert, token)
      .then(() => {
        setShowJobAlertModal(false);
        loadJobAlerts(); // Recargar datos
      })
      .catch((error) => console.error(error));
  };

  // Manejo de creación de publicación de trabajo
  const handleCreateJobPosting = () => {
    JobDataService.createJobPosting(newJobPosting, token)
      .then(() => {
        setShowPostingModal(false);
        loadJobPostings();
        setNewJobPosting({ title: "", description: "", companyId: "" });
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteJobPosting = (id) => {
    JobDataService.deleteJobPosting(id, token)
      .then(() => loadJobPostings())
      .catch((error) => console.error(error));
  };

  const handleShowPostingModal = () => setShowPostingModal(true);
  const handleClosePostingModal = () => setShowPostingModal(false);

  // Manejo de creación de aplicación de trabajo
  const handleCreateJobApplication = () => {
    JobDataService.createJobApplication(newJobApplication, token)
      .then(() => {
        setShowApplicationModal(false);
        loadJobApplications();
        setNewJobApplication({ jobId: "", applicantId: "", status: "" });
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteJobApplication = (id) => {
    JobDataService.deleteJobApplication(id, token)
      .then(() => loadJobApplications())
      .catch((error) => console.error(error));
  };

  const handleShowApplicationModal = () => setShowApplicationModal(true);
  const handleCloseApplicationModal = () => setShowApplicationModal(false);

  const handleSaveBenefit = (benefit) => {
    console.log("skill",benefit)
    const formData = new FormData();
    formData.append("name", benefit.name);
    formData.append("description", benefit.description);
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createBenefit(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

const handleSaveJobTag = (jobTag) => {
    console.log("skill",jobTag)
    const formData = new FormData();
    formData.append("name", jobTag.name);
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createJobTag(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

const handleSaveCompany = (companyData) => {
    if (companyData.id) {
        // Edit company
        JobDataService.updateCompany(companyData.id, companyData, token)
            .then(() => {
                loadCompanies();
            })
            .catch((error) => console.error(error));
    } else {
        // Create new company
        console.log("datos de compania", companyData)
        const formData = new FormData();
        formData.append("name", companyData.name);
        formData.append("logo", companyData.logo); // Asegúrate de que esto sea un archivo
        formData.append("website", companyData.website);
        formData.append("description", companyData.description);
        formData.append("contact_email", companyData.contact_email);
        formData.append("phone_number", companyData.phone_number);
        formData.append("address", companyData.address);
        formData.append("industry", companyData.industry);
        formData.append("established_date", companyData.established_date);
       

        for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
        JobDataService.createCompany(formData, token)
        .then(() => {
            setShowCompanyModal(false);
            loadCompanies();
            // Resetea el estado del nuevo objeto
            setNewCompany({
            name: "",
            logo: null,
            website: "",
            description: "",
            contact_email: "",
            phone_number: "",
            address: "",
            industry: "",
            established_date: null,
            });
        })
        .catch((error) => console.error("Error al crear empresa", error));
    };
};

const handleSaveExperienceLevel = (experienceLevel) => {
    
    const formData = new FormData();
    formData.append("level", experienceLevel.level);
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createExperienceLevel(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

const handleSaveJobAlert = (jobAlert) => {
  console.log("jobenviado", jobAlert)
  // jobAlert.append("user", user)
  const formData = new FormData();
    formData.append("user", user);
    formData.append("keywords", jobAlert.keywords);
    formData.append("categories", jobAlert.category);
    formData.append("experience_level", jobAlert.experienceLevel);
    if (Array.isArray(jobAlert.skills)) {
      jobAlert.skills.forEach(skill => {
        formData.append("skills_required", skill); // Añade cada skill individualmente
      });
    }
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
  JobDataService.createJobAlert(formData, token)
      .then(() => {
        setShowJobAlertModal(false);
        loadJobAlerts(); // Recargar datos
      })
      .catch((error) => console.error(error));
    // Aquí agrega la lógica para guardar la alerta de trabajo
};

const handleSaveJobApplication = (jobApplication) => {
    console.log("datos job application", jobApplication.formData)
    const id = jobApplication.formData.get("id");

    console.log("user", user);
    jobApplication.formData.append("applicant", user)
    for (let [key, value] of jobApplication.formData.entries()) {
      console.log(`${key}: ${value}`);
      }
    JobDataService.createJobApplication(jobApplication.formData, token, id)
    .catch((error) => console.error("Error al crear empresa", error));
};

const handleSaveJobCategory = (jobCategory) => {
    // Aquí agrega la lógica para guardar la categoría de trabajo
};

const handleSaveJobPosting = (jobPosting) => {
    console.log("jobposting", jobPosting)
    const formData = new FormData();
    formData.append("company", jobPosting.company);
    formData.append("title", jobPosting.title);
    formData.append("description", jobPosting.description);
    formData.append("category", jobPosting.category);
    formData.append("city", jobPosting.city);
    formData.append("country", jobPosting.country);
    formData.append("region", jobPosting.region);
    formData.append("modality", jobPosting.modality);
    formData.append("employment_type", jobPosting.employment_type);
    formData.append("experience_level", jobPosting.experience_level);
    formData.append("salary_range", jobPosting.salary_range);
    formData.append("skills_required", jobPosting.skills_required);
    formData.append("benefits", jobPosting.benefits);
    formData.append("tags", jobPosting.tags);
    formData.append("application_deadline", jobPosting.application_deadline);
    formData.append("how_to_apply", jobPosting.how_to_apply);

    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createJobPosting(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

const handleSaveSkill = (skill) => {
    console.log("skill",skill)
    const formData = new FormData();
    formData.append("name", skill.name);
    formData.append("description", skill.description);
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createSkill(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

  return (
    <div style={{ marginTop: "8%" }}>
      <h2>Gestión de Datos</h2>

      {/* Botón para agregar Empresa */}
      <Button variant="primary"  onClick={() => setShowCompanyModal(true)}>Agregar Empresa</Button>


      {/* Tabla de Empresas */}
      <h2>Empresas</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td>{company.id}</td>
              <td>{company.name}</td>
              <td>{company.description}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteCompany(company.id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

     {/* Botón para agregar Categoría de Trabajo */}
      <Button variant="primary" onClick={handleShowCategoryModal}>
        Agregar Categoría de Trabajo
      </Button>

      {/* Tabla de Categorías de Trabajo */}
      <h2>Categorías de Trabajo</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {jobCategories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.name}</td>
              <td>{category.description}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteJobCategory(category.id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal para agregar Categoría de Trabajo */}
      <Modal show={showCategoryModal} onHide={handleCloseCategoryModal}>
        <Modal.Title style={{ marginBottom: "4%" }}>
          Agregar Categoría de Trabajo
        </Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={newCategory.name}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="categoryDescription">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                value={newCategory.description}
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCategoryModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleCreateJobCategory}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>


      <h2>Experience Levels</h2>
            <Button variant="primary" onClick={() => setShowExperienceLevelModal(true)}>Crear Nivel de Experiencia</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nivel Profesional</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {experienceLevels.map((level) => (
                        <tr key={level.id}>
                            <td>{level.level}</td>
                            <td>
                                
                                <Button 
                                    variant="danger" 
                                    onClick={() => deleteExperienceLevel(level.id)} // Llama a la función de eliminación
                                >Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h2>Skills</h2>
            <Button variant="primary" onClick={() => setShowSkillModal(true)}>Crear Habilidad</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {skills.map((skill) => (
                        <tr key={skill.id}>
                            <td>{skill.name}</td>
                            <td>
                                <Button variant="danger" onClick={() => {deleteSkill(skill.id)}}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h2>Benefits</h2>
            <Button variant="primary" onClick={() => setShowBenefitModal(true)}>Crear Beneficio</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {benefits.map((benefit) => (
                        <tr key={benefit.id}>
                            <td>{benefit.name}</td>
                            <td>
                            
                                <Button variant="danger" onClick={() => {deleteBenefit(benefit.id)}}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h2>Job Tags</h2>
            <Button variant="primary" onClick={() =>  setShowJobTagModal(true)}>Crear Etiqueta</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobTags.map((tag) => (
                        <tr key={tag.id}>
                            <td>{tag.name}</td>
                            <td>
                                <Button variant="danger" onClick={() => deleteJobTag(tag.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h2>Job Postings</h2>
            <Button variant="primary" onClick={() => setShowJobPostingModal(true)}>Crear Publicación</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Empresa</th>
                        <th>Título</th>
                        <th>Descripción</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobPostings.map((posting) => (
                        <tr key={posting.id}>
                          <td>{posting.company}</td>
                            <td>{posting.title}</td>
                            <td>{posting.description}</td>
                            <td>
                                <Button variant="danger" onClick={() => deleteJobPosting(posting.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h2>Job Applications</h2>
            <Button variant="primary" onClick={() => setShowJobApplicationModal(true)}>Crear Aplicación</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Puesto</th>
                        <th>Nombre del Candidato</th>
                        <th>Presentacion</th>
                        <th>Estado de Aplicacion</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobApplications.map((application) => (
                        <tr key={application.id}>
                          <td>{application.job}</td>
                            <td>{application.applicant}</td>
                            <td>{application.cover_letter }</td>
                            <td>{application.status}</td>
                            <td>
                                <Button variant="warning" onClick={() => {/* Función para editar aplicación */}}>Editar</Button>
                                <Button variant="danger" onClick={() => deleteJobApplication(application.job, application.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>


            <h2>Job Alerts</h2>
            <Button variant="primary" onClick={() => setShowJobAlertModal(true)}>Crear Alerta</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nombre keywords</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobAlerts.map((alert) => (
                        <tr key={alert.id}>
                            <td>{alert.keywords}</td>
                            <td>
                                <Button variant="warning" onClick={() => {/* Función para editar alerta */}}>Editar</Button>
                                <Button variant="danger" onClick={() => deleteJobAlert(alert.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            
            {/* Modales */}
            <BenefitModal 
                show={showBenefitModal} 
                handleClose={() => setShowBenefitModal(false)} 
                benefit={currentBenefit} 
                handleSave={handleSaveBenefit} 
            />
            <JobTagModal
                show={showJobTagModal} 
                handleClose={() => setShowJobTagModal(false)} 
                jobTag={currentJobTag} 
                handleSave={handleSaveJobTag} 
            />
            <CompanyModal 
                show={showCompanyModal} 
                handleClose={() => setShowCompanyModal(false)} 
                company={currentCompany} 
                handleSave={handleSaveCompany} 
            />
            <ExperienceLevelModal 
                show={showExperienceLevelModal} 
                handleClose={() => setShowExperienceLevelModal(false)} 
                experienceLevel={currentExperienceLevel} 
                handleSave={handleSaveExperienceLevel} 
            />
            <SkillModal 
                show={showSkillModal} 
                handleClose={() => setShowSkillModal(false)} 
                skill={currentSkill} 
                handleSave={handleSaveSkill} 
            />
            <JobPostingModal
                show={showJobPostingModal} 
                handleClose={() => setShowJobPostingModal(false)} 
                jobPosting={currentJobPosting} 
                handleSave={handleSaveJobPosting} 
                jobCategories={jobCategories}  // Pasa las categorías de trabajo
                experienceLevels={experienceLevels}  // Pasa los niveles de experiencia
                tags ={jobTags}  // Pasa los tags cargados
                benefits={benefits}
                skills={skills}
                companies={companies}
            />
            <JobApplicationModal 
                show={showJobApplicationModal} 
                handleClose={() => setShowJobApplicationModal(false)} 
                jobApplication={currentJobApplication} 
                jobPostings={jobPostings}
                handleSave={handleSaveJobApplication} 
            />
            <JobAlertModal 
                show={showJobAlertModal} 
                handleClose={() => setShowJobAlertModal(false)} 
                jobAlert={currentJobAlert} 
                jobCategories={jobCategories}
                experienceLevels={experienceLevels}
                skills={skills}
                handleSave={handleSaveJobAlert} 
            />
            
            <JobCategoryModal 
                show={showJobCategoryModal} 
                handleClose={() => setShowJobCategoryModal(false)} 
                jobCategory={currentJobCategory} 
                handleSave={handleSaveJobCategory} 
            />
           
    </div>
  );
};

export default ManageJobs;
