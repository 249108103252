import React, { useState, useEffect } from 'react';
import Boton from './iframe';
import HomeScreen from '../ecommerce/Homescreen';
import image1 from './imagenes/15.jpg';
import image2 from '../../assets/categorias/ABCupon.jpg';
import Table1 from '../pages/abcupon/Table1';

const Ofertasysubastas = () => {
  const allowedCategories = [
    '15. Catálogo, Ofertas y Subastas'    
  ];
  const tablacategory = "15. Catalogo, ofertas y subastas";

  const [inputValue, setInputValue] = useState('');
  const [botones, setBotones] = useState([]);
  const [isHomeScreenOpen, setIsHomeScreenOpen] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(true); // Tabla abierta por defecto

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const filteredBotones = botones.filter((boton) =>
    boton.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Hook useEffect para manejar el tamaño de la ventana
  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      const marginTop = windowWidth <= 768 ? '20vh' : '15vh'; // Cambio de valores en dispositivos móviles
      const marginBottom = windowWidth <= 768 ? '10vh' : '0'; // Cambio de valores en dispositivos móviles
      document.documentElement.style.setProperty('--marginTop', marginTop);
      document.documentElement.style.setProperty('--marginBottom', marginBottom);
    };

    // Agregar el listener para el cambio de tamaño de ventana
    window.addEventListener('resize', handleWindowResize);

    // Llamada inicial para establecer el valor correcto
    handleWindowResize();

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleHomeScreen = () => {
    setIsHomeScreenOpen(!isHomeScreenOpen);
    if (!isHomeScreenOpen) {
      setIsTableOpen(false); // Cerrar tabla al abrir productos
    }
  };

  const toggleTable = () => {
    setIsTableOpen(!isTableOpen);
    if (!isTableOpen) {
      setIsHomeScreenOpen(false); // Cerrar productos al abrir tabla
    }
  };

  return (
    <div style={{ marginTop: 'var(--marginTop)' }}>
     
      
      {filteredBotones.map((boton) => (
        <Boton key={boton.label} url={boton.url} label={boton.label} />
      ))}

      <div>
        {/* Imágenes en la parte superior ocupando la mitad de la pantalla */}
        <div style={{ display: 'flex', justifyContent: 'left', height: '10vh', marginBottom: 'var(--marginBottom)', marginLeft: "5.3%"}}>
          <img src={image1} alt="Imagen 1" style={{ maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }} />
          <img src={image2} alt="Imagen 2" style={{ maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }} />
        </div>

        {/* Sección de productos de tienda */}
        <div style={{marginLeft: "5.3%"}}>
          <h3 
            onClick={toggleHomeScreen} 
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight:"bold",
              border: '1px solid black',
              padding: '10px 20px',
              cursor: 'pointer',
              width: '50vh',
              transition: 'background-color 0.3s, color 0.3s',
              borderRadius: '10px',
            }} 
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = 'green';
              e.target.style.color = 'white';
            }} 
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = 'black';
            }}
          >
            Productos de Tienda
          </h3>
          {isHomeScreenOpen && <HomeScreen allowedCategories={allowedCategories} />}
        </div>

        {/* Sección de tabla plana de directorio */}
        <div >
          <div style={{marginLeft: "5.3%"}}>
          <h3 
            onClick={toggleTable} 
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight:"bold",
              border: '1px solid black',
              padding: '10px 20px',
              cursor: 'pointer',
              width: '50vh',
              transition: 'background-color 0.3s, color 0.3s',
              borderRadius: '10px',
            }} 
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = 'green';
              e.target.style.color = 'white';
            }} 
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = 'black';
            }}
          >
            Tabla Plana de Directorio
          </h3 >
          </div>
          <div style={{marginTop:"2%"}}>
          {isTableOpen && <Table1 tablacategory={tablacategory} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ofertasysubastas;