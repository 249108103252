import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ShippingDataService from "../../services/shipping";
import TodoDataService from "../../services/todos";
import { useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import ModalProducto3 from "./modals/files_ecommerce_modal";
import Form_ecommerce from "./form_ecommerce";
import FormDetailsModalByName from "./modals/FormDetailsModalByName.js";

function Files_ecommerce() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const currentUser = localStorage.getItem("currentUser");
  // console.log("usuario actual", currentUser)
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [filteredProducts, setFilteredProducts] = useState(products);
  const productsToShow = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [storedData, setStoredData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [selectedShippingAddresses, setSelectedShippingAddresses] = useState(
    []
  );
  const [editingProduct, setEditingProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newProductData, setNewProductData] = useState({
    name: "",
    subproduct: "",
    category: "",
    subcategory: "",
    subsubcategory: "",
    description: "",
    price: 0,
    discount: 0,
    countInStock: 0,
    image: null,
    // Agregar otros campos según sea necesario
  });
  const [selectedProductData, setSelectedProductData] = useState({
    name: "",
    subproduct: "",
    category: "",
    subcategory: "",
    subsubcategory: "",
    description: "",
    price: 0,
    discount: 0,
    countInStock: 0,
  });
  const [currentModal, setCurrentModal] = useState(null);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    fetchProducts();
    fetchUsers();
  }, []);

  const [hasFetchedUsers, setHasFetchedUsers] = useState(false);

  const fetchUsers = () => {
    if (hasFetchedUsers) return; // Evita realizar la llamada si ya se ha hecho

    TodoDataService.getUserList(token)
      .then((response) => {
        const modifiedData = response.data.map((user) => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        }));
        console.log(modifiedData);
        setUsers(modifiedData);
        setStoredData(modifiedData);
        setHasFetchedUsers(true); // Indica que ya hemos cargado los usuarios
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchProducts = () => {
    ShippingDataService.getAllProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Estilos para los botones de paginación
  const paginationButtonStyle = {
    backgroundColor: "red",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "0 5px",
    border: "none",
    padding: "5px 10px",
    height: "30px", // Ajusta la altura deseada para los botones
  };

  const paginationButtonHoverStyle = {
    backgroundColor: "black",
    color: "white",
  };
  const activePageButtonStyle = {
    backgroundColor: "blue",
    color: "white",
  };
  // Estilo para los buscadores
  const searchInputStyle = {
    padding: "10px",
    borderRadius: "15px", // Borde redondeado
    border: "2px solid red", // Borde rojo
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Sombra negra
    outline: "none",
    width: "100%",
  };

  // Estilo para el contenedor en grid
  const searchContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "20px",
    marginBottom: "20px",
    marginTop: "20px",
  };
  const tableContainerStyle = {
    overflowX: "auto",
    maxWidth: "100%",
  };
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px 0",
    backgroundColor: "white",
  };

  const thStyle = {
    background: "#f2f2f2",
    padding: "10px",
    textAlign: "left",
    color: "black",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = paginationButtonHoverStyle.backgroundColor;
    e.target.style.color = paginationButtonHoverStyle.color;
  };

  const handleMouseOut = (e, isActive) => {
    e.target.style.backgroundColor = isActive
      ? activePageButtonStyle.backgroundColor
      : paginationButtonStyle.backgroundColor;
    e.target.style.color = isActive
      ? activePageButtonStyle.color
      : paginationButtonStyle.color;
  };

  // Paginación con botones "Anterior" y "Siguiente"
  const Pagination = ({ currentPage, totalPages, paginate }) => {
    return (
      <div>
        {/* Botón de página anterior */}
        <button
          style={paginationButtonStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={(e) => handleMouseOut(e, false)}
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1} // Deshabilitar si estamos en la primera página
        >
          Anterior
        </button>

        {/* Botones de páginas */}
        {Array.from({ length: totalPages }, (_, index) => {
          const page = index + 1;
          const isActive = page === currentPage; // Verificar si es la página actual
          return (
            <button
              key={index}
              style={isActive ? activePageButtonStyle : paginationButtonStyle}
              onMouseOver={handleMouseOver}
              onMouseOut={(e) => handleMouseOut(e, isActive)}
              onClick={() => paginate(page)}
            >
              {page}
            </button>
          );
        })}

        {/* Botón de página siguiente */}
        <button
          style={paginationButtonStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={(e) => handleMouseOut(e, false)}
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredProducts.length / itemsPerPage)
          } // Deshabilitar si estamos en la última página
        >
          Siguiente
        </button>
      </div>
    );
  };
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  // Función para acortar la descripción del producto a 30 palabras
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + "...";
    }
    return description;
  };

  // Función para manejar la apertura del modal
  const [selectedProduct, setSelectedProduct] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingProduct(null); // Restablece el producto que se está editando
    setNewProductData({
      name: "",
      subproduct: "",
      category: "",
      subcategory: "",
      description: "",
      price: 0,
      discount: 0,
      countInStock: 0,
    }); // Restablece los datos del nuevo producto
    setImage(null); // Restablece la imagen seleccionada
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Establece un temporizador para cambiar el estado de carga después de 2 segundos
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    // Limpieza del temporizador
    return () => clearTimeout(timer);
  }, [users]); // Asegúrate de que se ejecute cada vez que los usuarios cambian

  const getUserDisplayName = (userId) => {
    // Llama a fetchUsers si no se han cargado los usuarios
    if (!hasFetchedUsers) {
      fetchUsers(); // Esto cargará los usuarios una vez
      return "Cargando..."; // Devuelve un mensaje mientras carga
    }

    const user = users.find((u) => u.id === Number(userId));
    return user
      ? `${user.first_name} ${user.last_name}`
      : "Usuario Desconocido";
  };

  const handleCheckboxChange = async (productId) => {
    if (productId) {
      const confirmation = window.confirm(
        "¿Deseas eliminar el producto seleccionado?"
      );

      if (confirmation) {
        try {
          const deleteItem = await ShippingDataService.deleteProduct(
            productId,
            token
          );
          alert("Producto eliminado:", productId);
          // Aquí puedes actualizar el estado si es necesario, por ejemplo:
          setSelectedProducts(
            selectedProducts.filter((id) => id !== productId)
          );
          setEditingProduct(null);
          fetchProducts();
        } catch (error) {
          console.error("Error al eliminar el producto:", error);
          alert("Ocurrió un error al eliminar el producto.");
        }
      }
    } else {
      // Lógica para manejar la selección o deselección de productos
      if (selectedProducts.includes(productId)) {
        setSelectedProducts(selectedProducts.filter((id) => id !== productId));
        setEditingProduct(null); // Asegúrate de que setEditingProduct se llame con null al deseleccionar
      } else {
        setSelectedProducts([...selectedProducts, productId]);
        setEditingProduct(
          products.find((product) => product._id === productId)
        );
      }
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const openModal = (product) => {
    setNewProductData({
      id: product._id,
      name: product.name,
      category: product.category,
      subcategory: product.subcategory,
      subsubcategory: product.subsubcategory,
      description: product.description,
      price: product.price,
      discount: product.discount,
      countInStock: product.countInStock,
      image: null, // Puedes agregar la imagen si necesitas gestionarla
    });
    setEditingProduct(product); // Asegúrate de tener un estado para identificar si es edición
    setShowModal(true); // Abre el modal
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleCreateProduct = () => {
    // Verifica si se ha seleccionado una imagen
    if (!image) {
      alert("Seleccione una imagen para el producto.");
      return;
    }

    // Crea un objeto FormData para enviar datos con la imagen
    const formData = new FormData();
    formData.append("name", newProductData.name);
    formData.append("subproduct", newProductData.subproduct);
    formData.append("category", newProductData.category);
    formData.append("subcategory", newProductData.subcategory);
    formData.append("subsubcategory", newProductData.subsubcategory);
    formData.append("description", newProductData.description);
    formData.append("price", newProductData.price);
    formData.append("discount", newProductData.discount);
    formData.append("countInStock", newProductData.countInStock);
    formData.append("rating", 5);
    formData.append("image", image); // Agrega la imagen al FormData

    // Llama a la API para crear el producto con la imagen
    ShippingDataService.createProduct(formData, token)
      .then((response) => {
        console.log("Producto creado exitosamente:", response.data);
        fetchProducts();
      })
      .catch((error) => {
        console.error("Error al crear el producto:", error);
      });
  };

  // Función para manejar cambios en las subcatesgorías
  const handleSubcategoryChange = (e) => {
    const selectedSubcategory = e.target.value;
    setNewProductData((prevState) => ({
      ...prevState,
      subcategory: selectedSubcategory,
      subsubcategory: "", // Reinicia la sub-subcategoría al cambiar la subcategoría
    }));
  };

  const handleSubsubcategoryChange = (e) => {
    const selectedSubSubcategory = e.target.value;
    setNewProductData((prevState) => ({
      ...prevState,
      subsubcategory: selectedSubSubcategory,
    }));
  };

  const resetProductState = () => {
    // Reiniciar el estado del producto después de crear o editar
    setNewProductData({
      name: "",
      subproduct: "",
      category: "",
      subcategory: "",
      subsubcategory: "",
      description: "",
      price: 0,
      discount: 0,
      countInStock: 0,
    });
    setEditingProduct(null); // Reiniciar el indicador de edición
    setImage(null); // Reiniciar la imagen
  };

  const handleCreateOrEditProduct = () => {
    // Crea un objeto FormData para enviar datos con la imagen
    const formData = new FormData();
    formData.append("name", newProductData.name);
    formData.append("subproduct", user);
    formData.append("category", newProductData.category);
    formData.append("subcategory", newProductData.subcategory);
    formData.append("subsubcategory", newProductData.subsubcategory);
    formData.append("description", newProductData.description);
    formData.append("price", newProductData.price);
    formData.append("discount", newProductData.discount);
    formData.append("countInStock", newProductData.countInStock);
    formData.append("rating", 5);
    // formData.append("user", currentUser.id);

    // Solo agrega la imagen si existe una seleccionada
    if (image) {
      formData.append("image", image);
    }

    // Decide si crear o editar según si hay un producto en edición
    if (editingProduct) {
      // Llama a la API para actualizar el producto
      ShippingDataService.updateSubProduct(editingProduct._id, formData, token)
        .then((response) => {
          console.log("Producto editado exitosamente:", response.data);
          fetchProducts();
          handleCloseModal();
          resetProductState();
        })
        .catch((error) => {
          console.error("Error al editar el producto:", error);
        });
    } else {
      // Llama a la API para crear el producto
      ShippingDataService.createProduct(formData, token)
        .then((response) => {
          console.log("Producto creado exitosamente:", response.data);
          fetchProducts();
          handleCloseModal();
          resetProductState();
        })
        .catch((error) => {
          console.error("Error al crear el producto:", error);
        });
    }
  };

  const [showSubcategories, setShowSubcategories] = useState(false);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setNewProductData({ ...newProductData, category: selectedCategory });
    // Reinicia la subcategoría y sub-subcategoría al cambiar la categoría
    setNewProductData((prevState) => ({
      ...prevState,
      subcategory: "",
      subsubcategory: "",
    }));
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const openImageModal = (productId, productName) => {
    // Llamar al método para obtener las imágenes de ProductImage
    ShippingDataService.getProductImages(productId, token)
      .then((response) => {
        console.log(
          "Datos de las imágenes obtenidos con éxito:",
          response.data
        );

        // Comprobar si response.data es un objeto o una matriz
        if (Array.isArray(response.data)) {
          // Filtrar las imágenes que tienen el mismo productId
          const filteredImages = response.data.filter(
            (image) => image.product === productId
          );
          setSelectedImages(filteredImages); // Establecer las imágenes filtradas en el estado
        } else if (
          typeof response.data === "object" &&
          response.data.product === productId
        ) {
          // Si response.data es un objeto y tiene el mismo productId, convertirlo en una matriz
          setSelectedImages([response.data]);
        } else {
          setSelectedImages([]); // Establecer un arreglo vacío si no hay imágenes correspondientes
        }

        setSelectedProductName(productName); // Establecer el nombre del producto
        setSelectedProductId(productId); // Establecer el nombre del producto
        setIsImageModalOpen(true); // Abrir el modal
      })
      .catch((error) => {
        console.error("Error al obtener las imágenes:", error);
        // Si hay un error al obtener las imágenes, aún puedes abrir el modal sin imágenes.
        setSelectedImages([]); // Establecer un arreglo vacío para que el modal se abra sin imágenes
        setSelectedProductName(productName); // Establecer el nombre del producto
        setSelectedProductId(productId); // Establecer el nombre del producto
        setIsImageModalOpen(true); // Abrir el modal
      });
  };

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedImageComment, setSelectedImageComment] = useState("");

  const handleAddImage = async () => {
    try {
      if (!selectedProductId || !selectedImageFile) {
        console.error("Por favor, seleccione un producto y una imagen.");
        return;
      }

      // Crea un objeto FormData para enviar la imagen al servidor
      const formData = new FormData();
      formData.append("product", selectedProductId); // Utiliza el ID correcto del producto
      formData.append("image", selectedImageFile); // Utiliza el archivo de imagen seleccionado
      formData.append("comment", selectedImageComment); // Utiliza el comentario deseado

      // Agrega el siguiente console.log para verificar el contenido de formData
      console.log("Contenido de formData:");
      for (const pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      // Llama al servicio para agregar la imagen al producto
      const response = await ShippingDataService.addProductImage(
        selectedProductId,
        formData,
        token
      );

      // Verifica si la respuesta es exitosa y maneja la lógica adicional según sea necesario
      if (response.status === 201) {
        // La imagen se ha agregado correctamente, puedes actualizar el estado o realizar otras acciones aquí
        console.log("Imagen agregada con éxito.");
        setIsImageModalOpen(false); // Cierra el modal después de agregar la imagen si es necesario
      } else {
        // Manejar errores si es necesario
        console.error("Error al agregar la imagen.");
      }
      openImageModal(selectedProductId, selectedProductName);
    } catch (error) {
      // Manejar errores de red u otros errores
      console.error("Error al agregar la imagen:", error);
    }
  };

  const handleImageFileChange = (e) => {
    // Aquí puedes manejar el cambio en el archivo de imagen
    const file = e.target.files[0];
    setSelectedImageFile(file);
  };

  const handleImageSelect = (imageId) => {
    // Comprueba si la imagen ya está seleccionada
    const isSelected = selectedImages.includes(imageId);

    // Si la imagen está seleccionada, quítala del conjunto; de lo contrario, agrégala
    if (isSelected) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const handleImageCheckboxChange = (e) => {
    const imageId = parseInt(e.target.value); // Convierte el valor del checkbox a un número

    if (e.target.checked) {
      // Si el checkbox se cliente, agrega el ID a la lista de IDs seleccionados
      setSelectedImageIds([imageId]);
      console.log(`Checkbox seleccionado con ID: ${imageId}`);
    } else {
      // Si el checkbox se descliente, quita el ID de la lista de IDs seleccionados
      setSelectedImageIds(selectedImageIds.filter((id) => id !== imageId));
      console.log(`Checkbox deseleccionado con ID: ${imageId}`);
    }
  };
  const handleDeleteImages = async () => {
    try {
      if (selectedImageIds.length === 0) {
        console.error(
          "Por favor, selecciona al menos una imagen para eliminar."
        );
        return;
      }

      // Llama al servicio para eliminar las imágenes seleccionadas
      const response = await ShippingDataService.deleteProductImage(
        selectedImageIds,
        token
      );

      // Verifica si la respuesta es exitosa y maneja la lógica adicional según sea necesario
      if (response.every((res) => res.status === 204)) {
        // Todas las imágenes se han eliminado correctamente
        console.log("Imágenes eliminadas con éxito.");
        // Actualiza la lista de imágenes en el estado después de eliminarlas
        setSelectedImages((prevImages) =>
          prevImages.filter((image) => !selectedImageIds.includes(image.id))
        );
        // Limpia los IDs seleccionados
        setSelectedImageIds([]);
      } else {
        // Manejar errores si es necesario
        console.error("Error al eliminar las imágenes.");
      }
      openImageModal(selectedProductId, selectedProductName);
    } catch (error) {
      // Manejar errores de red u otros errores
      console.error("Error al eliminar las imágenes:", error);
    }
  };

  // Funciones de búsqueda
  const handleSearchByName = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      // Si el campo de búsqueda está vacío, mostrar todos los productos
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.name.toLowerCase().includes(searchTerm)
        )
      );
    }
  };

  const handleSearchByCategory = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter(
          (product) =>
            product.category.toLowerCase().includes(searchTerm) ||
            product.subcategory.toLowerCase().includes(searchTerm)
        )
      );
    }
  };

  const handleSearchBySubproduct = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.subproduct.toLowerCase().includes(searchTerm)
        )
      );
    }
  };

  const [formNameToView, setFormNameToView] = useState(null);
  const [form_idToView, setForm_idToView] = useState(null);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);

  const openFormDetailsByNameModal = (name, product_id) => {
    console.log("product_id",product_id)
    setForm_idToView(product_id)
    setFormNameToView(product_id);
    setIsNameModalOpen(true);
  };

  return (
    <div style={{ marginTop: "10%" }}>
      <h1>Crear Formularios para Productos </h1>
      <Form_ecommerce />
      <h1> Crear Productos</h1>

      <Button onClick={handleOpenModal}>Crear Productos</Button>

      <Modal
        show={isImageModalOpen}
        onHide={() => setIsImageModalOpen(false)}
        size="lg"
        style={{ overflowX: "auto", overflowY: "auto" }}
      >
        <Modal.Title>{selectedProductName}</Modal.Title>

        <Modal.Body>
          <div className="text-center">
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected Product"
                className="img-fluid"
              />
            )}
          </div>
          <hr />
          <h4>Imágenes de ProductImage</h4>
          <div className="row">
            {Array.isArray(selectedImages) &&
              selectedImages.map((imageData) => (
                <div key={imageData._id} className="col-md-3">
                  <div className="text-center">
                    <input
                      type="checkbox"
                      value={imageData._id} // Convierte el ID a una cadena
                      onChange={(e) => handleImageCheckboxChange(e, imageData)} // Pasa imageData como argumento
                    />
                    {imageData.image && (
                      <img
                        src={imageData.image}
                        alt="Selected Product"
                        className="img-fluid"
                      />
                    )}
                    <p>Comentario: {imageData.comment}</p>
                  </div>
                </div>
              ))}

            <div className="mt-4">
              <h5>Eliminar Imágenes</h5>
              <div className="form-group">
                <button className="btn btn-danger" onClick={handleDeleteImages}>
                  Eliminar Imágenes Seleccionadas
                </button>
              </div>
            </div>
          </div>
          {/* Agregar imagen */}
          <div className="mt-4">
            <h5>Agregar Imagen</h5>
            <div className="form-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageFileChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Comentario"
                value={selectedImageComment}
                onChange={(e) => {
                  setSelectedImageComment(e.target.value);
                  // console.log("Valor de selectedImageComment:", e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary" onClick={handleAddImage}>
                Agregar Imagen
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsImageModalOpen(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={searchContainerStyle}>
        {/* Buscador por Nombre */}
        <input
          type="text"
          placeholder="Buscar por nombre"
          style={searchInputStyle}
          onChange={handleSearchByName}
        />

        {/* Buscador por Categoría/Subcategoría */}
        <input
          type="text"
          placeholder="Buscar por categoría o subcategoría"
          style={searchInputStyle}
          onChange={handleSearchByCategory}
        />

        {/* Buscador por Subproducto */}
        <input
          type="text"
          placeholder="Buscar por cliente"
          style={searchInputStyle}
          onChange={handleSearchBySubproduct}
        />
      </div>

      {/* Tabla de productos */}
      <div style={tableContainerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange("selectAll")}
                  checked={selectedProducts.length === filteredProducts.length}
                />
              </th>
              <th style={thStyle}>Usuario</th>
              <th style={thStyle}>Nombre</th>
              <th style={thStyle}>Imagen</th>
              <th style={thStyle}>Cliente</th>
              <th style={thStyle}>Categoría</th>
              <th style={thStyle}>Subcategoría</th>
              <th style={thStyle}>Descripción</th>
              <th style={thStyle}>Calificación</th>
              <th style={thStyle}>Num Visualizaciones</th>
              <th style={thStyle}>Precio</th>
              <th style={thStyle}>Descuento</th>
              <th style={thStyle}>Cantidad en bodega</th>
              <th style={thStyle}>Fecha de creación</th>
              <th style={thStyle}>Formulario</th>
            </tr>
          </thead>
          <tbody>
            {productsToShow.map(
              (
                product // Cambia a productsToShow
              ) => (
                <tr
                  key={product._id}
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <td style={tdStyle}>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(product._id)}
                      checked={selectedProducts.includes(product._id)}
                    />
                  </td>
                  <td style={tdStyle}>{getUserDisplayName(product.user)}</td>
                  <td style={tdStyle}>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        cursor: "pointer",
                      }}
                      onClick={() => openModal(product)} // Aquí llamas a openModal
                    >
                      {product.name}
                    </a>
                  </td>
                  <td style={tdStyle}>
                    {product.image && (
                      <img
                        src={product.image}
                        alt="Product Image"
                        style={{ maxWidth: "50px" }}
                        onClick={() =>
                          openImageModal(product._id, product.name)
                        }
                      />
                    )}
                  </td>
                  <td style={tdStyle}>{product.subproduct}</td>
                  <td style={tdStyle}>{product.category}</td>
                  <td style={tdStyle}>{product.subcategory}</td>
                  <td style={tdStyle}>
                    {truncateDescription(product.description)}
                  </td>
                  <td style={tdStyle}>{product.rating}</td>
                  <td style={tdStyle}>{product.numReviews}</td>
                  <td style={tdStyle}>₡{product.price}</td>
                  <td style={tdStyle}>{product.discount}</td>
                  <td style={tdStyle}>{product.countInStock}</td>
                  <td style={tdStyle}>
                    {new Date(product.createdAt).toLocaleDateString("es-ES")}
                  </td>
                  <td style={tdStyle}> <button onClick={() => openFormDetailsByNameModal(product.subsubcategory, product._id)}>Editar Formulario</button></td>
                 
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(filteredProducts.length / itemsPerPage)} // Cambia a filteredProducts
        paginate={paginate}
      />

      <ModalProducto3
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        newProductData={newProductData}
        setNewProductData={setNewProductData}
        handleImageChange={handleImageChange}
        handleCategoryChange={handleCategoryChange}
        handleSubcategoryChange={handleSubcategoryChange}
        handleSubsubcategoryChange={handleSubsubcategoryChange}
        handleCreateOrEditProduct={handleCreateOrEditProduct}
      />
       {isNameModalOpen && (
        <FormDetailsModalByName
          formName={formNameToView}
          form_idToView = {form_idToView}
          onClose={() => setIsNameModalOpen(false)}
        />
      )}
    </div>
  );
}

export default Files_ecommerce;
