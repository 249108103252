import React, { useState, useEffect } from "react";
import ShippingDataService from "../../services/shipping";
import TodoDataService from "../../services/todos";
import { useSelector } from "react-redux";
import FormDetailsModal from "./modals/FormDetailsModal";
import ModalCrearForm from "./modals/FormCreateModal";

const FormEcommerce = () => {
  const [formProducts, setFormProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    fetchFormProducts();
    fetchUsers();
    fetchProducts();
  }, []);

  const fetchFormProducts = () => {
    ShippingDataService.getAllForms()
      .then((response) => {
        setFormProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchUsers = () => {
    TodoDataService.getUserList(token)
      .then((response) => {
        setUsers(response.data);
        console.log("users",response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = () => {
    ShippingDataService.getAllProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUserNameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "Desconocido";
  };

  const getProductNameById = (productId) => {
    const product = products.find((product) => product._id === productId);
    return product ? product.name : "Producto no disponible";
  };

  const getResponseForField = (responses, fieldId) => {
    const response = responses.find((resp) => resp.form_field === fieldId);
    return response ? response.value : "";
  };

  const openModal = (formProduct) => {
    setSelectedForm(formProduct);
    setIsModalOpen(true);
  };

  const updateFormFields = () => {
    fetchFormProducts(); // Vuelve a obtener los productos del formulario
  };

  // Este useEffect actualiza el modal cuando selectedForm cambia
  useEffect(() => {
    if (selectedForm) {
      // Si hay un formulario seleccionado, busca su información actualizada
      const updatedForm = formProducts.find(form => form._id === selectedForm._id);
      setSelectedForm(updatedForm);
    }
  }, [formProducts, selectedForm]);

  const handleDeleteForm = (formId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este formulario?")) {
      ShippingDataService.deleteForm(formId)
        .then(() => {
          // Actualizar la lista después de eliminar
          setFormProducts(formProducts.filter((form) => form._id !== formId));
          setSelectedForm(null); // Resetea la selección
        })
        .catch((error) => {
          console.error("Error al eliminar el formulario:", error);
        });
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
       <button onClick={() => setIsCreateModalOpen(true)}>Crear Formulario</button> {/* Botón para abrir el modal de creación */}
      
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Nombre del Formulario</th>
            <th style={thStyle}>Fecha de Creación</th>
            <th style={thStyle}>Usuario</th>
            <th style={thStyle}>Producto</th>
            <th style={thStyle}>Campos del Formulario</th>
            <th style={thStyle}>Acción</th>
          </tr>
        </thead>
        <tbody>
          {formProducts.map((formProduct) => (
            <tr key={formProduct._id}>
              <td style={tdStyle}>{formProduct.name}</td>
              <td style={tdStyle}>{new Date(formProduct.created_at).toLocaleDateString()}</td>
              <td style={tdStyle}>{getUserNameById(formProduct.user)}</td>
              <td style={tdStyle}>{formProduct.product.length > 0 ? getProductNameById(formProduct.product[0]) : "Sin producto"}</td>
              <td style={tdStyle}>
                {formProduct.fields.map((field) => (
                  <div key={field._id}>* {field.label}</div>
                ))}
              </td>
              <td style={tdStyle}>
                <button onClick={() => openModal(formProduct)}>Ver detalles</button>
                <button onClick={() => handleDeleteForm(formProduct._id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <FormDetailsModal
          formProduct={selectedForm}
          getUserNameById={getProductNameById}
          getProductNameById={getProductNameById}
          getResponseForField={getResponseForField}
          onClose={() => setIsModalOpen(false)}
          updateFields={updateFormFields}
        />
      )}
       {isCreateModalOpen && ( // Modal para crear formulario
        <ModalCrearForm
          onClose={() => setIsCreateModalOpen(false)}
          users = {users}
          updateFields={updateFormFields}
        />
      )}
    </div>
  );
};
// Estilos para la tabla
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "20px",
  backgroundColor: "#f9f9f9",
};

const thStyle = {
  backgroundColor: "#f2f2f2",
  color: "black",
  padding: "10px",
  textAlign: "left",
  borderBottom: "1px solid #ddd",
};

const tdStyle = {
  padding: "10px",
  textAlign: "left",
  borderBottom: "1px solid #ddd",
};

export default FormEcommerce;
